import React from "react";
import { toolCards } from "./Helper";

const Homecards = () => {
  return (
    <div className="container container_modified mb-1 pb-2 pb-sm-5">
      <div className="row pb-xl-4">
        {toolCards.map((obj, index) => {
          return (
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mt-4" key={index}>
              <div className="tools_card transition_03s bg-white d-flex flex-column align-items-center text-center h-100 p-3">
                <span className="cards_circle position-relative">
                  {obj.icon}
                </span>
                <p className="mt-3 mt-xl-4 pt-xl-1 text-black fw-medium mb-0 font-md">
                  {obj.title}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Homecards;