import EmojiPicker from "emoji-picker-react";
import React, { useEffect, useRef, useState } from "react";
import senderImg from "../../components/images/png/sender_img.png";
import incentiveLogo from "../../components/images/svg/logo-dashbord.svg"; 
import { IconAttachment, IconEmojiToggle, IconMessage } from "./Icons";
import axios from '../../backendClient'
import { Spinner } from "react-bootstrap";
import Markdown from "react-markdown"; 
import { useSelector } from "react-redux";

const MessageBox = ({ selectedProfile, onLimitExceeded }) => {
const ChatList = [
        {
          message: "Vitae egestas non pharetra interdum commodo habitasse",
          time: "11:00 AM",
        },
        {
          message: "Vitae egestas non pharetra interdum ",
          time: "11:00 AM",
        },
      ];
  const [newMessage, setNewMessage] = useState("");
  const [question, setQuestion] = useState(""); 
  const [aimessage, setAimessage] = useState(""); 
  const [messages, setMessages] = useState([]);
  const latestValueRef = useRef(null);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [previewLoading, setPreviewLoading]=useState(false); 
  const [countCall, setCountCall]=useState(0); 

  const [limitPass, setLimitPass] = useState(false); 
  const {currentUser} = useSelector(state => state.user);


  //want to check if limitPass is already false for a signed in user who's not on premium 


  const SidebarProfileList = [
    {
      image: senderImg,
      name: "James",
      description: "Lectus nunc egestas faucibus",
      groupchat: [
        {
          messages: "Hi",
        },
      ],
    },
    {
      image: senderImg,
      name: "James",
      description: "Lectus nunc egestas faucibus",
      groupchat: [
        {
          messages: "How are you",
        },
      ],
    },
    {
      image: senderImg,
      name: "James",
      description: "Lectus nunc egestas faucibus",
      groupchat: [
        {
          messages: "Hi",
        },
      ],
    },
    {
      image: senderImg,
      name: "James",
      description: "Lectus nunc egestas faucibus",
      groupchat: [
        {
          messages: "How are you",
        },
      ],
    },
    {
      image: senderImg,
      name: "James",
      description: "Lectus nunc egestas faucibus",
      groupchat: [
        {
          messages: "Hi",
        },
      ],
    },
  ];

  const EmojiSelectHandler = (chosenEmoji) => {
    setChosenEmoji(chosenEmoji.emoji);
    setNewMessage((newMessage) => newMessage + chosenEmoji.emoji);
  };

  const handleMessageChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleGPT = async (newMessage) => {
    const prompt = newMessage; 
    setCountCall(countCall+1); 
    const currentTime = new Date();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedTime = `${hours % 12 || 12}:${
        minutes < 10 ? "0" : ""
      }${minutes} ${ampm}`;
    setPreviewLoading(true); 

    const endpoint = currentUser ? '/api/events/userProductgpt' : '/api/events/askProductgpt';
    const res = await axios.post(endpoint, {text: prompt},{
        headers: {
          'Content-Type': 'application/json'
        }
      });

    //const result is the prompt from gpt we need to create a backend and call axios 

    if(res.data.limitExceed){
        onLimitExceeded();
        setPreviewLoading(false); 
        setLimitPass(true);  
        return; 
    }else{
    setAimessage(res.data.message);   
    setMessages((messages) => [...messages, { message: res.data.message, time: formattedTime }]);
    setPreviewLoading(false); 
    return res.data; }
  }

//   useEffect(()=> {
//     if(messages.length > 0 && newMessage === ""){
//     handleGPT(messages.pop().message); }
//   },[newMessage]); 

  const handleSend = async () => {
    if (newMessage.trim() !== "") {
      const currentTime = new Date();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedTime = `${hours % 12 || 12}:${
        minutes < 10 ? "0" : ""
      }${minutes} ${ampm}`;

      if(limitPass){
        onLimitExceeded(); 
        return; 
      }
      setMessages((messages) => [...messages, { message: newMessage, time: formattedTime }]);

      setQuestion(newMessage); 
      setNewMessage("");
      setChosenEmoji(null); // Close the emoji picker

      await handleGPT(newMessage); 
    }
  };
  const handleKeyPress = (e) => {
    if (!previewLoading && e.key === "Enter") {
      handleSend();
    }
  };

  useEffect(() => {
    if (latestValueRef.current) {
      latestValueRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, aimessage, question]);

  const getInitialTime = () => {
    const currentTime = new Date();
    // Calculate 2 or 3 hours less than the current time
    currentTime.setHours(currentTime.getHours() - 2); // or -3 for 3 hours less
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    return `${hours % 12 || 12}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
  };
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Padding month with leading zero
  const date = currentDate.getDate().toString().padStart(2, "0"); // Padding date with leading zero

  const formattedDate = `${year}/${month}/${date}`;

  return (
    <div className="w-100" style={{ paddingRight: "20px" }}>
      <div className="p-3 p-md-4 w-100 ms-md-1" id="chat_box">
        {selectedProfile !== null && (
          <div className="w-100 h-100 position-relative">
            <div className="chat_parent_height">
              <p className="font-xs text-gray text-center mb-4 pb-3">
                {formattedDate}
              </p>
              <div className="d-flex justify-content-end gap-2 mb-md-2 pb-1">
                <p className="font-sm my-message text-white bg-offblue py-2 px-3 px-md-4 mb-0 d-flex align-items-center">
                  Enter Your Product Below: 
                  <span className="ms-2 ms-md-4 opacity-75 text-nowrap">
                    
                  </span>
                </p>
                <div className="user-img overflow-hidden ms-1">
                  <img src={senderImg} alt="sender" />
                </div>
              </div>
              {/* {SidebarProfileList.map((obj, index) => {
                return (
                  <div key={index}>
                    {obj.groupchat.map((text, i) => {
                      return (
                        <div
                          className="d-flex gap-2 mb-md-2 pb-md-1 align-items-end"
                          key={i}
                        >
                          <div className="user-img overflow-hidden ms-1">
                            <img
                              src={obj.image}
                              alt="receiverImg"
                              className="w-100 h-100"
                            />
                          </div>
                          <p className="user-message text-white bg-orangelight py-2 px-3 px-md-4 mb-0 d-flex">
                            {text.messages}
                            <span className="ms-2 ms-md-4 opacity-75 text-nowrap">
                              {getInitialTime()}
                            </span>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                );
              })} */}
              <p className="font-xs text-gray text-center py-3 mb-0">
                {formattedDate}
              </p>
                {messages.map((obj, index) => (
                 <div
                   ref={index === messages.length - 1 ? latestValueRef : null}
                   className={index % 2 === 0 ? "d-flex justify-content-end gap-2 py-2 mb-md-2 pb-1" : "d-flex gap-2 mb-md-2 pb-md-1 align-items-end"}
                   key={index}>
                   <div className={`font-sm text-white py-2 px-3 px-md-4 mb-0 d-flex ${index % 2 === 0 ? "my-message bg-offblue align-items-center" : "user-message bg-orangelight"}`}>
                        <Markdown>
                            {obj.message}
                        </Markdown>
                     <span className="ms-2 ms-md-4 opacity-75 text-nowrap">
                       {obj.time}
                    </span>
                  </div>
                  <div className="user-img overflow-hidden ms-1">
                    <img className={index % 2 === 0 ? "" : "w-100 h-100"} src={ index%2===0? senderImg: incentiveLogo} alt="sender" />
                  </div>  </div>))}

                  { previewLoading && 
                       <div
                       ref={countCall === messages.length - 1 ? latestValueRef : null}
                       className="d-flex gap-2 mb-md-2 pb-md-1 align-items-end">
                       <p className="font-sm text-white py-2 px-3 px-md-4 mb-0 d-flex user-message bg-orangelight">
    
                       <div className='spinner'><Spinner animation="border" role="status">
                        </Spinner>
                        </div>
                      </p>
                      <div className="user-img overflow-hidden ms-1">
                        <img className="w-100 h-100" src={incentiveLogo} alt="sender" />
                      </div>  </div>}
                
            </div>
            <div className="d-flex align-items-center gap-3 position-absolute bottom-0 start-0 w-100 z-3 input-parent">
              {chosenEmoji ? (
                <div className="position-absolute emoji_picker end-0 z-3">
                  <EmojiPicker onEmojiClick={EmojiSelectHandler} />
                </div>
              ) : (
                ""
              )}
              <input
                value={newMessage}
                onChange={handleMessageChange}
                onKeyDown={handleKeyPress}
                className="w-100 message-input p-0"
                type="text"
                placeholder="Type a message.."
              />
              <span
                onClick={() => setChosenEmoji(!chosenEmoji)}
                className="cursor_pointer"
              >
                <IconEmojiToggle />
              </span>
              <span className="cursor_pointer">
                <input
                  type="file"
                  name="image"
                  id="image"
                  className="d-none"
                  accept=".png,.jpg,.jpeg"
                />
                <label htmlFor="image" className="cursor_pointer">
                  <IconAttachment />
                </label>
              </span>
              <button
                onClick={handleSend} disabled={previewLoading}
                className="bg-darkblue d-flex align-items-center justify-content-center send_btn"
              >
                <IconMessage />
              </button>
            </div>
          </div>
        )}
        {chosenEmoji ? (
          <div
            onClick={() => setChosenEmoji(!chosenEmoji)}
            className="vh-100 w-100 bg-lightblack z-2 position-absolute top-0 start-0"
          ></div>
        ) : (
          ""
        )}
      </div>
    </div>
  );


};

export default MessageBox;


// return (
//     <div className="w-100" style={{ paddingRight: "20px" }}>
//       <div className="p-3 p-md-4 w-100 ms-md-1" id="chat_box">
//         {selectedProfile !== null && (
//           <div className="w-100 h-100 position-relative">
//             <div className="chat_parent_height">
//               <p className="font-xs text-gray text-center mb-4 pb-3">
//                 2024/03/10
//               </p>
//               <div className="d-flex justify-content-end gap-2 mb-md-2 pb-1">
//                 <p className="font-sm my-message text-white bg-offblue py-2 px-3 px-md-4 mb-0 d-flex align-items-center">
//                   Hii
//                   <span className="ms-2 ms-md-4 opacity-75 text-nowrap">
//                     1:00 PM
//                   </span>
//                 </p>
//                 <div className="user-img overflow-hidden ms-1">
//                   <img src={senderImg} alt="sender" />
//                 </div>
//               </div>
//               {SidebarProfileList.map((obj, index) => {
//                 return (
//                   <div key={index}>
//                     {obj.groupchat.map((text, i) => {
//                       return (
//                         <div
//                           className="d-flex gap-2 mb-md-2 pb-md-1 align-items-end"
//                           key={i}
//                         >
//                           <div className="user-img overflow-hidden ms-1">
//                             <img
//                               src={obj.image}
//                               alt="receiverImg"
//                               className="w-100 h-100"
//                             />
//                           </div>
//                           <p className="user-message text-white bg-orangelight py-2 px-3 px-md-4 mb-0 d-flex">
//                             {text.messages}
//                             <span className="ms-2 ms-md-4 opacity-75 text-nowrap">
//                               {getInitialTime()}
//                             </span>
//                           </p>
//                         </div>
//                       );
//                     })}
//                   </div>
//                 );
//               })}
//               <p className="font-xs text-gray text-center py-3 mb-0">
//                 {formattedDate}
//               </p>
//               {messages.map((obj, index) => (
//                 <div
//                   ref={index === messages.length - 1 ? latestValueRef : null}
//                   className="d-flex justify-content-end gap-2 mb-md-2 pb-1"
//                   key={index}
//                 >
//                   <p className="font-sm my-message text-white bg-offblue py-2 px-3 px-md-4 mb-0 d-flex align-items-center">
//                     {/* {obj.message} */}
//                     {obj.message}
//                     <span className="ms-2 ms-md-4 opacity-75 text-nowrap">
//                       {obj.time}
//                     </span>
//                   </p>
//                   <div className="user-img overflow-hidden ms-1">
//                     <img src={senderImg} alt="sender" />
//                   </div>

//                   <div className="user-img overflow-hidden ms-1">
//                             <img
//                               src={obj.image}
//                               alt="receiverImg"
//                               className="w-100 h-100"
//                             />
//                           </div>
//                           <p className="user-message text-white bg-orangelight py-2 px-3 px-md-4 mb-0 d-flex">
//                             {aimessage[index]}
//                             <span className="ms-2 ms-md-4 opacity-75 text-nowrap">
//                               {getInitialTime()}
//                             </span>
//                           </p>
                  
//                 </div>
                
//               ))}
//             </div>
//             <div className="d-flex align-items-center gap-3 position-absolute bottom-0 start-0 w-100 z-3 input-parent">
//               {chosenEmoji ? (
//                 <div className="position-absolute emoji_picker end-0 z-3">
//                   <EmojiPicker onEmojiClick={EmojiSelectHandler} />
//                 </div>
//               ) : (
//                 ""
//               )}
//               <input
//                 value={newMessage}
//                 onChange={handleMessageChange}
//                 onKeyPress={handleKeyPress}
//                 className="w-100 message-input p-0"
//                 type="text"
//                 placeholder="Type a message.."
//               />
//               <span
//                 onClick={() => setChosenEmoji(!chosenEmoji)}
//                 className="cursor_pointer"
//               >
//                 <IconEmojiToggle />
//               </span>
//               <span className="cursor_pointer">
//                 <input
//                   type="file"
//                   name="image"
//                   id="image"
//                   className="d-none"
//                   accept=".png,.jpg,.jpeg"
//                 />
//                 <label htmlFor="image" className="cursor_pointer">
//                   <IconAttachment />
//                 </label>
//               </span>
//               <button
//                 onClick={handleSend}
//                 className="bg-darkblue d-flex align-items-center justify-content-center send_btn"
//               >
//                 <IconMessage />
//               </button>
//               <button
//                 onClick={handleGPT}
//                 className="bg-darkblue d-flex align-items-center justify-content-center send_btn"
//               >
//                AI TEST <IconMessage />
//               </button>
//             </div>
//           </div>
//         )}
//         {chosenEmoji ? (
//           <div
//             onClick={() => setChosenEmoji(!chosenEmoji)}
//             className="vh-100 w-100 bg-lightblack z-2 position-absolute top-0 start-0"
//           ></div>
//         ) : (
//           ""
//         )}
//       </div>
//     </div>
//   );