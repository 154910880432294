import React, { useState, useEffect } from "react";
import {useSelector } from 'react-redux';
import MessageBox from "./MessageBox";
import senderImg from "../../components/images/png/sender_img.png";
import { IconBtnArrow } from "./Icons";
import UserListView from "./UserListView";
import SecondaryButton from "./SecondaryButton";
import { useNavigate } from "react-router-dom";
import "./productgpt.scss";
import  Modal  from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ProductGPT = ({ isPublic }) => {


    const {currentUser} = useSelector(state => state.user);
    const navigate = useNavigate(); 

    const [limitExceeded, setLimitExceeded] = useState(false);

    const handleLimitExceeded = () => {
        setLimitExceeded(true); 
    }

    const handleCloseModal = ()=>{
        setLimitExceeded(false); 
    }

    const proceedToPremium = () => {
        if(!currentUser){
            navigate('/signup', {state: {from: '/trygpt'}}); 
            return; 
        }else{
            navigate('/checkout',{state: {from: '/trygpt'}});
        }
    }

    useEffect(() => {

        const checkAccess = async () => {
            if(isPublic){
                //replace with modal 
                if(currentUser){
                    navigate("/productgpt"); 
                }
            }else{
                //they are in the private gpt page remove below 
                // **TO DO ** the else is not needed after debugging but test the path from the signed in user to payment
               // alert('Not public'); 
            }
        };
    
        checkAccess();
      }, [isPublic, currentUser]);
 
    const SidebarProfileList = [
        {
          image: senderImg,
          name: "James",
          description: "Lectus nunc egestas faucibus",
          groupchat: [
            {
              messages: "Hi",
            },
          ],
        },
        {
          image: senderImg,
          name: "James",
          description: "Lectus nunc egestas faucibus",
          groupchat: [
            {
              messages: "How are you",
            },
          ],
        },
        {
          image: senderImg,
          name: "James",
          description: "Lectus nunc egestas faucibus",
          groupchat: [
            {
              messages: "Hi",
            },
          ],
        },
        {
          image: senderImg,
          name: "James",
          description: "Lectus nunc egestas faucibus",
          groupchat: [
            {
              messages: "How are you",
            },
          ],
        },
        {
          image: senderImg,
          name: "James",
          description: "Lectus nunc egestas faucibus",
          groupchat: [
            {
              messages: "Hi",
            },
          ],
        },
      ];


  const [selectedProfile, setSelectedProfile] = useState(1);

  const handleProfileClick = (index) => {
    setSelectedProfile(index);
  };
  
    return (
        <div className="mt-1 bg-lightwhite pb-4">
          <div
            className="mx-auto"
            style={{
              maxWidth: "1360px",
              paddingLeft: "20px",
            }}>
            <div
              className="d-sm-flex back_btn_parent"
              style={{ paddingRight: "20px" }}>
              <SecondaryButton
                ctaText="Back"
                icon={<IconBtnArrow />}
                link="/dashboard"
              />
              <div
                className="text-center w-100 d-flex flex-column align-items-center mt-2 mb-3 mb-sm-0"
                style={{ lineHeight: "24px" }}>
                <p className="fw-bolder premium_text mb-1">
                  Product GPT: Enter Your Product for A Full High Converting Script 
                </p>
                <p className="font-sm mb-0 text-darkblue">Trained on Thousands of Real World Examples</p>
              </div>
            </div>
            <div
              className="d-flex gap-2 flex-column flex-md-row chat_parent"
              style={{ height: "calc(100vh - 186px)" }}>
              {/* <UserListView
                handleProfileClick={handleProfileClick}
                selectedProfile={selectedProfile}
              /> */}
              {limitExceeded && (    <div>
                                        <Modal
                                            show={limitExceeded}
                                            onHide={handleCloseModal}
                                            centered
                                        >
                                            <Modal.Header closeButton>
                                            <Modal.Title>Limit Exceeded</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="mb-2">
                                            <h4>
                                            You've exceeded your daily limit for product GPT. Please Login and Sign Up for Premium 
                                            to get unlimited usage per month. You can use ProductGPT daily for free for limited use
                                            or unlimited use with a premium membership. 
                                            </h4>
                                            <br />
                                            <p>
                                            You pay for parking, seltzer water...so why not support a community dedicated to helping you 
                                            make money online with affiliate and product sales? You'll get premium access on the platform
                                            as well as discounts on our Full Tiktok Shop Community (Coming Soon!)
                                            </p>
                                            <br/>
                                            <Button className="mt-1" variant="primary" onClick={proceedToPremium}>
                                                Sign-Up For Premium
                                            </Button>
                                            </Modal.Body>
                                            <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseModal}>
                                                Close
                                            </Button>
                                            </Modal.Footer>
                                        </Modal>
                                </div>)}
              <MessageBox
                sidebarProfileList={SidebarProfileList} onLimitExceeded={handleLimitExceeded}
              />
            </div>
          </div>
          </div>
       
      );
 

};

export default ProductGPT;