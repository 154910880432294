import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from '../../backendClient'

import inviteImg from "../../components/images/svg/no_invite.svg"; 
import Emptystate from "../../components/Emptystate";
import Hero from "./Hero";
import PopularVideos from "./PopularVideos";
import HowItWorks from "./HowItWorks";
import AuthorBlog from "./AuthorBlog";
import WannaAdvertise from "./WannaAdvertise";
import MediaWorked from "./MediaWorked";
import OurCustomersSay from "./OurCustomersSay";
import Pricing from "./Pricing";
import ExplainImg from "./ExplainImg";
import "./social-media.scss"; 
import PrimaryButton from "../../components/PrimaryButton";


const SocialMedia = () => {
    const {title} = useParams(); 
    const [loading, setLoading] = useState(false); 
    const [data, setData] = useState([]); 

    const fetchPage = async (title) => {

    
        try{
          let response; 
          setLoading(true); 
         
          response = await axios.post(`/api/events/getSocialPage`, {page: title });
          const newData = response.data; 
          setData(newData);
          
        }
        catch(e){
         console.log(e); 
        }finally{
          setLoading(false); 
        }
  };

  if(data.length === 0 && loading){


    return <>
        <div
                className="pt-4 pt-sm-5 mt-5 bg-lightwhite invite-page text-center px_xsm"
                style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    minHeight: "calc(100vh - 70px)",}}>
                <div
                    className="py-5 px_xsm"
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <h2 className="text-lightblue fw-bolder text-center mb-3">
              Give Us A Moment To Grab Your Special Deal
            </h2>
            <img loading="lazy" src={inviteImg} alt="inviteImg" />
            <p className="text-gray font-sm py-3 mt-sm-1 mb-1 mb-sm-2">
              We are preparing your page, sorry about the delay. 
            </p>
          </div>
        </div>
    </>
  }




    return (
      <>
        <div id="social-media">
          {/* Hello Social! {title} */}
          <Hero title={title} />
          <PopularVideos />
          <HowItWorks />
          <AuthorBlog />
          <ExplainImg />
          <WannaAdvertise />
          <OurCustomersSay />
          <MediaWorked />
          <Pricing />
        </div>
      </>
    );
  };
  
  export default SocialMedia;