import React from "react";
import circle_center_img from "../../components/images/png/step-blue-circle.png";
import signUp from "../../components/images/webp/sign-up.webp";
import businessGirl from "../../components/images/webp/business-girl.webp";
import highestQuality from "../../components/images/webp/highest-quality.webp"; 

const BusinessStep = () => {

    const BusinessStepList = [
        { description: "Sign Up As An Individual Affiliate (Promote Other Products wihtout worrying about shipping or inventory) or A Brand Looking To Post", image: signUp },
        {
          description: "Brands Set Your Affiliate Payouts And Link Your Shopify Checkout Page, Affiliates Choose Which Products You Want To Promote With Links to Get Paid",
          image: businessGirl,
        },
        {
          description:
            "Affiliates Use ProductGPT To Write Authentic Ads That Convert Into Sales and Get Promoted and Look Organic and Authentic",
          image: highestQuality,
        },
      ];
  return (
    <>
      <div id="business-cards-step" className="position-relative z-1">
        <div className="position-absolute top-0 end-0 z-n1 rounded-pill business_shadow"></div>
        <div className="position-absolute bottom-0 start-0 z-n1 rounded-pill business_shadow"></div>
        <div className="container container_modified">
          {BusinessStepList.map((obj, index) => {
            return (
              <div
                className={`row align-items-center business_card position-relative z-1 ${
                  index === 1 ? "flex-row-reverse rotate_second_card" : ""
                }`}
                key={index}
              >
                <img
                  src={circle_center_img}
                  alt="blue circle"
                  className={`position-absolute top-50 start-50 center_image_blue z-n1 ${
                    index === 1 ? "rotate_0" : ""
                  }`}
                />
                <div className="col-lg-6 col-12 ps-lg-5">
                  <h6 className="heading_step mb-1 fw-bold position-relative z-1">
                    Step {index + 1}
                  </h6>
                  <p className={`mb-lg-0 pb-lg-0 pe-4 pe-md-0 text-white fw-bold paragraph_step ${index === 0 ? "mb-4 pb-2" : "mb-2 pb-1"}`}>
                    {obj.description}
                  </p>
                </div>
                <div
                  className={`col-lg-6 px-0 col-12 d-flex justify-content-center ${
                    index === 1 ? "justify-content-lg-start" : "justify-content-lg-end"
                  }`}
                >
                  <div className="business_card_image rounded-3 overflow-hidden">
                    <img
                      src={obj.image}
                      alt="quality"
                      className="w-100 rounded-3 object-fit-cover"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BusinessStep;