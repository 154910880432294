import React, { useEffect, useState, useRef} from 'react';
import { Col,Container, Form, Row, Button, Tooltip, OverlayTrigger, Alert, Image } from 'react-bootstrap';
import {Link, useNavigate, useParams} from 'react-router-dom';
import "./invitecode.scss";
import logo from '../../assets/logo.svg';
//import axios from 'axios';
import axios from '../../backendClient'
import { useDispatch, useSelector } from 'react-redux';
import { userRouteError, clearError} from '../../redux/userSlice';


const Invitecode =  () =>  {

  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {currentUser, error} = useSelector((state)=> state.user);
  const [show, setShow] = useState(true);
  const { linkcode } = useParams();


  useEffect(() => {
      if (linkcode) {
          setCode(linkcode);
      }
  }, [linkcode]);

  const handleCodeSubmit = async (e) => {
    e.preventDefault(); 
    if(currentUser){
      try{
        const res = await axios.post("/api/events/inviteGuestFromCode", {phone: phone, inviteCode: code, userInvited: currentUser._id},{
          headers: {
            'Content-Type': 'application/json'
          }
        });
        //go to activeInvites page but should actually just go to the event page 
        navigate('/activeinvites'); 

      }catch(err){
        console.log(err); 
        dispatch(userRouteError(err.response.data.message));
        setShow(true);
      }
    }else{
      try{

        const res = await axios.post("/api/events/inviteGuestFromCode", {phone: phone, inviteCode: code},{
          headers: {
            'Content-Type': 'application/json'
          }
        });


        navigate('/signup', {state: {code}}); //from login/sign up we will check for active or pass the short title?  from the endpoint 

      }catch(err){
        console.log(err); 
        dispatch(userRouteError(err.response.data.message));
        setShow(true);
      }
      
    } 
  }

  return (
    <Container className='main-container'>
      <Row>
      {error && show && <Alert variant="danger" onClose={() => {setShow(false); dispatch(clearError());}} dismissible>{error}</Alert>}
      </Row>
        <Row>
        <Col md={6} className="invite__bg order-2 order-md-1">
        </Col>
        <Col md={6} className="form-box d-flex align-items-center justify-content-center flex-direction-column order-1 order-md-2">
        <h2 className="form-title"><Image src={logo} style={{width: 75, height:75, objectFit:'cover', borderRadius:'50%'}}/></h2>

            <Form onSubmit={handleCodeSubmit}>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  placeholder="Enter phone number"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">Invite Code</Form.Label>
                <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip-auto">
                        Enter the 5 digit Code given to you 
                      </Tooltip>
                    }>
                <Form.Control
                  type="text"
                  value={code}
                  onChange={e => setCode(e.target.value)}
                  placeholder="Enter your Invite Code"
                /></OverlayTrigger>
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit 
              </Button>
            </Form>
        </Col>
        </Row>
    </Container>

  )
}


export default Invitecode