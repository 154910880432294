import React from "react";
import { LinkContainer } from "react-router-bootstrap";

const CustomButton = ({ text, btnStyle, icon, type }) => {
  return (
    <>
      {type === "link" ? (
        <LinkContainer
          to="/login">
            <button className={`common_btn ${btnStyle} text-decoration-none text-center`}>
                {text} {icon ? icon: ''}
          </button>
        </LinkContainer>
      ) : (
         <LinkContainer to="/publiclisting">
           <button className={`common_btn ${btnStyle}`}>
            {text} {icon ? icon: ''}
        </button>
      </LinkContainer>
      )}
    </>
  );
};

export default CustomButton;