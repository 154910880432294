import { Card } from 'react-bootstrap';
import './contact.scss'; 

const Contact = () => {
  return (
    <Card>
      <Card.Header className="card-header">Contact Us</Card.Header>
      <Card.Body>
        <Card.Text className="card-text">
          <p> IncentiveMeet operates Monday through Saturday 9 AM to 9 PM PST</p>
          <h3>Email Contact: </h3>
          <p>
            Please contact us with any inquiries at support@rshieldtech.com if you have any account related questions you may email 
            incentivemeet-account@rshieldtech.com
          </p>
          <h3>Phone Contact</h3>
          <p>
            We are happy to help over the phone please contact us during the above hours: (213) 566-3301
          </p>

          {/* <h3>Address Information</h3>
          <p>
            You may send any documents, notices, or mailers to the following: <br/>
            300 Lenora Street #497, Seattle WA 98121 <br/>
            Please address correspodence to IncentiveMeet.com
            and include your email, username on the platform, and way to contact you. 
          </p> */}

        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default Contact;
