import React from "react";
import checkMark from "../../components/images/svg/check-mark-with-blue-bg.svg";
import image2 from "../../components/images/webp/ent-img-pricing.webp";
import image1 from "../../components/images/webp/std-img-pricing.webp";
import { SecondryHeadingVector } from "./Icons";
import CustomButton from "./CustomButton";
import PrimaryButton from "../../components/PrimaryButton";

const Pricing = () => {

    const standardSpecification = [
        "Invite Affiliates Directly: Invite 10 affiliates per month from the platform, plus unlimited invites via link/code off the platform",
        "Post Your Ad: One free affiliate offer post each month",
        "Redemptions: Unlimited customers can redeem your offer(s) and get 5 affilites for each offer",
        "Text Notifications/Blasts: 100 Text notifications to customers who join offers",
        "Group Chat Feature:  Meet in group chats guide affiliates, provide info, and build excitement",
        "Reviews for Trust and Safety: Customers can leave reviews on Offer Pages helping build social proof"
      ];
    
      const enterpriseSpecification = [
        "Invite Affiliates Directly: Invite 100 affiliates per month from the platform, plus unlimited invites via link/code off the platform",
        "Post Your Ad: 3 affiliate post each month in any location",
        "Redemptions: Unlimited customers can redeem your offer(s) and unlimited Affiliates",
        "Text Notifications/Blasts: Unlimited text notifications to customers who join offers",
        "Group Chat Feature:  Meet in group chats guide affiliates, provide info, and build excitement",
        "Reviews for Trust and Safety: Customers can leave reviews helping you build social proof",
        "Test promotions with a tracking dashboard and see affilaite performance",
        "Bonus: Guided integration over video call so you can focus on your business and tracking",
        "Bonus: Free Ads training for E-Commerce Store owners ($1000/month value) ",
        "Bonus: Automatic Payouts for Affiliate Offers", 
        "Bonus: 1 Dedicated Tiktok or Instagram page with 1 Professional UGC video and 1 Voiceover video every week ($1000 month/ value)", 
        "Bonus: Commission free sales from your social media pages", 
        "Bonus: We do influencer outreach and get them in contact with you in your product category ($1000/month value)", 
        "Bonus: Includes High converting pre-landing page before your purchase offer ($1000/month value)"
      ];
  return (
    <div className="bg_grident_pricing py-5">
      <div className=" container container_modified py-md-4 py-1 py-xl-5">
        <div className="d-flex justify-content-center">
          <h2
            style={{ lineHeight: "110%" }}
            className=" mx-auto d-inline-block position-relative fw-black text-white font-xxl mb-0 pt-2 z-1">
            <span className=" d-inline-block position-absolute center__absolute_content z-n1">
              <SecondryHeadingVector />
            </span>
            Pricing
          </h2>
        </div>
        <div className="row pt-5 justify-content-center px-2 px-sm-0">
          <div className="col-md-6 col-lg-5">
            <div className="p-4 bg-white w-100 pricing_cards position-relative h-100">
              {/* <span className="tag position-absolute d-inline-block">
                Most Popular
              </span> */}
              <div className="p-3 d-flex justify-content-center">
                <img src={image1} alt="std" /> 
              </div>
              <h3 className="text-center mb-0">Free</h3>
              <h4 className=" text-center fw-medium text-black mb-0">
                <span>Free</span> *Self-Managed Plan 
              </h4>
              <div className="specification_box mt-3">
                {standardSpecification.map((obj, i) => (
                  <div key={i} className="d-flex gap-2 align-items-center pt-3">
                    <img
                      className=" pe-1 img_check_mark"
                      src={checkMark}
                      alt="checkmark"
                    />

                    <p
                      style={{ lineHeight: "150%" }}
                      className=" text-black font-sm mb-0">
                      {obj}
                    </p>
                  </div>
                ))}
              </div>
              <CustomButton
                text="Sign up"
                btnStyle="w-100 mt-4"
                icon=""
                type="link"
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-5 mt-4 mt-md-0">
            <div className="p-4 bg-white w-100 pricing_cards position-relative h-100">
            <span className="tag position-absolute d-inline-block">
                Over $4000/month in Bonuses 
              </span> 
              <div className="p-3 d-flex justify-content-center">
                <img src={image2} alt="" /> 
              </div>
              <h3 className="text-center mb-0">Premium</h3>
              <h4 className=" text-center fw-medium text-black mb-0">
                <span>$1997</span> /mo Full Support
              </h4>
              <div className="specification_box mt-3">
                {enterpriseSpecification.map((obj, i) => (
                  <div key={i} className="d-flex gap-2 align-items-center pt-3">
                    <img
                      className=" pe-1 img_check_mark"
                       src={checkMark}
                      alt="checkmark"
                    />

                    <p
                      style={{ lineHeight: "150%" }}
                      className=" text-black font-sm mb-0">
                      {obj}
                    </p>
                  </div>
                ))}
              </div>
              <PrimaryButton
                      text="Schedule A Call"
                      btnStyle="btn__  d-block mx-auto mt-4 mt-md-5"
                      target="_blank"
                      url="https://calendly.com/incentive-meet/30min"
                    />
            </div>
          </div>
          {/* <div className="col-sm-10 px-xl-4">
            <p className="font-md text-white px-lg-5 mx-xl-3 text-center mt-4 mt-md-5">
              ¹No purchase necessary. Contests are open to all eligible
              attendees who are active participants of the incentive community.
              All contests are open to eligible participants who "Request to
              Join" a contest based on eligibility criteria.
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
