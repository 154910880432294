import React from "react";
import { Link } from "react-router-dom";

const GhostButton = ({ text, btnStyle, icon, url, isModalVisible }) => {
  return (
    <>
      {url ? (
        <Link
          to={`${url}`}
          className={`common_btn ghost ${btnStyle} text-decoration-none text-center`}
        >
          {text} {icon ? icon : ""}
        </Link>
      ) : (
        <button
          className={`common_btn ghost d-flex align-items-center justify-content-center ${btnStyle}`}
          onClick={isModalVisible}
        >
          {text}{" "}
          {icon ? <span className="ms-2 d-inline-block ">{icon}</span> : ""}
        </button>
      )}
    </>
  );
};

export default GhostButton;
