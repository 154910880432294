// POST OFFERS LIST
import {
    AutoMotive,
    ConstructionIcon,
    Electrician, 
    Contests,
    ECommerce,
    EducationIcon,
    FinancialIcon,
    HealthFitness,
    HomeServices,
    JobsIcon,
    JunkHaulingIcon,
    LegalIcon,
    Misc,
    MovingIcon,
    Nightlife,
    PhotoVideoIcon,
    PlumbingIcon,
    RealEstate,
    Restaurants,
    Retail,
    RomanceIcon,
    SocialIcon,
    TechRepairs,
    TravelTours,
    Pets,
  } from "./Icons";
export const postOffersList = [
    {
      title: "E-Commerce",
      icon: <ECommerce className="e_eommerce" />,
    },
    {
      title: "Health and Supplements",
      icon: <HealthFitness className="health_fitness" />,
    },
    {
      title: "Beauty and Personal Care",
      icon: <RomanceIcon className="social" />,
    },
    {
       title: "Pet Supplies",
       icon: <Pets className="pets" />,
     },
    // {
    //   title: "Financial",
    //   icon: <FinancialIcon className="finance" />,
    // },
    {
      title: "Automotive",
      icon: <AutoMotive className="automotive" />,
    },
    // {
    //   title: "Travel Tours",
    //   icon: <TravelTours className="travel_tours" />,
    // },
    {
      title: "Tech Products",
      icon: <TechRepairs className="tech_repairs" />,
    },
    // {
    //     title: "Hauling",
    //     icon: <JunkHaulingIcon className="junk_hauling" />,
    //   },
    //   {
    //     title: "Moving",
    //     icon: <MovingIcon className="moving_movers" />,
    //   },
    {
      title: "Home Services",
      icon: <HomeServices className="home_services" />,
    },
    // {
    //     title: "Plumbing",
    //     icon: <PlumbingIcon className="plumbing" />,
    // },
    // {
    //     title: "Construction",
    //     icon: <ConstructionIcon className="construction" />,
      
    // },
    // {
    //   title: "Electrician",
    //   icon: <Electrician className="electrician" />,
    
    // },
    // {
    //     title: "Jobs",
    //     icon: <JobsIcon className="hiring" />,
      
    // },
    // {
    //   title: "Contests",
    //   icon: <Contests className="contests" />,
    // },
    // {
    //     title: "PhotoVideo",
    //     icon: <PhotoVideoIcon className="photo_creative" />,
    //   },
    {
      title: "Fashion",
      icon: <Retail className="retail" />,
    },
    // {
    //   title: "Real Estate",
    //   icon: <RealEstate className="real_estate" />,
    // },
    // {
    //     title: "Legal",
    //     icon: <LegalIcon className="legal" />,
    //   },
      {
        title: "E-Books",
        icon: <EducationIcon className="education" />,
      },
    //   {
    //     title: "Nightlife",
    //     icon: <Nightlife className="nightlife" />,
    //   },
      // {
      //   title: "Restaurants",
      //   icon: <Restaurants className="restaurants" />,
      // },
      {
          title: "Jewelry and Accessories",
          icon: <SocialIcon className="music" />,
      },
      {
      title: "Misc",
      icon: <Misc className="misc" />,
    },
  ];