export const IconAttachment = () => {
    return (
      <svg
        width="12"
        height="20"
        viewBox="0 0 12 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.91066 6.80574V14.1315C3.91936 14.6684 4.14423 15.1803 4.53676 15.5569C4.92929 15.9336 5.45799 16.1446 6.00877 16.1446C6.55955 16.1446 7.08825 15.9336 7.48078 15.5569C7.87331 15.1803 8.09818 14.6684 8.10688 14.1315L8.11369 4.50925C8.1194 4.05043 8.03162 3.59506 7.85544 3.16956C7.67926 2.74405 7.41819 2.35686 7.08735 2.03043C6.75651 1.70401 6.36249 1.44485 5.92813 1.26796C5.49377 1.09108 5.02772 1 4.55698 1C4.08624 1 3.62018 1.09108 3.18582 1.26796C2.75146 1.44485 2.35744 1.70401 2.0266 2.03043C1.69576 2.35686 1.43469 2.74405 1.25851 3.16956C1.08233 3.59506 0.994551 4.05043 1.00026 4.50925V14.1964C1.01927 15.4767 1.5544 16.6984 2.48999 17.5972C3.42559 18.4961 4.68648 19 6.00013 19C7.31379 19 8.57468 18.4961 9.51027 17.5972C10.4459 16.6984 10.981 15.4767 11 14.1964V5.14411"
          stroke="#797979"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </svg>
    );
  };

  export const IconEmojiToggle = () => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 0C4.03741 0 0 4.03741 0 9C0 13.9626 4.03741 18 9 18C13.9626 18 18 13.9626 18 9C18 4.03741 13.9627 0 9 0ZM9 17.027C6.79521 17.027 4.7953 16.1333 3.34318 14.6894C2.76328 14.1128 2.27101 13.4482 1.88771 12.7179C1.30392 11.6057 0.972986 10.3408 0.972986 9C0.972986 4.57389 4.57389 0.972986 9 0.972986C11.0994 0.972986 13.0128 1.78348 14.4446 3.1076C15.187 3.79404 15.8001 4.6184 16.2421 5.53982C16.745 6.58815 17.027 7.76175 17.027 9C17.027 13.4261 13.4261 17.027 9 17.027Z"
          fill="#797979"
        />
        <path
          d="M6.06137 7.96602C6.59873 7.96602 7.03435 7.5304 7.03435 6.99304C7.03435 6.45567 6.59873 6.02005 6.06137 6.02005C5.524 6.02005 5.08838 6.45567 5.08838 6.99304C5.08838 7.5304 5.524 7.96602 6.06137 7.96602Z"
          fill="#797979"
        />
        <path
          d="M12.0805 7.96602C12.6179 7.96602 13.0535 7.5304 13.0535 6.99304C13.0535 6.45567 12.6179 6.02005 12.0805 6.02005C11.5432 6.02005 11.1075 6.45567 11.1075 6.99304C11.1075 7.5304 11.5432 7.96602 12.0805 7.96602Z"
          fill="#797979"
        />
        <path
          d="M8.98315 13.9861C10.8655 13.9861 12.6695 13.0234 13.7024 11.4251L12.8851 10.897C11.9366 12.3647 10.2017 13.1838 8.46454 12.9834C7.11076 12.8273 5.85883 12.0473 5.11553 10.897L4.29834 11.4251C5.19774 12.8171 6.71353 13.7609 8.35302 13.95C8.56348 13.9742 8.77365 13.9861 8.98315 13.9861Z"
          fill="#797979"
        />
      </svg>
    );
  };

  export const IconMessage = () => {
    return (
      <svg
        className="transition_03s"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.63116 0.122083C0.737625 -0.324436 -0.251417 0.5281 0.058096 1.47764L2.06518 7.6304C2.12195 7.80422 2.22529 7.95915 2.36396 8.07834C2.50264 8.19754 2.67134 8.27642 2.85171 8.30643L10.782 9.62798C11.1996 9.69799 11.1996 10.298 10.782 10.368L2.85221 11.6896C2.67184 11.7196 2.50314 11.7985 2.36446 11.9177C2.22579 12.0368 2.12245 12.1918 2.06568 12.3656L0.058096 18.5219C-0.251917 19.4719 0.737625 20.3244 1.63116 19.8779L19.3789 11.0065C20.2084 10.5915 20.2084 9.40798 19.3789 8.99346L1.63116 0.122083Z"
          fill="white"
        />
      </svg>
    );
  };
  export const IconBtnArrow = () => {
    return (
      <svg
        width="5"
        height="10"
        viewBox="0 0 5 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.00905848 4.99501C0.00833416 4.9295 0.0200486 4.86454 0.0434484 4.80432C0.0668483 4.74411 0.101416 4.68996 0.144928 4.64536L4.22101 0.14985C4.40217 -0.0499501 4.68297 -0.0499501 4.86413 0.14985C5.04529 0.34965 5.04529 0.659341 4.86413 0.859141L1.10507 5.005L4.85507 9.14086C5.03623 9.34066 5.03623 9.65035 4.85507 9.85015C4.67391 10.05 4.39312 10.05 4.21196 9.85015L0.13587 5.35465C0.04529 5.25475 0 5.12488 0 5.005L0.00905848 4.99501Z"
          fill="#5271FF"
        />
      </svg>
    );
  };