import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Heading = ({ title, subtitle, center }) => {
    return (
      <Container className={center ? 'text-center' : 'text-start'}>
        <h2 className="h2 font-weight-bold">
          {title}
        </h2>
        {subtitle && <p className="text-muted mt-2">
          {subtitle}
        </p>}
      </Container>
    );
  }


const Emptystate = ({
  title = "No Offer Invites Yet",
  subtitle = "Request to Redeem Offers from Hosts.",
  showReset
}) => {
 
 const navigate = useNavigate();

  return (
    <Container className="d-flex h-100 flex-column justify-content-center align-items-center text-center mt-3">
      <Row>
        <Col>
          <Heading
            center
            title={title}
            subtitle={subtitle}
          />
        </Col>
      </Row>
      { (
        <Row className='mb-3'>
          <Col>
            <Button
              variant="outline-primary"
              onClick={() => navigate('/search')}
              className="mt-4"
            >
              Search 
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default Emptystate;