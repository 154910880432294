
// configureStore helps add defaults to create store
import { configureStore, combineReducers } from "@reduxjs/toolkit";

// this reducer handles all changes to user
import userReducer from './userSlice'; 
import eventReducer from './eventSlice';
import messagesSlice from "./messagesSlice";
// will create another slice for events (notify, invite, etc)


// we have a function to keep track of our store
// we have methods that will keep track of the reducers
import { persistStore,
persistReducer,
FLUSH,
REHYDRATE,
PAUSE,
PERSIST,
PURGE,
REGISTER } from "redux-persist";

// actually how store persists state in the browser 
import storage from "redux-persist/lib/storage";

// ensures information is not lost from react until its stored in Redux store

// We give it a key and version to tell it how to persist 
const persistConfig = {
    key: 'root',
    version: 1,
    storage, //store everything in localStorage
}

const rootReducer = combineReducers({
    user: userReducer,
    event: eventReducer, 
    messages: messagesSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer); 

// store.user.currentUser
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck:{
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
})

export const persistor = persistStore(store); 
