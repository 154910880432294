export const IconStopWatch = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M8 1C11.8661 1 15 4.1339 15 8C15 11.8661 11.8661 15 8 15C4.1339 15 1 11.8661 1 8C1 4.1339 4.1339 1 8 1ZM8 2.4C6.51479 2.4 5.09041 2.99 4.0402 4.0402C2.99 5.09041 2.4 6.51479 2.4 8C2.4 9.48521 2.99 10.9096 4.0402 11.9598C5.09041 13.01 6.51479 13.6 8 13.6C9.48521 13.6 10.9096 13.01 11.9598 11.9598C13.01 10.9096 13.6 9.48521 13.6 8C13.6 6.51479 13.01 5.09041 11.9598 4.0402C10.9096 2.99 9.48521 2.4 8 2.4ZM8 3.8C8.17145 3.80002 8.33694 3.86297 8.46506 3.9769C8.59318 4.09083 8.67504 4.24782 8.6951 4.4181L8.7 4.5V7.7102L10.5949 9.6051C10.7204 9.73107 10.7933 9.9001 10.7988 10.0779C10.8042 10.2556 10.7417 10.4288 10.6241 10.5622C10.5065 10.6956 10.3425 10.7792 10.1655 10.7961C9.98842 10.8129 9.81159 10.7618 9.6709 10.653L9.6051 10.5949L7.5051 8.4949C7.39631 8.38601 7.32643 8.2443 7.3063 8.0917L7.3 8V4.5C7.3 4.31435 7.37375 4.1363 7.50503 4.00503C7.6363 3.87375 7.81435 3.8 8 3.8Z"
          fill="#5271FF"
          stroke="#5271FF"
          strokeWidth="0.5"
        />
      </svg>
    );
  };
  export const IconPriceTag = () => {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.00106 15C7.00071 15 7.00029 15 6.99986 15C6.56176 14.9997 6.14981 14.8288 5.84014 14.5186L1.47847 10.1507C0.840509 9.51151 0.840509 8.47151 1.47847 7.83243L7.35806 1.94156C7.96398 1.33445 8.7702 1 9.62811 1H13.3584C14.263 1 14.999 1.73589 14.999 2.64062V6.35946C14.999 7.21677 14.6649 8.02264 14.0584 8.62848L8.16052 14.5202C7.85068 14.8296 7.4389 15 7.00106 15ZM9.62811 2.09375C9.06277 2.09375 8.53154 2.31412 8.13223 2.7142L2.25256 8.60507C2.03987 8.81818 2.03987 9.16476 2.25256 9.37787L6.61423 13.7458C6.71736 13.8491 6.8546 13.9062 7.00071 13.9062H7.00114C7.14709 13.9062 7.28432 13.8494 7.38754 13.7464L13.2854 7.85474C13.6852 7.45552 13.9053 6.92437 13.9053 6.35946V2.64062C13.9053 2.33907 13.6599 2.09375 13.3584 2.09375H9.62811ZM11.0341 6.55078C10.1294 6.55078 9.39346 5.81489 9.39346 4.91016C9.39346 4.00542 10.1294 3.26953 11.0341 3.26953C11.9387 3.26953 12.6747 4.00542 12.6747 4.91016C12.6747 5.81489 11.9387 6.55078 11.0341 6.55078ZM11.0341 4.36328C10.7325 4.36328 10.4872 4.60861 10.4872 4.91016C10.4872 5.21171 10.7325 5.45703 11.0341 5.45703C11.3357 5.45703 11.581 5.21171 11.581 4.91016C11.581 4.60861 11.3357 4.36328 11.0341 4.36328Z"
          fill="#5271FF"
          stroke="#5271FF"
          strokeWidth="0.6"
        />
      </svg>
    );
  };
  export const IconLocation = () => {
    return (
      <svg
        width="13"
        height="16"
        viewBox="0 0 13 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.2 6.6C12.2 10.8 6.6 15 6.6 15C6.6 15 1 10.8 1 6.6C1 5.11479 1.59 3.69041 2.6402 2.6402C3.69041 1.59 5.11479 1 6.6 1C8.08521 1 9.50959 1.59 10.5598 2.6402C11.61 3.69041 12.2 5.11479 12.2 6.6Z"
          stroke="#5271FF"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.6 8.7C7.7598 8.7 8.7 7.7598 8.7 6.6C8.7 5.4402 7.7598 4.5 6.6 4.5C5.4402 4.5 4.5 5.4402 4.5 6.6C4.5 7.7598 5.4402 8.7 6.6 8.7Z"
          stroke="#5271FF"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  export const IconBarCode = () => {
    return (
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9313 9.54554C10.9313 9.89666 10.6457 10.1819 10.2949 10.1819H5.20401C4.85321 10.1819 4.56765 9.89666 4.56765 9.54554C4.56765 9.1938 4.28271 8.90918 3.93129 8.90918C3.57986 8.90918 3.29492 9.1938 3.29492 9.54554C3.29492 10.5983 4.15128 11.4546 5.20401 11.4546H10.2949C11.3477 11.4546 12.204 10.5983 12.204 9.54554C12.204 9.1938 11.9191 8.90918 11.5676 8.90918C11.2162 8.90918 10.9313 9.1938 10.9313 9.54554Z"
          fill="#5271FF"
        />
        <path
          d="M10.2949 2.54541H5.20401C4.15128 2.54541 3.29492 3.40177 3.29492 4.4545C3.29492 4.80624 3.57986 5.09086 3.93129 5.09086C4.28271 5.09086 4.56765 4.80624 4.56765 4.4545C4.56765 4.10338 4.85321 3.81814 5.20401 3.81814H10.2949C10.6457 3.81814 10.9313 4.10338 10.9313 4.4545C10.9313 4.80624 11.2162 5.09086 11.5676 5.09086C11.9191 5.09086 12.204 4.80624 12.204 4.4545C12.204 3.40177 11.3477 2.54541 10.2949 2.54541Z"
          fill="#5271FF"
        />
        <path
          d="M1.38636 3.18182C1.73779 3.18182 2.02273 2.89719 2.02273 2.54545V1.90909C2.02273 1.55797 2.30828 1.27273 2.65909 1.27273H3.29545C3.64688 1.27273 3.93182 0.988104 3.93182 0.636364C3.93182 0.284624 3.64688 0 3.29545 0H2.65909C1.60636 0 0.75 0.856357 0.75 1.90909V2.54545C0.75 2.89719 1.03493 3.18182 1.38636 3.18182Z"
          fill="#5271FF"
        />
        <path
          d="M12.8411 0H12.2047C11.8533 0 11.5684 0.284624 11.5684 0.636364C11.5684 0.988104 11.8533 1.27273 12.2047 1.27273H12.8411C13.1919 1.27273 13.4775 1.55797 13.4775 1.90909V2.54545C13.4775 2.89719 13.7624 3.18182 14.1138 3.18182C14.4652 3.18182 14.7502 2.89719 14.7502 2.54545V1.90909C14.7502 0.856357 13.8938 0 12.8411 0Z"
          fill="#5271FF"
        />
        <path
          d="M2.65909 14.0001H3.29545C3.64688 14.0001 3.93182 13.7154 3.93182 13.3637C3.93182 13.012 3.64688 12.7273 3.29545 12.7273H2.65909C2.30828 12.7273 2.02273 12.4421 2.02273 12.091V11.4546C2.02273 11.1029 1.73779 10.8182 1.38636 10.8182C1.03493 10.8182 0.75 11.1029 0.75 11.4546V12.091C0.75 13.1437 1.60636 14.0001 2.65909 14.0001Z"
          fill="#5271FF"
        />
        <path
          d="M14.1138 10.8182C13.7624 10.8182 13.4775 11.1029 13.4775 11.4546V12.091C13.4775 12.4421 13.1919 12.7273 12.8411 12.7273H12.2047C11.8533 12.7273 11.5684 13.012 11.5684 13.3637C11.5684 13.7154 11.8533 14.0001 12.2047 14.0001H12.8411C13.8938 14.0001 14.7502 13.1437 14.7502 12.091V11.4546C14.7502 11.1029 14.4652 10.8182 14.1138 10.8182Z"
          fill="#5271FF"
        />
        <path
          d="M13.478 7.00013C13.478 6.64839 13.193 6.36377 12.8416 6.36377H2.6598C2.30837 6.36377 2.02344 6.64839 2.02344 7.00013C2.02344 7.35187 2.30837 7.6365 2.6598 7.6365H12.8416C13.193 7.6365 13.478 7.35187 13.478 7.00013Z"
          fill="#5271FF"
        />
      </svg>
    );
  };
  export const IconChat = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          d="M2.53698 17.1591C1.80185 17.1591 1.08365 16.9715 0.432764 16.606C0.0978715 16.4335 -0.0318382 16.002 0.168226 15.6774C0.832948 14.6152 1.07298 13.3168 0.768637 12.0958C0.453185 10.8299 -0.0032498 9.7637 1.74431e-05 8.42359C0.0113439 3.72196 3.92469 -0.0896071 8.62152 0.00160358C13.1581 0.0929776 16.8969 3.90651 16.8969 8.44428C16.897 14.3707 10.686 18.5315 5.20289 16.243C4.44712 16.8362 3.49717 17.1591 2.53698 17.1591ZM1.64372 15.7121C2.6766 16.0329 3.83827 15.7766 4.63477 15.0066C4.83102 14.8168 5.12459 14.7691 5.37083 14.8869C10.0652 17.1313 15.59 13.6133 15.59 8.44428C15.59 4.58708 12.4521 1.38588 8.59522 1.30823C4.6205 1.22927 1.3165 4.44838 1.30692 8.42675C1.30381 9.69961 1.80043 10.7169 2.07183 11.9226C2.35777 13.1933 2.20715 14.537 1.64372 15.7121Z"
          fill="white"
        />
        <path
          d="M19.4607 22.0001C18.5005 22.0001 17.5505 21.6772 16.7949 21.0841C13.8789 22.3011 10.4102 21.7468 8.01932 19.6821C7.74618 19.4462 7.71602 19.0336 7.95186 18.7604C8.18775 18.4873 8.60035 18.457 8.87349 18.693C10.994 20.5242 14.0996 20.9362 16.6269 19.728C16.8731 19.6101 17.1666 19.6579 17.3629 19.8477C18.1594 20.6177 19.3211 20.8741 20.354 20.5532C19.6893 19.1668 19.5973 17.5785 20.1123 16.1082C20.117 16.0949 20.122 16.0819 20.1275 16.069C20.5036 15.1822 20.6932 14.2397 20.6909 13.2678C20.6867 11.5542 20.1167 9.96913 19.0423 8.68374C18.8109 8.40678 18.8477 7.99473 19.1246 7.7633C19.4015 7.53182 19.8137 7.56873 20.0451 7.84563C21.2995 9.34644 21.993 11.2709 21.9978 13.2646C22.0005 14.4066 21.779 15.5148 21.3392 16.5591C20.8774 17.8961 21.0898 19.3276 21.8294 20.5184C22.0297 20.843 21.8998 21.2746 21.5648 21.4471C20.9141 21.8125 20.1957 22.0001 19.4607 22.0001Z"
          fill="white"
        />
        <path
          d="M8.44804 9.5101C9.01909 9.5101 9.48201 9.04742 9.48201 8.47667C9.48201 7.90592 9.01909 7.44324 8.44804 7.44324C7.87699 7.44324 7.41406 7.90592 7.41406 8.47667C7.41406 9.04742 7.87699 9.5101 8.44804 9.5101Z"
          fill="white"
        />
        <path
          d="M4.63847 9.50998C5.20952 9.50998 5.67244 9.04729 5.67244 8.47655C5.67244 7.9058 5.20952 7.44312 4.63847 7.44312C4.06742 7.44312 3.60449 7.9058 3.60449 8.47655C3.60449 9.04729 4.06742 9.50998 4.63847 9.50998Z"
          fill="white"
        />
        <path
          d="M12.2576 9.5101C12.8287 9.5101 13.2916 9.04742 13.2916 8.47667C13.2916 7.90592 12.8287 7.44324 12.2576 7.44324C11.6866 7.44324 11.2236 7.90592 11.2236 8.47667C11.2236 9.04742 11.6866 9.5101 12.2576 9.5101Z"
          fill="white"
        />
      </svg>
    );
  };
  export const IconEdit = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M21.408 14.806C21.1039 14.806 20.8574 15.0525 20.8574 15.3567V20.2462C20.8563 21.1583 20.1174 21.8974 19.2053 21.8983H3.75345C2.84137 21.8974 2.10246 21.1583 2.10138 20.2462V5.89579C2.10246 4.98392 2.84137 4.24479 3.75345 4.24372H8.64299C8.94716 4.24372 9.19368 3.9972 9.19368 3.69302C9.19368 3.38907 8.94716 3.14233 8.64299 3.14233H3.75345C2.23346 3.14405 1.00172 4.3758 1 5.89579V20.2465C1.00172 21.7664 2.23346 22.9982 3.75345 22.9999H19.2053C20.7253 22.9982 21.957 21.7664 21.9587 20.2465V15.3567C21.9587 15.0525 21.7122 14.806 21.408 14.806Z"
          fill="#5271FF"
          stroke="#5271FF"
          strokeWidth="0.6"
        />
        <path
          d="M21.7397 1.72585C20.7719 0.758051 19.2029 0.758051 18.2351 1.72585L8.41043 11.5505C8.3431 11.6178 8.29448 11.7013 8.2691 11.793L6.97713 16.4573C6.92399 16.6485 6.97799 16.8533 7.11824 16.9938C7.25871 17.134 7.4635 17.188 7.65473 17.1351L12.319 15.8429C12.4107 15.8175 12.4941 15.7689 12.5615 15.7016L22.3859 5.87668C23.3522 4.90824 23.3522 3.34049 22.3859 2.37205L21.7397 1.72585ZM9.61033 11.9085L17.6511 3.86752L20.2443 6.46071L12.2033 14.5017L9.61033 11.9085ZM9.09234 12.9479L11.1641 15.0199L8.29835 15.8139L9.09234 12.9479ZM21.6072 5.09797L21.0232 5.682L18.4298 3.08859L19.014 2.50456C19.5516 1.96699 20.4232 1.96699 20.9608 2.50456L21.6072 3.15076C22.1439 3.68897 22.1439 4.55997 21.6072 5.09797Z"
          fill="#5271FF"
          stroke="#5271FF"
          strokeWidth="0.6"
        />
      </svg>
    );
  };
  
  export const IconComma = () => {
    return (
      <svg
        width="26"
        height="20"
        viewBox="0 0 26 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.19822 0.333347C7.36851 0.20202 7.56229 0.106468 7.76849 0.0521462C7.97469 -0.00217534 8.18928 -0.0142026 8.4 0.0167515C8.61072 0.0477057 8.81345 0.121035 8.99661 0.232552C9.17977 0.34407 9.33978 0.491592 9.4675 0.666695C9.59521 0.841798 9.68814 1.04105 9.74097 1.25308C9.79379 1.46512 9.80549 1.68577 9.77539 1.90245C9.74528 2.11913 9.67397 2.32759 9.56552 2.51593C9.45707 2.70427 9.3136 2.8688 9.14332 3.00013C6.60821 4.95521 5.15587 6.85695 4.32435 8.51702C5.46607 8.21481 6.67099 8.28586 7.77163 8.72029C8.87227 9.15471 9.81396 9.93093 10.4658 10.9411C11.1176 11.9512 11.4472 13.145 11.4088 14.3567C11.3703 15.5683 10.9658 16.7375 10.2513 17.7018C9.53679 18.6661 8.5479 19.3775 7.42206 19.7372C6.29622 20.097 5.08936 20.0871 3.96922 19.709C2.84909 19.3309 1.87133 18.6034 1.17185 17.6276C0.472366 16.6517 0.0859164 15.476 0.0662119 14.2639C-0.141488 12.2171 0.141212 10.149 0.889635 8.24034C1.85732 5.73024 3.74892 2.99346 7.19822 0.333347ZM21.7864 0.333347C21.9567 0.20202 22.1505 0.106468 22.3567 0.0521462C22.5629 -0.00217534 22.7775 -0.0142026 22.9882 0.0167515C23.1989 0.0477057 23.4016 0.121035 23.5848 0.232552C23.768 0.34407 23.928 0.491592 24.0557 0.666695C24.1834 0.841798 24.2763 1.04105 24.3292 1.25308C24.382 1.46512 24.3937 1.68577 24.3636 1.90245C24.3335 2.11913 24.2622 2.32759 24.1537 2.51593C24.0453 2.70427 23.9018 2.8688 23.7315 3.00013C21.1964 4.95521 19.7441 6.85695 18.9125 8.51702C20.0543 8.21481 21.2592 8.28586 22.3598 8.72029C23.4605 9.15471 24.4022 9.93093 25.054 10.9411C25.7058 11.9512 26.0354 13.145 25.997 14.3567C25.9585 15.5683 25.554 16.7375 24.8395 17.7018C24.125 18.6661 23.1361 19.3775 22.0103 19.7372C20.8844 20.097 19.6776 20.0871 18.5574 19.709C17.4373 19.3309 16.4595 18.6034 15.76 17.6276C15.0606 16.6517 14.6741 15.476 14.6544 14.2639C14.4467 12.2171 14.7294 10.149 15.4778 8.24034C16.4471 5.73024 18.3371 2.99346 21.7864 0.333347Z"
          fill="#5271FF"
        />
      </svg>
    );
  };
  
  export const IconCrossModals = () => {
    return (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.851 6.79552C18.9366 6.71011 19.0044 6.60869 19.0507 6.49706C19.0971 6.38542 19.1209 6.26575 19.121 6.14489C19.1211 6.02403 19.0974 5.90433 19.0512 5.79264C19.005 5.68094 18.9373 5.57944 18.8518 5.49392C18.7664 5.40841 18.665 5.34055 18.5534 5.29423C18.4417 5.24791 18.3221 5.22403 18.2012 5.22395C18.0803 5.22388 17.9606 5.24761 17.849 5.2938C17.7373 5.33998 17.6358 5.40771 17.5502 5.49312L11.9998 11.0435L6.45104 5.49312C6.27833 5.32042 6.04409 5.22339 5.79984 5.22339C5.55559 5.22339 5.32135 5.32042 5.14864 5.49312C4.97593 5.66583 4.87891 5.90008 4.87891 6.14432C4.87891 6.38857 4.97593 6.62282 5.14864 6.79552L10.699 12.3443L5.14864 17.8931C5.06313 17.9786 4.99529 18.0802 4.94901 18.1919C4.90273 18.3036 4.87891 18.4234 4.87891 18.5443C4.87891 18.6653 4.90273 18.785 4.94901 18.8967C4.99529 19.0085 5.06313 19.11 5.14864 19.1955C5.32135 19.3682 5.55559 19.4653 5.79984 19.4653C5.92078 19.4653 6.04054 19.4414 6.15227 19.3952C6.264 19.3489 6.36553 19.281 6.45104 19.1955L11.9998 13.6451L17.5502 19.1955C17.723 19.368 17.9571 19.4648 18.2012 19.4647C18.4453 19.4645 18.6793 19.3674 18.8518 19.1947C19.0243 19.022 19.1212 18.7879 19.121 18.5438C19.1209 18.2997 19.0238 18.0656 18.851 17.8931L13.3006 12.3443L18.851 6.79552Z"
          fill="black"
        />
      </svg>
    );
  };
  export const CameraIcon = () => {
    return (
      <svg
        width="17"
        height="13"
        viewBox="0 0 17 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="transition_03s"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.30735 0.964817C4.73729 0.36205 5.4611 0 6.23619 0H10.7638C11.5389 0 12.2627 0.36205 12.6927 0.964817L13.3205 1.84506C13.4638 2.04598 13.7051 2.16667 13.9635 2.16667H14.6818C15.9621 2.16667 17 3.13672 17 4.33333V8.06773C16.7583 7.98792 16.4982 7.94444 16.2273 7.94444C16.2273 6.74779 15.1894 5.77778 13.9091 5.77778C12.6288 5.77778 11.5909 6.74779 11.5909 7.94444C11.5581 7.94444 11.5255 7.94509 11.493 7.94632C11.5569 7.71492 11.5909 7.47226 11.5909 7.22222C11.5909 5.62676 10.207 4.33333 8.5 4.33333C6.79294 4.33333 5.40909 5.62676 5.40909 7.22222C5.40909 8.81768 6.79294 10.1111 8.5 10.1111C8.76752 10.1111 9.02715 10.0793 9.27474 10.0196C9.27342 10.0499 9.27273 10.0805 9.27273 10.1111C9.27273 11.3078 10.3106 12.2778 11.5909 12.2778C11.5909 12.531 11.6374 12.7741 11.7228 13H2.31818C1.03789 13 0 12.03 0 10.8333V4.33333C0 3.13672 1.03789 2.16667 2.31818 2.16667H3.03653C3.2949 2.16667 3.53617 2.04598 3.67948 1.84506L4.30735 0.964817ZM13.9091 7.22222C14.3359 7.22222 14.6818 7.54556 14.6818 7.94444V9.38889H16.2273C16.654 9.38889 17 9.71223 17 10.1111C17 10.51 16.654 10.8333 16.2273 10.8333H14.6818V12.2778C14.6818 12.6767 14.3359 13 13.9091 13C13.4823 13 13.1364 12.6767 13.1364 12.2778V10.8333H11.5909C11.1641 10.8333 10.8182 10.51 10.8182 10.1111C10.8182 9.71223 11.1641 9.38889 11.5909 9.38889H13.1364V7.94444C13.1364 7.54556 13.4823 7.22222 13.9091 7.22222Z"
          fill="#5271FF"
        />
      </svg>
    );
  };
  export const IconButtonArrow = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <rect opacity="0.1" width="30" height="30" rx="10" fill="white" />
        <path
          d="M17.5 14.8754L12.0322 19.7509L11.2667 19.0683L15.969 14.8754L11.2667 10.6826L12.0322 10L17.5 14.8754Z"
          fill="white"
        />
      </svg>
    );
  };