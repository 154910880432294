import React, {useState} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const PrivacyModal = ({ isOpen, onClose }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [cookiePreference, setCookiePreference] = useState(null); // 'all', 'necessary', or null

  const handleModalClose = () => {
    if (isChecked && cookiePreference) {
      onClose();
    } else {
      alert('Please agree to the privacy policy and select a cookie preference to continue.');
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Modal show={isOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Privacy Consent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
        We use cookies to provide essential services and analyze website traffic and optimize your website experience. 
        You can find details in our  <LinkContainer to="/privacy"><a href="#" className="text-decoration-none">Privacy Policy</a></LinkContainer>.
        </p>
        <p>
          By using this site, you confirm that you are over the age of 18. We do not knowingly collect information from anyone under the age of 18.
        </p>
        <Form.Group className='mb-2'>
          <Form.Check 
            type="checkbox"
            label="I agree to the privacy policy"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </Form.Group>
        <h3>Cookie Preferences</h3>
        <Form.Group>
          <Form.Check 
            type="radio"
            name="cookiePreference"
            label="Allow all cookies"
            value="all"
            checked={cookiePreference === 'all'}
            onChange={() => setCookiePreference('all')}
          />
          <Form.Check 
            type="radio"
            name="cookiePreference"
            label="Only necessary cookies- may impact or not allow certain features on the site"
            value="necessary"
            checked={cookiePreference === 'necessary'}
            onChange={() => setCookiePreference('necessary')}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PrivacyModal;
