import React from "react";

const WhiteCard = ({ content, title, className }) => {
  return (
    <div className={`${className}`} id="white-card">
      {title && <h2>{title}</h2>}
      {content}
    </div>
  );
};

export default WhiteCard;