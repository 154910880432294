 import { createSlice } from "@reduxjs/toolkit";

 const initialState = {
    currentUser: null,
    loading: false, 
    error: false
 };

 // name will be stat.user but value is currentUser
 export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers:{
        loginStart: (state)=> {
            state.loading = true; 
        },
        loginSuccess: (state, action)=> {
            state.loading = false;
            state.currentUser = action.payload;
        },
        loginFailure: (state,action)=> {
            state.loading = false;
            state.error = action.payload;  
        },
        registerStart: (state) => {
            state.loading = true;
        },
        // this means they just registered didn't confirm
        registerSuccess: (state) => {
            state.loading = false;
            state.registrationComplete = false;
        },
        // Note: Same as successful login 
        registerConfirm: (state, action) => {
            state.registrationComplete = true;
            state.currentUser = action.payload;
            state.error = false; 
        },
        registerFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload;
        },
        userRouteError: (state, action) =>{
            state.loading = false;
            state.error = action.payload;
        },
        clearError: (state) =>{
            state.loading = false;
            state.error = false;
        },
        updateUser: (state, action) => {
            const { fieldName, value } = action.payload;
            state.currentUser[fieldName] = value;
          },
        logout:() =>{
            return initialState; 
        }
    }
 });

 export const {loginStart,loginSuccess, loginFailure, logout, 
registerStart, registerSuccess, registerFailure, registerConfirm, userRouteError, clearError, updateUser} = userSlice.actions;


export default userSlice.reducer; 