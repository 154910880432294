import {useContext, useEffect, useState, useCallback} from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image'; 
import DropdownButton from 'react-bootstrap/DropdownButton'
import FormControl from 'react-bootstrap/FormControl';
import LinkContainer from 'react-router-bootstrap/LinkContainer';
import logo from '../assets/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faBell, faSearch } from '@fortawesome/free-solid-svg-icons';
import './navigation.scss'; 
import './sample.scss';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/userSlice';
import { AppContext } from '../AppContext';
import { useLocation, useNavigate} from "react-router-dom";
import { incrementUnreadCount, addNotifications, clearAllNotifications, logoutNotifications } from '../redux/messagesSlice';
import axios from '../backendClient';
import Customautocomplete from '../pages/event/Customautocomplete';
import { AsyncTypeahead } from 'react-bootstrap-typeahead'; // Import Typeahead

import Logo from "../components/images/svg/logo-dashbord.svg"; 
import imageNotify from '../components/images/svg/icon-messages.svg'; 
import crossIcon from '../components/images/svg/cross-dashbord.svg'; 
import hamburgerIcon from '../components/images/svg/toggle-deshbord.svg'; 
import { Link } from "react-router-dom";



export const Navigation = ({onVariableChange}) => {

  const {currentUser} = useSelector(state=>state.user);
  const totalUnreadCount = useSelector(state=> state.messages.totalUnreadCount);
  const notificationCount = useSelector(state => state.messages.notificationsUnclearedCount); 
  const notificationsFromStore = useSelector(state => Object.values(state.messages.notifications));
  const dispatch = useDispatch();
  const socket = useContext(AppContext); 
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]); 
  const [open, setOpen] = useState(false); 


  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [isNavVisible, setIsNavVisible] = useState(false);

  const handleSearchEvent = (queryVal) => {
    onVariableChange(queryVal);
  };

  const IconNotify = () => {
    return (
      <svg
        width="15"
        height="17"
        viewBox="0 0 15 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8713 11.98C12.789 11.0748 12.1684 9.75163 12.1684 8.34983V6.375C12.1684 3.88238 10.2973 1.819 7.87368 1.47333V0.708333C7.87368 0.316625 7.55301 0 7.1579 0C6.76278 0 6.44211 0.316625 6.44211 0.708333V1.47333C4.01773 1.819 2.14737 3.88238 2.14737 6.375V8.34983C2.14737 9.75163 1.52678 11.0748 0.438063 11.9857C0.159621 12.2216 0 12.5644 0 12.9271C0 13.6106 0.561895 14.1667 1.25263 14.1667H13.0632C13.7539 14.1667 14.3158 13.6106 14.3158 12.9271C14.3158 12.5644 14.1562 12.2216 13.8713 11.98Z"
          fill="white"
        />
        <path
          d="M7.15801 17C8.4543 17 9.53873 16.0855 9.78782 14.875H4.5282C4.77729 16.0855 5.86171 17 7.15801 17Z"
          fill="white"
        />
      </svg>
    );
  };

  // Get the current pathname using a custom hook (replace with actual hook)
  // Effect for handling scroll events
  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      let visibleHeader = isHeaderVisible;
      // Check if the user has scrolled more than 150 pixels
      if (currentScrollPosition > 400) {
        // Determine if the user is scrolling up or down
        visibleHeader = prevScrollPosition > currentScrollPosition;
      }
      // Update the state with the current scroll position and header visibility
      setPrevScrollPosition(currentScrollPosition);
      setIsHeaderVisible(visibleHeader);
    };
    // Add scroll event listener when the component mounts
    //window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener when the component unmounts
    return () => {
      //window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPosition, isHeaderVisible]); // Dependencies for the useEffect hook


  const displayNotification = (n) => {
    let action; 
    let link; 
    // 1= viewed, 2 = active invite , 3 = sender requested invite, 
    //set unique styling and text for the action as well as a link to the page can change 
    if(n.type === 1){ 
      action = "viewed your profile";
      link = `/profile/${n.sender._id}`;
    }
    else if(n.type === 2){
      action = "is joining your offer!";
      link = '/chat'; 
    } 
    else if (n.type===3){
      action = "invited you to an offer!";
      link = `/invites`;
    } 
    else if (n.type===4){
      action = "requests to join your offer!";
      link = `/invites`;
    }
    else if(n.type===5){
      action = "'s offer is now available in chat!"
      link = `/event`; 
    }
    else if(n.type===6){
      action = "has active invites!";
      link = `/activeinvites`; 
    }
    else if (n.type===7){
      action = " declined your request"
      link = `/eventlisting`; 
    }
    //console.log(notifications); 
    return (
      <LinkContainer to={link}>
      <Dropdown.Item className="notification">
        {`${n.sender.userName} ${action}!`}
      </Dropdown.Item>
      </LinkContainer>
    )

  }


  const CustomDropdown = ({ title, icon, itemList, customStyles }) => {


    return (
      <DropdownButton
        className="custom-dropdown"
        id="dropdown-basic-button"
        title={<><FontAwesomeIcon className='message-iconbell' icon={faBell} /><Badge pill variant="danger" className="unread-badge">{notificationCount > 0 && notificationCount}</Badge></>}>
        {itemList.map((obj, i) => (
            displayNotification(obj)
        ))}
      {notificationCount > 0 && 
               <>
                 <Dropdown.Divider />
                 <Dropdown.Item onClick={handleRead}>Clear All</Dropdown.Item>
              </>
               }
      </DropdownButton>
    );
  };


  const RegularSearch = () =>{
    const [query, setQuery] = useState('');

    const [singleSelections, setSingleSelections] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]); 
   // const options = [
      // { id: '1', name: 'Offer 1' },
      // { id: '2', name: 'Offer 2' },
      // { id: '3', name: 'Event 1' },
      // Add more options as needed
   // ];


     // Function to fetch options based on user input
    const fetchOptions = async (query) => {
      try {
        // Replace '/api/search' with your actual backend endpoint
        setIsLoading(true); 
        const response = await axios.get(`api/events/eventOptions?query=${query}`);
        //alert(response.data); 
        if (response.data) {
          // Assuming the response data is the array of options
          setOptions(response.data);
         }
         setIsLoading(false); 
        } catch (error) {
        console.error('Failed to fetch options:', error);
        setIsLoading(false); 
      }
    };
  


    const handleSearchClick = () => {
      // Navigate to a route with the query
      //if no current User route to /publiclisting
      const searchQuery = singleSelections.length > 0 ? singleSelections[0].name : '';
      handleSearchEvent(searchQuery); 
      navigate(`/eventlisting?query=${encodeURIComponent(searchQuery)}`);
      //navigate(`/eventlisting?query=${encodeURIComponent(query)}`);
    };

    const handleKeyPress = (e) => {
      if(e.key === "Enter" || e.keyCode ===13){
        navigate(`/eventlisting?query=${encodeURIComponent(query)}`); 
      }
    }
  

   return (
    //   <>
    // <FormControl style={{maxWidth: '950px'}} type="text" placeholder="Search by Offers" className="search-input" value={query} onChange={(e)=> setQuery(e.target.value)} onKeyDown={handleKeyPress} />
    // <FontAwesomeIcon className='search-icon' onClick={handleSearchClick} icon={faSearch}/>
    // </>
    <>
    <div style={{ maxWidth: '100%', position: 'relative' }}>
      <AsyncTypeahead
        filterBy={()=> true}
        id="async-search"
        isLoading={isLoading}
        labelKey="name"
        minLength={3}
        onSearch={fetchOptions}
        onChange={setSingleSelections}
        options={options}
        placeholder="Search by Offers"
        selected={singleSelections}
        onKeyDown={handleKeyPress}
      />
      <FontAwesomeIcon
        className='search-icon'
        onClick={handleSearchClick}
        icon={faSearch}
        style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}
      />
    </div>
  </>
  );
} 

  const location = useLocation(); 
  const onSearchPage = location.pathname.includes('/search');
  const [currentSearch, setCurrentSearch] = useState('regular'); 
  const [city, setCity] = useState(''); 


  const CitySearch = ({setCity}) => {

    const [firstPrediction, setFirstPrediction] = useState(''); 
    const [inputValue, setInputValue] = useState("");

    const searchCity = useCallback(async () => {
      // Determine which API to call based on searchType
      const apiURL = `/api/events/cities?cityName=${inputValue}`;
      const response = await axios(apiURL); //{ withCredentials: true }
      //Debug below: 
      //console.log(response);
      

      if (response) {
          setFirstPrediction(response.data.predictions[0]?.description);
      }
  },[inputValue]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (inputValue) {
        searchCity();
      }
    }, 500); // Delay in ms

    return () => clearTimeout(timerId);
  }, [inputValue, searchCity]);

  const updateCityValue = (newCity) => {
    setInputValue(newCity);
    setCity(newCity); // Update the parent's state
  };


    const handleBlur = () => {
      updateCityValue(firstPrediction);
    };
  
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' || event.keyCode ===13) {
        updateCityValue(firstPrediction);
        navigate(`/search?city=${firstPrediction}`); 
      }
    };

    return (
      <Form.Control 
        style={{maxWidth: '100%', padding: '0px'}} 
        placeholder={'Search City'}
        type="text" 
        value={inputValue} 
        onChange={(e) => setInputValue(e.target.value)} 
        onBlur={handleBlur} 
        onKeyDown={handleKeyDown}
      />
    );

  }

  const CustomSearch = () => (
    <>
    <Customautocomplete style={{maxWidth: '950px'}} className="search-input" onSelect={setCity} searchType="city" />
    </>
  );

  useEffect(()=>{
    if(onSearchPage){
      setCurrentSearch('custom');
    }else{
      setCurrentSearch('regular'); 
    }

  },[onSearchPage]);


  useEffect(() => {
    if(notificationsFromStore.length !== notifications.length){setNotifications(notificationsFromStore);}
  }, [notifications, dispatch, notificationsFromStore]);



  const handleRead = async() => {
  
    try{

      await axios.put('/api/messages/clearAllNotifications');

    }catch(e){
      console.log('Failed to remove notifications', e);
    }
      // Your handleRead logic here
    dispatch(clearAllNotifications());
    setNotifications([]); 
  };


  useEffect(() => {
    console.log("use effect runs but doesn't go into socket");
    console.log("socket is:", socket);
    if (socket) {
      console.log("use effect and socket check passes");

      socket.on('connect', ()=>{
        const userId = currentUser._id; 
        socket.emit('register', userId); 
      });

      socket.on('disconnect', () => {
        // Redirect to home page or login page
        //navigate('/login');
      });


 

      socket.on("increment-message", async (room)=>{
          dispatch(incrementUnreadCount(room));
          //we persist via redis on Send_message
      });

      socket.on("receiveNotification", async({sender, type, optionalObj=null})=>{

        // check to see if the notifications[key] already has it or not key = sender + type if !notification[key] then setNotifications
        const key = `${sender._id}-${Number(type)}`;
        const foundKey = notificationsFromStore.some((item) => {
          const keyItem = `${item.sender._id}-${item.type}`;
          return keyItem === key;
        });
       
        if(notificationsFromStore.length === 0 || !foundKey){
          if(type===1){
            setNotifications((prev) => [...prev, {sender, type}])
          }
          if(type===3){
            setNotifications((prev) => [...prev, {sender, type}])
          }
          if(type===2){
            setNotifications((prev) => [...prev, {sender, type}]); 
          }
          if(type===4){
            setNotifications((prev) => [...prev, {sender, type}]); 
          }
          if(type===5){
            setNotifications((prev) => [...prev, {sender, type}]);
          }
          if(type===6){
            setNotifications((prev) => [...prev, {sender, type}]);
          }
          dispatch(addNotifications({sender, type})); 
      }


        try{

          const response = await axios.post('/api/messages/addNotification',{sender: sender, notificationType: type, notificationMessage: optionalObj });

        }catch(e){
          console.log('Failed to set unread notification ', e);
        }

      });



      socket.on('error', (error) => {
        // Redirect to home page or login page
        navigate('/login');
      });
    }

    // Cleanup listeners when component unmounts
    return () => {
      if (socket) {
        socket.off('error');
        socket.off('increment-message');
      }
    };
  }, [socket]);


  const handleLogout = () => {
    dispatch(logoutNotifications()); 
    dispatch(logout());
  }

  const navLinks = [
    {
      link: "ProductGPT",
      url: "/trygpt",
    },
    {
      link: "View Offers",
      url: "/publiclisting",
    },
    {
      link: "FAQ",
      url: "/faq",
    },
    {
      link: "InviteCode",
      url: "/invitecode",
    }
  ];
  
  const navLinksLogged = [
    {
      link: "ProductGPT",
      url: "/productgpt",
    },
    {
      link: "MyOffers",
      url: "/event",
    },
    {
      link: "Offers",
      url: "/eventlisting",
    },
    {
      link: "Dashboard",
      url: "/dashboard",
    },
    {
      link: "Search",
      url: "/search",
    },
    {
      link: "My Profile",
      url: "/profile",
    },
    {
      link: "Invites",
      url: "/invites",
    },
    {
      link: "Settings",
      url: "/settings",
    },
    {
      link: "InviteCode",
      url: "/invitecode",
    },
    {
      link: "FAQ",
      url: "/faq",
    },
  ];

  const [navList, setNavList ] = useState(navLinks); 

 

  useEffect(() => {
    if(currentUser){
      setNavList(navLinksLogged); 
    }
    else{
      setNavList(navLinks); 
    }
  }, [currentUser]);



  
  return (
    <>
      <div
        id="dashbord-nav"
        className={`bg-white drop-shaddow py-2 start-0 z-3 transition_03s position-relative top-0 w-100 d-flex align-items-center ${
          isHeaderVisible ? "translate_y_0 " : "translate_y_full"
        }`}>
        <nav
          className=" d-flex justify-content-between w-100 align-items-center mx-auto px_12"
          style={{
            maxWidth: "1360px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}>
          <div className="d-flex align-items-center">
            <Link
              className="py-0"
              to="/home"
              rel="noopener"
              aria-label=" redirect to home page">
              <img className="dashbord__logo" src={Logo} alt="page logo" />
            </Link>
            <div className="ms-5 d-none d-md-block">
              <Form className="search-container mx-auto d-flex" style={{ display: 'inline' }}>
       {currentSearch === 'regular'? <RegularSearch /> : 
         <>
           <CitySearch setCity={setCity}/>
           <FontAwesomeIcon className='search-icon' onClick={()=> navigate(`/search?city=${city}`)}  icon={faSearch}/>
         </>
       }
     </Form>
            </div>
            <div className="px-5 mx-xl-5 d-none d-xl-block">
              <div className=" gap-4 ps-4 gap-xl-5 d-flex justify-content-center align-items-center align-items-lg-start pt-4 pt-lg-0 my-4 my-lg-0">
                {navList.slice(0,4).map((obj, index) => {
                  return (
                    <Link
                      className={`hover_dashbord_link font-sm p-0  transition_03s position-relative text-gray text-decoration-none d-inline-block`}
                      key={index}
                      to={obj.url}>
                      {obj.link}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
         
         
          <div className="w-auto  d-flex justify-content-end align-items-center gap_20 gap-sm-4 gap_12">
        {currentUser ? <>
            <Link
              to="/chat"
              className=" scale-9-mobile position-relative d-inline-block notification_badge"
              data-notification={totalUnreadCount}>
              <img src={imageNotify} alt=" notification " />
            </Link>
            <CustomDropdown
              icon={<IconNotify />}
              title={notificationCount}
              itemList={notifications}
              customStyles="dropNav"
            />

            <div className="profile d-flex gap-2 align-items-center">
              <div>
                <Link to="/profile">
                  <img
                    className="profile_nav pe-1"
                    src={currentUser?.profilePic}
                    alt=" profile "
                  />
                </Link>
              </div>
              <div
                id="profile"
                className="d-none d-md-flex gap-2 d-flex align-items-center">
                <DropdownButton className="custom-dropdown" id="dropdown-basic-button" title={currentUser?.userName}>
                {navList.slice(3).map((obj, index) => {
                  return (
                    <LinkContainer
                      className={`hover_dashbord_link font-md p-0 transition_03s position-relative text-gray text-decoration-none d-inline-block mb-2`}
                      key={index}
                      to={obj.url}>
                        <Dropdown.Item>
                      {obj.link}</Dropdown.Item>
                      
                    </LinkContainer>
                
                  
                  );
                })}
                 <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>
                </DropdownButton>
              </div>
            
            </div> </> :       <Link
          to="/login"
          className={`common_btn nav_btn text-decoration-none text-center`}>
          Login 
        </Link>}
            <>
            {isNavVisible ? (
              <img
                className="d-xl-none"
                width={28}
                onClick={() => setIsNavVisible(!isNavVisible)}
                src={crossIcon}
                alt=""
              />
            ) : (
              <img
                className="d-xl-none"
                width={28}
                onClick={() => setIsNavVisible(!isNavVisible)}
                src={hamburgerIcon}
                alt=""
              />
            )}</>
          </div>
          <div
            style={{ maxHeight: isNavVisible ? "100vh" : "0px"}}
            className=" d-xl-none transition_03s overflow-hidden mobilenav position-absolute w-100 top-100 d-flex justify-content-center align-items-center flex-column bg-white drop-shaddow-dashboard-nav start-0 h-auto">
            <span className="line_top_dashbord d-block mt-4 mb-0"></span>
            <div className="searchMobile py-4 mt-1 w-100 px-4">
             {/* <div className="d-md-none mt-4 w-100">
              <Form className="search-container mx-auto d-flex w_330 bg_mobile_imp" style={{ display: 'inline', margin: "auto", width: "330px" }}>
                {currentSearch === 'regular'? <RegularSearch /> : 
                <>
                  <CitySearch setCity={setCity}/>
                  <FontAwesomeIcon className='search-icon' onClick={()=> navigate(`/search?city=${city}`)}  icon={faSearch}/>
                </>
                }
              </Form>
      </div> */}
              <div className="gap-4 d-flex flex-column justify-content-center text-center align-items-center  my-lg-0 mt-3">
                {navList.map((obj, index) => {
                  return (
                    <LinkContainer
                      className={`hover_dashbord_link font-md p-0 transition_03s position-relative text-gray text-decoration-none d-inline-block mb-2`}
                      key={index}
                      to={obj.url}>
                     <NavDropdown.Item>{obj.link}</NavDropdown.Item> 
                    </LinkContainer>
                  );
                })}
                {currentUser && <NavDropdown.Item className='p-2' onClick={handleLogout}>
                    Logout
             </NavDropdown.Item>}
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className="d-md-none mt-4 w-100">
              <Form className="search-container mx-auto d-flex w_330 bg_mobile_imp" style={{ display: 'inline', margin: "auto", width: "330px" }}>
                {currentSearch === 'regular'? <RegularSearch /> : 
                <>
                  <CitySearch setCity={setCity}/>
                  <FontAwesomeIcon className='search-icon' onClick={()=> navigate(`/search?city=${city}`)}  icon={faSearch}/>
                </>
                }
              </Form>
      </div>
    </>
  );
}



