import React from "react";

const Description = ({ description }) => {
  return (
    <div id="description">
      <p className="px-1 sm-px-0 text-gray font-sm mb-1 mb-sm-0">
        {description}
      </p>
    </div>
  );
};

export default Description;