import React, { useContext } from "react";
import { IconChat, IconEdit, IconStopWatch, IconLocation, IconBarCode, IconPriceTag, IconButtonArrow } from "./Icons";
import PrimaryButton from "./button/PrimaryButton";
import GhostButton from "./button/GhostButton";
import { HeadingVector } from "../home/Icons"; 
import { useNavigate } from "react-router-dom";
import { AppContext } from '../../AppContext';
import axios from '../../backendClient'
 

const OfferDetails = ({ setEditDetails, editDetails, isPathname, offerObject, userNow }) => {

    const navigate = useNavigate(); 
    const socket = useContext(AppContext); 


    // ** TO DO ** Must Replace with Handle Join for Event to be able to Joim Chat 
  
    //**NOTE: WE don't have socket on this page technically passed in it would have to be brought in */

    function convertToLocalTime(timeString) {
      return new Date(`1970-01-01T${timeString}Z`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    }

  const handleEventChat = async() => {
    if(!socket){
       
        if(userNow){
          try{
              const response = await axios.put(`/api/events/changeGuestStatus`,{guestId: userNow._id, eventId: offerObject._id, inviteStatus: "request"}); 
              return navigate('/chat'); 
              
          }
          catch(e){
              alert(e.response.data.message); 
          }
        }
         //use navigate to event listing protected route for sign in 
        navigate('/eventlisting');
    }else{
        //alert('Socket is active!'); //use current user to pass notification for event invite to the host
        // filter out guests who are already "declined, request"
        //** TO DO CREATE SEPARATE PAGE For Single Offer when Logged in with Socket */
        //alert('In Here!'); 
        try{
        // console.log('The data for status:');
        // console.log(userNow._id); 
        // console.log(offerObject._id); 
        // console.log(offerObject.hostUser._id);
        //alert('Requested to Join- check your chats for approval');  
        const response = await axios.put(`/api/events/changeGuestStatus`,{guestId: userNow._id, eventId: offerObject._id, inviteStatus: "request"}); 
        // socket.emit("sendNotification",{
        //     sender: userNow,
        //     receiver: offerObject.hostUser._id, 
        //     type: 4
        // }); 
      }
        catch(e){
          alert(e.response.data.message);
        }
    
        try{
    
            await axios.post('/api/messages/addOfflineNotification',{sender: userNow, offlineUserId: offerObject.hostUser._id,  notificationType: 4, notificationMessage: null });
            navigate('/chat'); 
            
        }catch(e){
            console.log('Failed to set unread notification ', e);
        }
    }
    
};
      
    

    const handleEventForward = (e) => {
     
        e.preventDefault(); // Prevents the default click behavior
        const removeProtocol = (url) => {
          return url.replace(/https?:\/\//g, '');
        };
   
        const url = `https://` + removeProtocol(`${offerObject.forwardUrl}`);
        window.open(url, '_blank', 'noreferrer'); // Opens in a new tab/window securely   
    }

    const DetailsList =  [
        {
          offerEnd: "5/12/24",
          img: <IconStopWatch />,
        },
        {
          price: "145",
          img: <IconPriceTag />,
        },
        {
          time: "3/14/2024 12:00 AM",
          img: <IconStopWatch />,
        },
        {
          location: "Seattle,WA,USA",
          img: <IconLocation />,
        },
      
        {
          onlineCode: "54354311",
          img: <IconBarCode />,
        },
      ];

  return (
    <div
      id="offer-details"
      className="d-flex justify-content-between flex-column h-100"
    >
      <div>
        {" "}
        <h2 className="mb-3 position-relative z-1">
          <span className="position-absolute offer_details_heading_img z-n1">
            <HeadingVector />
          </span>
          {offerObject?.eventTitle}
          <br /> 
          <br /> 
        </h2>
        <div className="detail-parent">
          {DetailsList.map((obj, i) => (
            <div key={i} className={`tag ${i > 0 && "mt-1"}`}>
              <div className=" d-flex align-items-center">
                {i === 0 && (
                  <>
                    <span>{obj.img}</span>
                    <p className=" mb-0 mx-2">Offer Ends:</p>
                    <span className="tag-red d-inline-block">
                      {new Date(offerObject.eventDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                    </span>
                  </>
                )}
                { offerObject.isRetailValue && i===1 ? <>{i === 1 && (
                  <>
                    <span>{obj.img}</span>
                    <p className=" mb-0 mx-2">Price:</p>
                    <span className="tag-blue d-inline-block">
                    {offerObject.offerValue ? `$${offerObject.offerValue}` : 'Chat'}
                    </span>
                  </>
                )}</> : <>{ i === 1 && (  <>
                <span>{obj.img}</span>
                <p className=" mb-0 mx-2">Learn More</p>
                <span className="tag-blue d-inline-block">
                {offerObject.offerValue ? `$${offerObject.offerValue}` : 'Click Below'}
                </span>
              </>)} </>}
                {i === 2 && (
                  <>
                    <span>{obj.img}</span>
                    <p className=" mb-0 mx-2">Offer Time:</p>
                    <span className="color-black d-inline-block">
                      {convertToLocalTime(offerObject.eventTime)}
                    </span>
                  </>
                )}
                {i === 3 && (
                  <>
                    <span>{obj.img}</span>{" "}
                    <p className=" mb-0 mx-2">Location:</p>
                    <span className="color-black d-inline-block">
                      {offerObject?.location || 'Nationwide'}
                    </span>
                  </>
                )}
                {i === 4 && (
                  <>
                    <span>{obj.img}</span>{" "}
                    <p className=" mb-0 mx-2">Online code:</p>
                    <span className="color-black d-inline-block">
                      {offerObject.onlineCode ?  `${offerObject.onlineCode}` : 'None'}
                    </span>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex flex-column flex-xl-row justify-content-between gap-md-3 gap-2 gap-xl-4 mt-md-4 mt-3">
        {offerObject.hostUser._id !== userNow?._id ? <button
          className={`common_btn d-flex align-items-center justify-content-center  btnStyle="contact_via_chat"`}
          onClick={handleEventChat}
        >
          {"Contact via chat"}{""}
          <IconChat />
        </button>: ''}
        {offerObject.hostUser._id !== userNow?._id ? (offerObject.forwardUrl ? <button
          className={`common_btn d-flex align-items-center justify-content-center  btnStyle="contact_via_chat"`}
          onClick={handleEventForward}
        >
          {offerObject.forwardCTA ? `${offerObject.forwardCTA}` : "Continue to Purchase"}{""}
          <IconButtonArrow />
        </button>:''):   <GhostButton
          text="Edit Page"
          btnStyle="edit_page"
          icon={<IconEdit />}
          isModalVisible={() => setEditDetails(!editDetails)}
        />}
      </div>
    </div>
  );
};

export default OfferDetails;
