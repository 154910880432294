import React from "react";
import chatWithUsImg from "../../components/images/webp/promote-img.webp";
import {
  ImageBlueCircleBgPromot,
  SecondryHeadingVector,
} from "./Icons";
import CustomButton from "./CustomButton";

const Promote = () => {
  return (
    <div
      id="promot"
      className="container container_modified py-4 my-3  py-md-5 my-sm-2 my-md-5">
      <div className="row align-items-center flex-row-reverse justify-content-center justify-sm-content-end justify-content-md-between my-md-1">
        <div className="col-md-7 col-lg-6 px-lg-5">
          <h2 className="text-black fw-bolder font-xxl mb-2 mb-lg-3 pb-1 ">
            <span className=" d-inline-block">
              <span className=" d-inline-block left__absolute_content position-absolute">
                <SecondryHeadingVector />
              </span>
              Promote/Find
            </span>
            <br/>
            Products and Create a Community
          </h2>
          <p className="font-md text-gray mb-3 mb-lg-3">
            Incentive allows you to find great offerings and invite others to connect with those offers. 
          </p>
          <h3 className="mb-2 mb-lg-4 font-md " style={{ lineHeight: "150%" }}>
            Sign up and and find offers.
          </h3>
          <CustomButton
            text="Find Offers!"
            btnStyle="py-3 w-100 d-none d-md-block"
          />
            <CustomButton
            text="Write my AI Script!"
            btnStyle="py-3 w-100 d-none d-md-block mt-2"
            type="link"

          />
        </div>
        <div className=" col-11  col-sm-8 col-md-5 col-lg-6 col-xl-6 pe-xl-4 position-relative mt-4 mt-md-0 z-1">
          <span className="position-absolute top-50 start-0 position-image ">
            <ImageBlueCircleBgPromot />
          </span>
          <img
            loading="lazy"
            src={chatWithUsImg}
            alt="chatWithUsImg"
            className="w-100 mb-3 mb-md-0 pb-1 pb-md-0 promot-image d-flex mx-auto"
          />
          <CustomButton text="Get Started" btnStyle=" hero_btn d-md-none" />
          <CustomButton
            text="Write my AI Script with ProductGPT!"
            btnStyle="hero_btn mt-2 d-md-none"
            type="link"
          />
        </div>
      </div>
    </div>
  );
};

export default Promote;
