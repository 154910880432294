import React, { useEffect, useState } from "react";
//import Header from "../../common/dashboard/Header";
import PostOffersCards from "./PostOffersCards";
import Promotions from "./Promotions";
//import Footer from "../../common/dashboard/Footer";
//import Sidebar from "./Sidebar";
import './offer.scss'; 

const Offers = ({sharedVariable}) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [passVariable, setPassVariable] = useState(sharedVariable); 

  const handleCategorySelect = (category) => {
    setSelectedCategory(category); 
    setPassVariable(''); 
  };

  useEffect(() => {
    setPassVariable(sharedVariable); 
    setSelectedCategory(''); 
    
  }, [sharedVariable]);

  const handleClose = () => setShowSidebar(!showSidebar);
  // Update HTML class whenever showSidebar changes
  useEffect(() => {
    if (showSidebar) {
      document.documentElement.classList.add("overflow-hidden");
    } else {
      document.documentElement.classList.remove("overflow-hidden");
    }
  }, [showSidebar]);



  return (
    <div className="bg-lightwhite offer-page">
      {/* <Header />
      <Sidebar showSidebar={showSidebar} handleClose={handleClose} /> */}
      <PostOffersCards onCategorySelect={handleCategorySelect}/>
      <Promotions category={selectedCategory} handleClose={handleClose} sharedVariable={passVariable}  />
      {/* <Footer />  */}
    </div>
  );
};

export default Offers;