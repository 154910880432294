import React from "react";
import checkMark from "../../components/images/svg/check-mark-with-blue-bg.svg";
import neuroImg from "../../components/images/webp/front-neuro.webp"; 
import { SecondryHeadingVector } from "./Icons";
import PrimaryButton from "../../components/PrimaryButton";

const Pricing = () => {

    const standardSpecification = [
      "Cost-Effective: Save more with our 3-month supply which gives you a free bottle ensuring consistent health benefits without breaking the bank.",
      "Convenience: Enjoy uninterrupted use with a longer supply, eliminating the hassle of monthly reordering.",
      "Free Shipping: Get your Neuro Mushroom Pro delivered to your doorstep at no extra cost, enhancing the value of your purchase."
      ];
    
      const enterpriseSpecification = [
        "Maximize Your Purchase: Our 5-month supply offers you 2 free bonus bottles.",
        "Enhanced Health Benefits: Extended use of Neuro Mushroom Pro supports sustained cognitive function, immune system strength, and overall wellness.",
        "Enjoy free and fast shipping, ensuring you receive your Neuro Mushroom Pro promptly.",
        "Risk-Free Purchase: We are so confident in Neuro Mushroom Pro that we offer a 100% money-back guarantee, even if you use up the entire product. If you're not satisfied, we'll refund your money—no questions asked."
      ];
  return (
    <div className="bg_grident_pricing py-5">
      <div className=" container container_modified py-md-4 py-1 py-xl-5">
        <div className="d-flex justify-content-center">
          <h2
            style={{ lineHeight: "110%" }}
            className=" mx-auto d-inline-block position-relative fw-black text-white font-xxl mb-0 pt-2 z-1">
            <span className=" d-inline-block position-absolute center__absolute_content z-n1">
              <SecondryHeadingVector />
            </span>
            Pricing
          </h2>
        </div>
        <div id="product-pricing" className="row pt-5 justify-content-center px-2 px-sm-0">
          <div className="col-md-6 col-lg-5">
            <div className="p-4 bg-white w-100 pricing_cards position-relative h-100">
              {/* <span className="tag position-absolute d-inline-block">
                Most Popular
              </span> */}
              <div className="p-3 d-flex justify-content-center">
                <img src={neuroImg} alt="std" /> 
              </div>
              <h3 className="text-center mb-0">Buy 2 Get 1 Free (3 MONTH SUPPLY)</h3>
              <h4 className=" text-center fw-medium text-black mb-0">
                <span>$64.00</span> FREE SHIPPING
              </h4>
              <div className="specification_box mt-3">
                {standardSpecification.map((obj, i) => (
                  <div key={i} className="d-flex gap-2 align-items-center pt-3">
                    <img
                      className=" pe-1 img_check_mark"
                      src={checkMark}
                      alt="checkmark"
                    />

                    <p
                      style={{ lineHeight: "150%" }}
                      className=" text-black font-sm mb-0">
                      {obj}
                    </p>
                  </div>
                ))}
              </div>
              <PrimaryButton
                      text="Buy Now"
                      btnStyle="buy-now btn__  d-block mx-auto mt-4 mt-md-5"
                      target="_blank"
                      url="https://shop.incentivemeet.com/cart/44367371796532:3?storefront=true"
                    />
            </div>
          </div>
          <div className="col-md-6 col-lg-5 mt-4 mt-md-0">
            <div className="p-4 bg-white w-100 pricing_cards position-relative h-100">
            <span className="tag position-absolute d-inline-block">
                Best Deal For Your Mental Improvement 
              </span> 
              <div className="p-3 d-flex justify-content-center">
                <img src={neuroImg} alt="" /> 
              </div>
              <h3 className="text-center mb-0">Buy 3 Get 2 Free (5 MONTH SUPPLY)</h3>
              <h4 className=" text-center fw-medium text-black mb-0">
                <span>$96.00</span> FREE SHIPPING
              </h4>
              <div className="specification_box mt-3">
                {enterpriseSpecification.map((obj, i) => (
                  <div key={i} className="d-flex gap-2 align-items-center pt-3">
                    <img
                      className=" pe-1 img_check_mark"
                       src={checkMark}
                      alt="checkmark"
                    />

                    <p
                      style={{ lineHeight: "150%" }}
                      className=" text-black font-sm mb-0">
                      {obj}
                    </p>
                  </div>
                ))}
              </div>
              <PrimaryButton
                      text="Buy Now"
                      btnStyle="buy-now btn__  d-block mx-auto mt-4 mt-md-5"
                      target="_blank"
                      url="https://shop.incentivemeet.com/cart/44367371796532:5?storefront=true"
                    />
            </div>
          </div>
          {/* <div className="col-sm-10 px-xl-4">
            <p className="font-md text-white px-lg-5 mx-xl-3 text-center mt-4 mt-md-5">
              ¹No purchase necessary. Contests are open to all eligible
              attendees who are active participants of the incentive community.
              All contests are open to eligible participants who "Request to
              Join" a contest based on eligibility criteria.
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
