import { HeadingVector } from "./Icons";
import lineImageDesktop from "../../components/images/svg/works-line-img-desktop.svg"; 
import lineImageMobile from "../../components/images/svg/works-line-mobile.svg"; 

const HowItWorks = () => {
    return (
      <>
        <div id="how-it-works" className="works-parent mt-md-5">
          <div className="container container_modified">
            <h4 className="font-xxl fw-bolder text-center text-white position-relative z-1 mb-4 mb-sm-5 pb-1 pb-lg-0">
              Easy Process
              <span className="works_heading position-absolute start-50 mb-0 z-n1 translate-middle-x">
                <HeadingVector />
              </span>
            </h4>
            <div className="row justify-content-end justify-content-sm-center justify-content-lg-between position-relative">
              <div className="position-absolute line_img_desktop d-none d-lg-block">
                <img src={lineImageDesktop} alt="works-line-img-desktop" />
              </div>
              <div className="position-absolute line_img_mobile d-lg-none line_mobile_img">
                <img
                  src={lineImageMobile}
                  alt="works-line-img-desktop"
                  className="line_mobile_img"
                />
              </div>
              <div className="col-11 col-sm-10 col-md-7 col-lg-5 works_card_one position-relative">
                <div className="works_card_bg bg-white d-flex flex-wrap justify-content-between align-items-center">
                  <div className="works_card_parent">
                    <h5 className="font-xl fw-bold works_text_color mb-0 position-relative">
                      Step 1
                    <div className="position-absolute works_step_text_circle"></div>
                    </h5>
                  </div>
                  <p className="font-lg fw-bold text-black mb-0 works_description_width ps-2 ps-sm-0">
                    Order our premium grade FDA Approved, GMP, US Manufactured Supplement 
                  </p>
                </div>
              </div>
              <div className="col-11 col-sm-10 col-md-7 col-lg-5 works_card_two position-relative d-lg-flex justify-content-end">
                <div className="works_card_bg bg-white d-flex flex-wrap justify-content-between align-items-center mt-3 mt-lg-0">
                  <div className="works_card_parent">
                    <h5 className="font-xl fw-bold works_text_color mb-0 position-relative">
                      Step 2
                      <div className="position-absolute works_step_text_circle"></div>
                    </h5>
                  </div>
                  <p className="font-lg fw-bold text-black mb-0 works_description_width ps-2 ps-sm-0">
                    After receiving your package ensure you're fully satisfied with the product
                  </p>
                </div>
              </div>
              <div className="col-11 col-sm-10 col-md-7 col-lg-5 works_card_three position-relative">
                <div className="works_card_bg bg-white d-flex flex-wrap justify-content-between align-items-center mt-3 mt-lg-0">
                  <div className="works_card_parent">
                    <h5 className="font-xl fw-bold works_text_color mb-0 position-relative">
                      Step 3
                      <div className="position-absolute works_step_text_circle"></div>
                    </h5>
                  </div>
                  <p className="font-lg fw-bold text-black mb-0 works_description_width ps-2 ps-sm-0">
                    Take the product twice daily with a nutritious diet and program 
                  </p>
                </div>
              </div>
              <div className="col-11 col-sm-10 col-md-7 col-lg-5 works_card_four position-relative">
                <div className="works_card_bg bg-white d-flex flex-wrap justify-content-between align-items-center mt-3 mt-lg-0">
                  <div className="works_card_parent">
                    <h5 className="font-xl fw-bold works_text_color mb-0 position-relative">
                      Step 4
                      <div className="position-absolute works_step_text_circle"></div>
                    </h5>
                  </div>
                  <p className="font-lg fw-bold text-black mb-0 works_description_width ps-2 ps-sm-0">
                    You'll start to see increased focus, clarity, and connect facts in your brain. Including increased memory within 30 days. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default HowItWorks;
  