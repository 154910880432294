import React from "react";
import { CameraIcon, IconCrossModals as IconCrossModals } from "./Icons";
import relatedCardImage from "../../components/images/dashboard/webp/info_business.webp"
import Field from "../../components/Field"; 
import PrimaryButton from "../../components/PrimaryButton"; 

const PromotionEdit = ({ setEditDetails, editDetails, userNow }) => {
  return (
    <>
      <div
        className={`bg-black opacity-75 position-fixed z-3 top-0 start-0 w-100 vh-100 ${
          editDetails ? " bg-danger" : "d-none bg-success"
        }`}
        onClick={() => setEditDetails(!editDetails)}
      ></div>
      <div
        id="edit-details-promotion"
        className={`position-fixed top-50 start-50 translate-middle overflow-scroll w-100 z-3 ${
          editDetails ? "" : "d-none"
        }`}
      >
        <div className="d-flex align-items-center justify-content-between mb-sm-4 mb-3">
          <p className="mb-0 edit_heading text-black">Edit Promotion</p>
          <span
            className="edit_cross cursor_pointer"
            onClick={() => setEditDetails(!editDetails)}
          >
            <IconCrossModals />
          </span>
        </div>
        <p className="text-black font-md mb-2 pb-sm-1 fw-bolder">Image promo</p>
        <img
          src={relatedCardImage}
          alt="img"
          className="w-100 object-fit-cover promotion_edit_img"
        />
        <label
          htmlFor="upload-img"
          className="upload_file d-flex justify-content-center align-items-center gap-2 font-sm fw-medium cursor_pointer transition_03s"
        >
          <CameraIcon /> Upload
        </label>
        <input type="file" id="upload-img" className=" d-none" />
        <h6 className="font-sm text-black fw-bolder mb-2 mt-sm-4 mt-3">
          Title
        </h6>
        <div className="title_description mb-3">
          <p className="font-sm fw-normal text-black mb-0">
            Bookkeeping / Bookkeper / Accountant/ QuickBooks - Reasonable Rates
            (Remote Access)
          </p>
        </div>
        <h6 className="font-sm text-black fw-bolder mb-2 pt-sm-1">
          Description
        </h6>
        <div className="title_description mb-sm-4 mb-3">
          <p className="mb-0 font-sm fw-normal description_edit">
            Volutpat leo placerat donec diam sodales diam. Amet augue
            ullamcorper dui eu sem nec molestie. Aliquet enim libero arcu
            volutpat amet a blandit tincidunt. Non adipiscing risus id arcu nunc
            ullamcorper. Tristique risus vel natoque tellus pulvinar. Aliquam
            sit elit sed lectus facilisis non mauris egestas ut. Amet
            scelerisque tellus id pellentesque eu ac.
          </p>
          <p className="mb-0 font-sm fw-normal description_edit mt-4">
            Urna consectetur consequat libero aliquam sit. Libero lacus enim
            pellentesque pharetra id aenean ac. Pellentesque dui et in lobortis
            varius gravida lacinia lacus volutpat. Duis mauris amet odio enim
            orci nisl libero in et. Fringilla nunc odio.
          </p>
        </div>
        <form>
          <div className="d-flex align-items-center justify-content-between">
            <label htmlFor="" className="font-sm text-black fw-bolder">
              Offer End Date
            </label>
            <Field
              placeholder="5/12/2024"
              type="date"
              labelStyle="edit_inputs w-100"
              customStyles="text-black"
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mt-sm-4 mt-3">
            <label htmlFor="" className="font-sm text-black fw-bolder">
              % off
            </label>
            <Field
              placeholder=""
              type="text"
              labelStyle="edit_inputs w-100"
              customStyles="text-black"
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mt-sm-4 mt-3">
            <label htmlFor="" className="font-sm text-black fw-bolder">
              Price
            </label>
            <Field
              placeholder="$145"
              type="text"
              labelStyle="edit_inputs w-100"
              customStyles="text-black"
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mt-sm-4 mt-3">
            <label htmlFor="" className="font-sm text-black fw-bolder">
              Location
            </label>
            <Field
              placeholder="Seattle, WA, USA"
              type="text"
              labelStyle="edit_inputs w-100"
              customStyles="text-black"
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mt-sm-4 mt-3">
            <label htmlFor="" className="font-sm text-black fw-bolder">
              Online code
            </label>
            <Field
              placeholder="#54354311"
              type="text"
              labelStyle="edit_inputs w-100"
              customStyles="text-black"
            />
          </div>
        </form>
        <div className="d-flex flex-column flex-sm-row align-items-center gap-sm-2 gap-3 mt-sm-4 mt-3">
          <PrimaryButton
            onclick={() => setEditDetails(!editDetails)}
            text="Save"
            btnStyle="px-2 w-100"
          />
          <PrimaryButton
            text="Cancel Promotion"
            btnStyle="px-2 w-100 cancel_btn ms-sm-1"
            onclick={() => setEditDetails(!editDetails)}
          />
        </div>
      </div>
    </>
  );
};

export default PromotionEdit;
