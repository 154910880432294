import React, {useState, useEffect, useContext} from 'react';
import { Col, Container, Row, Card, Button, Modal, Dropdown, Image, Alert} from 'react-bootstrap';
import { categories } from '../categories/categoriesdata';
import CategoryBox
 from '../categories/CategoryBox';
const Speedquestion = () => {


   
    
   
    const [selectedCategories, setSelectedCategories] = useState([]);

    const handleCategory = (selectedCategory) => {
      if (selectedCategories.includes(selectedCategory)) {
        setSelectedCategories(selectedCategories.filter(cat => cat !== selectedCategory));
        alert(selectedCategories); 
    } else {
        setSelectedCategories([...selectedCategories, selectedCategory]);
        alert(selectedCategories); 
      }

      
    };
    const [title, setTitle] = useState('What are some of your passions?'); 
    const [subtitle, setSubtitle] = useState('Select activites below'); 
    const center = true; 



   
  return (
    <Container className='maincontainer py-5'>
    <Row className={center ? 'justify-content-center' : 'justify-content-start'}>
      <Col>
        <div style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
          {title}
        </div>
        <div style={{ fontWeight: 'lighter', color: '#6c757d', marginTop: '1rem' }}>
          {subtitle}
        </div>
      </Col>
    </Row>
    <Row className="mb-3">
        {categories.map((item) => (
          <Col key={item.label} xs={12} md={4} className="mb-3 p-2">
        <CategoryBox 
                        key={item.label}
                        label={item.label}
                        icon={item.icon}
                        selected={selectedCategories === item.label}
                        onCategoryChange={handleCategory}
                    />
       
          </Col>
           
        ))}
   
        </Row>
    </Container>
  )
}

export default Speedquestion