import React from "react";
//import Header from './../../common/dashboard/Header';
import Cards from './Cards';
import User from "./User";
//import Footer from './../../common/dashboard/Footer';
import '../../components/sample.scss'
import './dashboard.scss'; 

const Dashboard = () => {
  return (
    <div style={{ background: "#FAFAFA" }}>
      {/* <Header /> */}
      <Cards />
      <User />
      {/* <Footer /> */}
    </div>
  );
};

export default Dashboard;