import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, ButtonGroup, Badge, Container, Row, Col, Form, InputGroup, Modal, FormControl } from 'react-bootstrap';
import axios from '../../backendClient'; 
import { useSelector } from 'react-redux';

const Billing = () => {
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [endDate, setEndDate] = useState(''); 
    const {currentUser} = useSelector(state => state.user);
    const [cancel, setCancel] = useState(false); 

    // get and set currentSubscription, currentInvites, maxInvites from an endpoint upon page load 
      //useEffect to check wha thee image is logged as 
      useEffect( ()=>{
        const fetchSubscription = async() => {
            const res = await axios('/api/payments/getSubscription');
            setEndDate(res.data.endDate);
            setCancel(res.data.isCanceled);  
        }
        fetchSubscription();
        
      },[]);

    const cancelSubscription = async () => {
      //get current users subscription and downgrade it to free and update the user's max invites to 10 via dispatch
      try{

        const res = await axios.delete("/api/payments/cancelSubscription");
        
        // axios already provides data in json() format ** 
        const data = res.data;
        setEndDate(data);
        setCancel(true); 
      }catch(e){
        console.log('Error: ', e); 
      } 

    }
  return (
    <>
    <Container className="my-4">
        <Row>
          <Col md={6} className="mx-auto">
      {/* <Card className="mb-3">
        <Card.Body>
          <Card.Title>Payment Method:</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">PayPal</Card.Subtitle>
          <Card.Text>f****@bu.edu</Card.Text>
          <ButtonGroup aria-label="Payment actions">
            <Button variant="outline-primary">Remove</Button>
            <Button variant="primary" onClick={handleShow}>Change</Button>
          </ButtonGroup>
        </Card.Body>
      </Card> */}
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update payment method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Check
                type="radio"
                label="Credit or debit card"
                name="paymentMethod"
                id="creditCard"
                defaultChecked
              />
            </Form.Group>

            <InputGroup className="mb-3">
              <InputGroup.Text>Card number</InputGroup.Text>
              <FormControl placeholder="Card number" />
            </InputGroup>

            <InputGroup className="mb-3">
              <FormControl placeholder="MM / YY" />
              <FormControl placeholder="CVC" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text>Country</InputGroup.Text>
              <Form.Select>
                <option>United States</option>
                {/* Add other countries here */}
              </Form.Select>
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text>Zip Code</InputGroup.Text>
              <FormControl placeholder="Zip Code" />
            </InputGroup>

            <Form.Group>
              <Form.Check
                type="radio"
                label="PayPal"
                name="paymentMethod"
                id="paypal"
              />
            </Form.Group>

            <Form.Text className="text-muted">
              This payment method will be available for all team members with purchasing rights to use.
            </Form.Text>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Your Offer Invites For the Month</Card.Title>
          <Card.Text>You have 10/10 Invites</Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Title>Your Offers For the Month</Card.Title>
          <Card.Text>You have 1 Promo for the Calendar Month</Card.Text>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Your Current Subscription : {endDate? `Premium` :`Free`}</Card.Title>
          <Card.Subtitle className="mb-2">
            {/* <Badge bg="warning" text="dark">TRIAL</Badge> */}
          </Card.Subtitle>
          <Card.Text>
           {endDate && !cancel && `Your current plan renews on: `+ endDate }
           {endDate && cancel && `Your current plan ends on ` + endDate}
          </Card.Text>
         {(!endDate || cancel) &&
          <ButtonGroup aria-label="Subscription actions">
            <Link to="/checkout"><Button variant="primary">{cancel? `Resume Premium Plan` : `Switch to Premium Plan`}</Button></Link>
            <Button variant="secondary">...</Button>
          </ButtonGroup>}
        </Card.Body>
      </Card>
      <Card className="mt-2">
            {!cancel && endDate && <>
            <Card.Body>
            <Card.Title>One-Click Cancel</Card.Title> <Card.Text>You will lose access to posting offers and tracking your offer data. You may still request to redeem offers</Card.Text> <Card.Text>Your subscription will renew on: {endDate}</Card.Text>
            </Card.Body>
            </>}
            {cancel && endDate && <Card.Body><Card.Text>Your subscription will end on: {endDate}</Card.Text></Card.Body>}
            {!cancel && endDate && <><Card.Body><Button variant="danger" onClick={cancelSubscription}>Cancel Subscription</Button></Card.Body></>}  
      </Card>
      </Col>
      </Row>
      </Container>
    </>
  );
};

export default Billing;
