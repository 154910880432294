
import neuroLabel from "../../components/images/png/Neuro-mushroom.png"; 

const ExplainImg = () => {
    return (
      <>
      <div className="container container_modified">
            <div className="row flex-column-reverse flex-lg-row align-items-center">
                    <div className="col-lg-12 text-end position-relative">
                        <div className="position-relative girl_image mx-auto">
                        <img
                            src={neuroLabel}
                            alt="girl"
                            className="position-relative z-1 w-100"
                        />
                        <div className="linear_clr"></div>
                        </div>
                    </div>
            </div>
</div>

      </>
    );
  }
  
  export default ExplainImg