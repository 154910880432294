import React, {useState} from "react";
import CustomDropdown from "../../components/CustomDropdown";


const Cards = () => {
 const offerDropItems = ['drop1', 'drop2', 'drop3']; 
 const [options, setOptions] = useState(['Select Your Offers Here']);

 //get endPoint for all events 

  return (
    <div
      id="dashbord_cards"
      style={{ maxWidth: "1360px", paddingLeft: "20px", paddingRight: "20px" }}
      className=" mx-auto py-3  py-md-4 mt-2 mt-md-5 pt-5">
      <CustomDropdown
        customStyles="mt-3 mt-md-4"
        title="Select Offers"
        itemList={options}
      />
      <div className="row pt-3 pt-md-4">
        <div className="col-4 ">
          <div className="card__dashbord ">
            <p className=" mb-0">Clicks</p>
            <h2 className="mb-0">0</h2>
          </div>
        </div>
        <div className="col-4">
          <div className="card__dashbord">
            <p className=" mb-0">Offer Requests</p>
            <h2 className="mb-0">0</h2>
          </div>
        </div>
        <div className="col-4">
          <div className="card__dashbord">
            <p className=" mb-0">Total Revenue</p>
            <h2 className="mb-0 color_green">$0</h2>
          </div>
        </div>
        <div className="col-6 mt_custom mt-sm-3 mt-md-4">
          <div className="card__dashbord">
            <div className=" d-flex  justify-content-between mb-0 position-relative w-100 pt-2 pt-sm-0">
              <p className=" mb-0">Offer Redemptions</p>
              <div className="btn_select  gap-2 d-none d-sm-flex align-items-center scale-down-desktop">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                <p className=" mb-0 ps-1">Confirmed</p>
              </div>
            </div>

            <div className="d-flex justify-content-between w-100 align-items-center">
              {" "}
              <h2 className="mb-0">0</h2>{" "}
              <div className="btn_select  gap-2 d-flex d-sm-none align-items-center position-relative scale-down-desktop">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                <p className=" mb-0 ps-1">Confirmed</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 mt_custom mt-sm-3 mt-md-4">
          <div className="card__dashbord">
            <div className=" d-flex justify-content-between mb-0">
              <p className=" mb-0">% Change Week over Week</p>
            </div>
            <h2 className="mb-0">0%</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
