import React from "react";
import { IconComma } from "./Icons";
import OrderDetailsUserImg from "../../components/images/png/user.png";
import { useNavigate } from "react-router-dom";

const Reviews = ({passedReviews}) => {

   const navigate = useNavigate(); 

    const ReviewCardsList = [
        {
          name: "Jamie",
          image: OrderDetailsUserImg,
          description:
            "Feugiat pretium mi a tellus est. Ac vulputate id fames nulla semper facilisis ultrices. Faucibus est morbi cras dui nunc convallis. Id ac sit cursus at convallis maecenas turpis. In sit dapibus tincidunt in felis felis integer.",
        },
        {
          name: "fill",
          image: OrderDetailsUserImg,
          description:
            "Volutpat leo placerat donec diam sodales diam. Amet augue ullamcorper dui eu sem nec molestie. Aliquet enim libero arcu volutpat amet a blandit tincidunt. ",
        },
        {
          name: "jame",
          image: OrderDetailsUserImg,
          description:
            "Volutpat leo placerat donec diam sodales diam. Amet augue ullamcorper dui eu sem nec molestie. Aliquet enim libero arcu volutpat amet a blandit tincidunt. ",
        },
      ];
      if (!passedReviews || passedReviews.length === 0) {
        return <div className={`reviews-cards 1 "mt-2"}`}>
        {" "}
        <div className="d-flex justify-content-between align-items-center mb-2 mb-md-3 ">
          {" "}
          <div className=" d-flex align-items-center gap-3">{" "}
          </div>
          <IconComma />
        </div>
        <p className=" mb-0">No Reviews Yet</p>
      </div>;
      }
    
  return (
    <div id="reviews">
      {passedReviews?.map((obj, i) => (
        <div key={i} className={`reviews-cards ${i > 0 && "mt-2"}`}>
          {" "}
          <div className="d-flex justify-content-between align-items-center mb-2 mb-md-3 ">
            {" "}
            <div className=" d-flex align-items-center gap-3" onClick={() => navigate(`/profile/${obj.reviewUser._id}`)}>
              <img src={obj.reviewUser.profilePic} alt="user profile"/>{" "}
              <p className=" mb-0 d-inline-block">{obj.reviewUser.userName}</p>
            </div>
            <IconComma />
          </div>
          <p className=" mb-0">{obj.reviewTexts}</p>
        </div>
      ))}
    </div>
  );
};

export default Reviews;