import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    byRoom: {},
    totalUnreadCount: 0,
    notifications: {}, 
    notificationsUnclearedCount: 0 
};

const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setUnreadCounts: (state, action) => {
            const unreadCounts = action.payload; // This should be an object with room names as keys and unread counts as values
            let totalUnread = 0;
            for (const [room, count] of Object.entries(unreadCounts)) {
                if (!state.byRoom[room]) {
                    state.byRoom[room] = { messages: [], unreadCount: 0 };
                }
                state.byRoom[room].unreadCount = Number(count);
                totalUnread = Number(totalUnread) + Number(count);
            }

            state.totalUnreadCount = totalUnread;
        },
        incrementUnreadCount: (state, action) => {
            const roomId = action.payload;
            if (!state.byRoom[roomId]) {
                state.byRoom[roomId] = { messages: [], unreadCount: 0 };
            }
            state.byRoom[roomId].unreadCount = Number(state.byRoom[roomId].unreadCount) + 1;
            state.totalUnreadCount = Number(state.totalUnreadCount) + 1;
        },
        clearUnreadCountForRoom: (state, action) => {
            const roomId = action.payload;
            if (state.byRoom[roomId]) {
                state.totalUnreadCount -= state.byRoom[roomId].unreadCount; // Subtract the unread count of the room from the total
                state.byRoom[roomId].unreadCount = 0; // Set the unread count for the room to 0
            }
        }, 
        setNotifications: (state, action) => {
            // state.notifications = action.payload; 
            // state.notificationsUnclearedCount = Object.keys(action.payload).length;
            const notifications = action.payload;
            //console.log(notifications);
            notifications.forEach((notification) => {
              const { sender, type, message = null } = notification;
              const key = `${sender._id}-${type}`;
              if (!state.notifications[key]) {
                state.notifications[key] = {
                  sender,
                  type: Number(type),
                  ...(message && { message }),
                };
                state.notificationsUnclearedCount += 1;
              }
            }); 

        }, 
        addNotifications: (state, action) => {
            const {sender, type, optionalObject = null} = action.payload; 
            const key = `${sender._id}-${type}`; //consists of notificationUser + type (i.e. the notification user is the user involved in the type) 
            if(!state.notifications[key]){
                state.notifications[key] = {
                    sender, type, ...(optionalObject && {optionalObject})};
                state.notificationsUnclearedCount += 1; 
            }
        },
        clearAllNotifications: (state) => {
            state.notifications = {}; 
            state.notificationsUnclearedCount = 0; 
        },
        logoutNotifications:() =>{
            return initialState; 
        }
    }
});

export const { setUnreadCounts, incrementUnreadCount, clearUnreadCountForRoom, setNotifications, addNotifications, clearAllNotifications, logoutNotifications } = messagesSlice.actions;
export default messagesSlice.reducer;
