import React, {useState} from 'react'
import "./home.scss";
import './hero.scss'; 
import {Row,Col, Button, Container} from 'react-bootstrap';
import PrivacyModal from '../../components/Privacymodal';
import phone from "../../assets/phone.png"; 
import chatScreen from "../../assets/chatScreenNew.png"; 
import infographic from "../../assets/infographic.png"; 
import businessPage from "../../assets/businessPage.png"; 
import invitesScreen from "../../assets/invitesScreen.png"; 
import Homecards from './Homecards';
import Hero from './Hero';
import IncentivePromotions from './IncentivePromotions';
import Promote from './Promote';
import Pricing from './Pricing';





const Home = () => {

  const userHasAccepted = true;
  const [isModalOpen, setIsModalOpen] = useState(!userHasAccepted); // Open the modal by default

  const handleCloseModal = () => {
    setIsModalOpen(false);
    //localStorage.setItem('userHasAccepted', 'true');
  };

  

  return (
    <Container>
    {/* <PrivacyModal isOpen={isModalOpen} onClose={handleCloseModal}/> */}
    <Row>
      <Hero />
    </Row>

    <Row className="rowSeven align-items-center mb-1">
      <h2 className="mt-3 d-flex flex-direction-column align-items-center justify-content-center">Categories </h2>
      <Homecards />
    </Row>
    <Row className="rowSeven align-items-center mb-1">
        <Col md={6} className="mb-3 mb-md-0">
          {/* <div className="video-responsive">
            <iframe
              height="100%"
              width="100%"
              src="https://www.youtube.com/embed/hDdxyLmfG-c?autoplay=1&controls=1&mute=1"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen>
            </iframe>
          </div> */}
        </Col>
        <Col md={6}>
          {/* <h2 className='mb-3'>Discover Incentive Meet</h2>
          <p>Discover promotions engage with and get feedback and tracking in one place</p>
          Add your infographic or additional content here */}
        </Col>
      </Row>
    <Row>
          {/* <img src={infographic} alt="" /> */}
    </Row>
    <Row className="rowSix project">
        <Col xs={12} md={6} className="phone">
          <img src={phone} alt="" />
          <div className="phoneScreen">
            <img src={businessPage} alt="" className="phoneApp" />
          </div>
        </Col>
        <Col xs={12} md={6} className="projectDetail">
          <h1 className="projectTitle">Automatic Landing Page For Each Offer</h1>
          <p className="projectDesc">
            All active offers get a full description page easily shareable with a link or invite code with the ability to forward to a checkout page¹. 
            <br />
          </p>
          <Button variant="primary" className="projectButton">Learn More</Button>
        </Col>
    </Row>
    <hr />
    <Row className="rowSix project">
    <Col xs={12} md={6} className="projectDetail">
           <h1 className="projectTitle">Chat With Others</h1>
          <p className="projectDesc">
            Chat easily with people interested and the business all in one place. 
            All chats are group chats so hosts can build excitement and guide customers to an appointment or purchase or hold an event.  
            <br />
          </p>
          <Button variant="outline-secondary" className="projectButton">Learn More</Button> 
        </Col>
        <Col xs={12} md={6} className="phone">
           <img src={phone} alt="" />
          <div className="phoneScreen">
            <img src={chatScreen} alt="" className="phoneApp" />
          </div> 
        </Col>
    </Row>
    <Row className="rowSix project">
    <Col xs={12} md={6} className="projectDetail">
          <h1 className="projectTitle">Invite List for Private Offers</h1>
          <p className="projectDesc">
            Control who you invite for certain offers, events, jobs for personal events. 
            <br />
          </p>
          <Button variant="primary" className="projectButton">Learn More</Button>
        </Col>
        <Col xs={12} md={6} className="phone">
          <img src={phone} alt="" />
          <div className="phoneScreen">
            <img src={invitesScreen} alt="" className="phoneApp" />
          </div>
        </Col>
    </Row>
    <Row className='rowThree'>
      <Col>
        <IncentivePromotions />
      </Col>
    </Row>
    <Row className='rowTwo'>
      <Col>
      <Promote />
      </Col>
    </Row> 
    {/* <Row> 
      <Col>
      <Pricing />
      </Col>
    </Row> */}
  </Container>
  )
}

export default Home