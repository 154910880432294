import React from 'react';
import { Card } from 'react-bootstrap';
import './terms.scss'; 

const TermsOfUse = () => {
  return (
    <Card>
      <Card.Header className="card-header">Terms of Use</Card.Header>
      <Card.Body>
        <Card.Text className="card-text">
          <p> These terms are a binding contract between you (user) and Red Shield Technology Inc. ('incentivemeet.com', 'we', 'us', 'our'). </p>
          <h3>Binding Arbitration </h3>
          <p>
            We are here to help and resolve any issues. By using our site you and opting in you are giving explicit consent on the date of account creation that you will 
            fully comply with the terms of use of our platform accessed through various means but ultimately located at the domain incentivemeet.com
          </p>
          <p>
            You are agreeing to all of these terms if you plan on using the web platform. If you do not agree to all of the following you may not access our services or the platform. 
            You are agreeing that disputes between you and us will be resolved by binding individual arbitration and you waive your right to participate in a class action lawsuit. 
          </p>

          <h3>Changes to Terms</h3>
          <p>
            We reserve the right to change our services and Terms. We will inform current users if these terms change via an updated notice at https://www.incentivemeet.com/termsofuse and/or by email or by other means. 
          </p>

          <h3>Terms and Conditions</h3>
          <p>
            You agree you are over 18 years old. You will only use the platform for your own use or a business you own or are the rightful lawful representative of 
            and not on behalf of any other business without express consent of that business. 
            Businesses are responsible for honoring the terms of their promotional offers and abiding by all applicable laws. We are not 
            responsible for damage caused by a business in not honoring the terms of their promotional offer. If you believe a business 
            is not honoring a promotion based on valid reasons you may notify us and we will investigate the nature of the promotion. However, 
            we are not responsible for the final decision of the business to honor or not honor obligations. All contractual and business agreements
            are between you and the business/host and not between you and us. 
          </p>

          <p>
            You warrant and agree that you will not violate the intellectual property rights of anyone when posting on the platform, violate any laws, 
            do anything dangerous, threatening, harrassing, defamatory, obscene, or objectionable. You will report any such behavior. 
          </p>

          <p>
            You warrant and agree that you will not engage in fraud or security risks. You warrant and agree you will not crawl our site for information
            via manual or automated means. You warrant and agree to not copy or store subntantial portions of our platform. 
          </p>

          <p>
            Violating the above are terms for account deletion and banning you from the platform. 
          </p>

          <h3>Paid Services</h3>
          <p>
            We use a payment processor for our paid services. The processing of such payments are subject to the terms, conditions, and privacy policy of the payment processor. 
            Some of the paid services may have an initial charge followed by recurring charges as agreed by you. We may submit such charges monthly wihtout further 
            authorization by you until you cancel your service in "Settings". You are responsible for all charges up until the terms of the billing cycle. 
            You also agree to provide current complete and accurate information for proper billing. You must notify us if your payment method is cancelled or you 
            are aware of a security breach of your account or payment method.
          </p>
          <p>
            Any free tial or other promotion for our paid services must be used within the specified time You must cancel your subscription to avoid being charged for 
            the paid service. You can contact support at incentivemeet-account@rshieldtech.com if you believe you cancelled your service but were charged inadvertently. 
          </p>

          <h3>Terminating Your Account</h3>
          <p>
            You are authorized to delete your account for any reason in the "Settings" tab. We also reserve the right to terminate your account for violations of these terms
            or other breaches we determine that are not explicitly listed here but that violate the spirit of the terms of use of our platform. 
          </p>


 
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default TermsOfUse;
