import React from "react";
import { Link } from "react-router-dom";

const CustomButton = ({ text, btnStyle, icon, type }) => {
  return (
    <>
      {type === "link" ? (
        <Link
          to="/dashboard"
          className={`common_btn ${btnStyle} text-decoration-none text-center`}>
          {text} {icon ? icon : ""}
        </Link>
      ) : (
        <button className={`common_btn ${btnStyle}`}>
          {text} {icon ? icon : ""}
        </button>
      )}
    </>
  );
};

export default CustomButton;
