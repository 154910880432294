import { useSelector, useDispatch } from "react-redux";
import {useState, useEffect, useRef} from 'react'
import { Navigation } from "./components/Navigation";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Chat from "./pages/chat/Chat";
import Footer from "./components/Footer";
import Faq from "./pages/faq/Faq";
import Search from "./pages/search/Search";
import Profile from "./pages/profilePage/Profile";
import {BrowserRouter, Routes, Route, Navigate, Outlet, useLocation} from "react-router-dom";
import ChatMessage from "./pages/chat/ChatMessage";
import Newevent from "./pages/event/Newevent";
import './App.scss';
import RegisterCode from "./pages/register/RegisterCode";
import Invites from "./pages/invites/Invites";
import Invitecode from "./pages/invitecode/Invitecode";
//import axios from "axios";
import axios from "./backendClient.js"; 
import PrivacyPolicy from "./pages/privacy/Privacy";
import { io } from "socket.io-client";
import { AppContext } from "./AppContext";
import { setNotifications, setUnreadCounts } from "./redux/messagesSlice";
import Checkout from "./pages/checkout/Checkout";
import Settings from "./pages/settings/Settings";
import Billing from "./pages/billing/Billing";
import Listing from "./pages/event/Listing";
import Upgrade from "./pages/test/Upgrade";
import SuccessPage from "./pages/checkout/SuccessPage";
import Speedquestion from "./pages/event/Speedquestion";
import TermsOfUse from "./pages/terms/Terms";
import Dashboard from "./pages/dashboard/Dashboard";
import Offers from "./pages/event/Offers";
import Nprofile from "./pages/profilePage/Nprofile";
import SingleOffer from "./pages/singleoffer/SingleOffer";
import { loginFailure } from './redux/userSlice';
import BusinessLanding from "./pages/business/BusinessLanding";
import SocialMedia from "./pages/social/SocialMedia";
import Contact from "./pages/contact/Contact";
import ProductGPT from "./pages/productgpt/ProductGPT";


const ProtectedLayout = ({setIsSocketReady, onVariableChange}) => {
  const { currentUser } = useSelector(state => state.user);
  const [isTokenValid, setIsTokenValid] = useState(null);
  const [socket, setSocket] = useState(null);  
  const socketRef = useRef(null);  
  const dispatch = useDispatch();
  const COOKIE_NAME = "AWSALB"; 
  
  

  useEffect(() => {
    const validateToken = async () => {
      try {
        const result = await axios(`/api/auth/validateToken`);
        setIsTokenValid(Boolean(result.data));
      } catch (error) {
        setIsTokenValid(false);
        // Handle the error as needed
        console.log(error); 
      }

    };

    validateToken();

  }, []);

    // useEffect for socket connection
    useEffect(() => {
      if (currentUser && !socketRef.current) {
        // For testing below
        //socketRef.current= io('http://localhost:8800');
        //Check if we need to disable stickiness
        //socketRef.current= io('https://app.incentivemeet.com', {withCredentials: true, transports: ["websocket", "polling"]});
        socketRef.current= io('https://app.incentivemeet.com', {withCredentials: true});
        //setSocket(socketRef.current);
        setIsSocketReady(true);
        // Cleanup the socket connection when the component is unmounted
        return () => {
          socketRef.current.disconnect();
          socketRef.current = null;
          setIsSocketReady(false);
        };
      }
    }, [currentUser, socket]);

    useEffect(() => {
      const fetchUnreadCounts = async() => {
        try{
          const response = await axios(`api/messages/getUnreadCount`);
          const data = response.data; 
          dispatch(setUnreadCounts(data));
        }
        catch(err){
          console.log(err); 
        }
      }

      const fetchUnreadNotifications = async() => {
       try{
        const response = await axios(`api/messages/getNotifications`);
        const data = response.data; 
        dispatch(setNotifications(data));
       }
       catch(err){
        console.log(err); 

       }
    }

      fetchUnreadCounts();
      fetchUnreadNotifications(); 
  }, []);


  if (isTokenValid === null) {
    return null; // or a loading spinner, etc.
  }

  return (currentUser && isTokenValid) ? 
  (<AppContext.Provider value={socketRef.current}> <Navigation onVariableChange={onVariableChange}/><Outlet /></AppContext.Provider>) 
  : <Navigate to="/login" replace />;
};




const App = () => {
  const [isSocketReady, setIsSocketReady] = useState(false);
  const [sharedVariable, setSharedVariable] = useState('');

  const handleVariableChange = (newVariable) => {
      setSharedVariable(newVariable);
  };

  //const location = useLocation(); 

  //const shouldShowNavigation = location.pathname !== '/single';
 



  return(
    <BrowserRouter>
      {!isSocketReady && <Navigation onVariableChange={handleVariableChange}/> }
      <Routes>
        <Route path="/test" element={<Upgrade/>} /> 
        <Route path="/" element={<BusinessLanding />} />
        {/* <Route path="/" element={<Offers />} /> */}
        <Route path="/single" element={<SingleOffer />}/>
        <Route path="/single/:short" element={<SingleOffer />} />
        <Route path="/social-media" element={<SocialMedia />}/>
        <Route path="/social-media/:title" element={<SocialMedia />} />
        <Route path="/home" element={<Home />} />
        <Route path="/business" element={<BusinessLanding />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/confirmregister" element={<RegisterCode />} />
        <Route path="/privacy" element={<PrivacyPolicy/>}/>
        <Route path="/termsofuse" element={<TermsOfUse />}/>
        <Route path="/invitecode/:linkcode?" element={<Invitecode/>} />
        <Route path="/speedquestion" element={<Speedquestion/>} />
        <Route path="/publiclisting" element={<Offers/>} />
        <Route path="/contactUs" element={<Contact/>} />
        <Route path="/trygpt" element={<ProductGPT isPublic={true} />} />
        <Route path="" element={<ProtectedLayout onVariableChange={handleVariableChange} setIsSocketReady={setIsSocketReady}/>}>
          <Route path="chat" element={<Chat />} />
          <Route path="productgpt" element={<ProductGPT isPublic={false} />} />
          <Route path="search" element={<Search />} />
          <Route path="newprofile" element={<Nprofile />} />
          <Route path="newprofile/:id" element={<Nprofile />} />
          <Route path="profile" element={<Profile />} />
          <Route path="profile/:id" element={<Profile />} />
          <Route path="message" element={<ChatMessage />} />
          <Route path="event" element={<Newevent />} />
          <Route path="invites" element={<Invites isBoolen={false}/>} />
          <Route path ="activeinvites" element={<Invites isBoolean={true}/>} />
          <Route path="checkout" element={<Checkout/>} />
          <Route path="billing" element={<Billing />} />
          <Route path="settings" element={<Settings />} />
          {/* <Route path="eventlisting" element={<Listing/>} /> */}
          <Route path="eventlisting" element={<Offers sharedVariable={sharedVariable} />} />
          <Route path="success" element={<SuccessPage/>} /> 
          <Route path="dashboard" element={<Dashboard/>} /> 
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );

};

// const RootApp = () => {
//   return( 
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   )
// }

export default App;