import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faClipboard, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';

const Sharemodal = ({ show, handleClose, code, link, publicUrl }) => {
  const [copySuccessCode, setCopySuccessCode] = useState(false);
  const [copySuccessLink, setCopySuccessLink] = useState(false);
  const [copyPublicLink, setCopyPublicLink] = useState(false);

  const copyToClipboardCode = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccessCode(true);
    } catch (err) {
      console.log('Failed to copy text: ', err);
    }
  };
  const copyToClipboardLink = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccessLink(true);
    } catch (err) {
      console.log('Failed to copy text: ', err);
    }
  };

  const copyToClipboardPublic = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyPublicLink(true);
    } catch (err) {
      console.log('Failed to copy text: ', err);
    }
  };
  useEffect(() => {
    let timer;
    if (copySuccessCode) {
      timer = setTimeout(() => setCopySuccessCode(false), 2000);
    }
    if (copySuccessLink) {
        timer = setTimeout(() => setCopySuccessLink(false), 2000);
      }
    
      if (copyPublicLink) {
        timer = setTimeout(() => setCopyPublicLink(false), 2000);
      }
    return () => clearTimeout(timer);
  }, [copySuccessCode, copySuccessLink, copyPublicLink]);

  return (
    <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Invite Code or Link Copy</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <p>These links and code will allow for an automatic invite by anyone. 
            The invite code can be entered in "Invite Code" navigation section at the top in cases where links can't be shared. 
        </p>
    <Container>
      <Row className="align-items-center">
      Invite Code: 
        <Col>
          <input type="text" value={code} readOnly className="form-control"/>
        </Col>
        <Col xs="auto">
          <button className="btn btn-primary" onClick={() => copyToClipboardCode(code)}>
            {copySuccessCode ? <FontAwesomeIcon icon={faCircleCheck} /> : <FontAwesomeIcon icon={faClipboard} /> } 
          </button>
        </Col>
      </Row>

      <Row className="align-items-center mt-1">
      Invite Link:
        <Col>
          <input type="text" value={link} readOnly className="form-control"/>
        </Col>
        <Col xs="auto">
          <button className="btn btn-primary" onClick={() => copyToClipboardLink(link)}>
            {copySuccessLink ? <FontAwesomeIcon icon={faCircleCheck} /> : <FontAwesomeIcon icon={faClipboard} />}
          </button>
        </Col>
      </Row>
      <Row className="align-items-center mt-1">
      Public Link:
        <Col>
          <input type="text" value={publicUrl} readOnly className="form-control"/>
        </Col>
        <Col xs="auto">
          <button className="btn btn-primary" onClick={() => copyToClipboardPublic(publicUrl)}>
            {copyPublicLink ? <FontAwesomeIcon icon={faCircleCheck} /> : <FontAwesomeIcon icon={faClipboard} />}
          </button>
        </Col>
      </Row>
    </Container>
    </Modal.Body>
         <Modal.Footer>
           <Button variant="secondary" onClick={handleClose}>
             Close
           </Button>
         </Modal.Footer>
       </Modal>
  );
}

export default Sharemodal;
