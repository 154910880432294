import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector} from 'react-redux'; 
import WhiteCard from "./WhiteCard";
//import { ServicesPromotionsCardsList } from "../../Helper";
import PostOffersCards from "../../pages/event/PostOffersCards";
import PromotionEdit from "./PromotionEdit";
import BusinessInfo from "./BusinessInfo";
import Description from "./Description";
import OfferDetails from "./OfferDetails";
import RelatedOffers from "./RelatedOffers";
import Reviews from "./Reviews";
import "./singleoffer.scss"; 
import axios from '../../backendClient'
import Emptystate from "../../components/Emptystate";

const SingleOffer = ({}) => {
    const [editDetails, setEditDetails] = useState(false);

    const [data, setData] = useState([]); 
    const [loading, setLoading] = useState(false); 

    const {currentUser} = useSelector(state => state.user);


    //short can either be a shortTitle or AffiliateCode
    const {short} = useParams(); 

    useEffect(() => {
      if (editDetails) {
        document.documentElement.classList.add("overflow-hidden");
      } else {
        document.documentElement.classList.remove("overflow-hidden");
      }
    }, [editDetails]);
  
    const location = useLocation();
    const currentPathname = location.pathname;
    const [isPathname, setIsPathname] = useState(false);
  
    const getTitleBySlug = (pathname) => {
      // Remove "/offer" from the pathname
      const cleanedPathname = pathname.replace("/offer/", "");
      // Find the matching route based on the cleaned slug
    //   const matchingRoute = ServicesPromotionsCardsList.find(
    //     (route) => route.slug === cleanedPathname
    //   );
  
      //matchingRoute && setIsPathname(matchingRoute);
    };
  
    useEffect(() => {
      //getTitleBySlug(currentPathname);
    }, [location]);


    //get viewedEventId from useParams with /singleoffer/:id 
          
    const fetchSingle = async (fetchId) => {

    
          try{
            let response; 
            setLoading(true); 
           
            response = await axios.post(`/api/events/getSingleEvent`, {singleSearch: fetchId });
            const newData = response.data; 
            setData(newData);
            
          }
          catch(e){
           console.log(e); 
          }finally{
            setLoading(false); 
          }
    };
    
    useEffect(() => {
     const fetchId = short || 'post-your-offer'; 
     fetchSingle(fetchId); 
    }, []);

    if(data.length === 0 && !loading){
      return <Emptystate title="No event page found" subtitle="This event has closed or is private" />
    }

    if(data.length === 0 && loading){
      return <Emptystate title="We are loading the offer..." subtitle="Just a moment while we find it." /> 
    }


  
    return (
      <>
        {" "}
        {/* <div className="mt_custom_over_nav">
          <PostOffersCards /> 
        </div> */}
        <div id="promotions-details">
          <div className=" container container_custom mt-4 pt-lg-2">
            <div className="row">
              <div className="col-md-6 ps-md-0">
                <BusinessInfo offerObject={data} isPathname="" />
              </div>
              <div className="col-md-6 pe-md-0 mt-3 mt-sm-4 mt-md-0">
                <WhiteCard
                  className="h-100"
                  content={
                    <OfferDetails
                      isPathname={isPathname}
                      editDetails={editDetails}
                      setEditDetails={setEditDetails}
                      offerObject={data}
                      userNow = {currentUser}
                    />
                  }
                />
              </div>
              <div className="col-md-6 ps-md-0 mt-3 mt-md-4">
                <WhiteCard
                  className="h_custom_dec"
                  title={data.eventTitle}
                  content={
                    <Description
                      description={
                        <span>
                          {data.eventDescription}
                        </span>
                      }
                    />
                  }
                />
                <WhiteCard
                  className="mt-3 mt-md-4"
                  title=" Reviews"
                  content={<Reviews passedReviews={data.reviews} />}
                />
              </div>
              <div className="col-md-6 pe-md-0 mt-3 mt-md-4 mb-4 mb-md-0">
                <WhiteCard
                  title="Related Offers"
                  // className="hight_100"
                  content={<RelatedOffers relatedEvents={data.related} />}
                />
              </div>
            </div>
          </div>
        </div>
        <PromotionEdit
          editDetails={editDetails}
          setEditDetails={setEditDetails}
          userNow = {currentUser}
        />
      </>
    );
  };
  
  export default SingleOffer;
  