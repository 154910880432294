
import {
  AutoMotive,
  Contests,
  ECommerce,
  Facebook,
  GroupChat,
  HealthFitness,
  HomeServices,
  Instagram,
  LinkedIn,
  Misc,
  Nightlife,
  PromoInvite,
  RealEstate,
  RealFeedback,
  Restaurants,
  Retail,
  SocialInteractions,
  TechRepairs,
  TravelTours,
  ViaCode,
  Youtube,
} from "./Icons";

export const navLinks = [
  {
    link: "Menu",
    url: "/",
  },
  {
    link: "Menu",
    url: "/",
  },
  {
    link: "Menu",
    url: "/",
  },
  {
    link: "Menu",
    url: "/",
  },
];

export const toolCards = [
  {
    icon: <AutoMotive />,
    title: "Automotive",
  },
  // {
  //   icon: <TravelTours />,
  //   title: "Travel Tours",
  // },
  {
    icon: <TechRepairs />,
    title: "Tech Products",
  },
  {
    icon: <HealthFitness />,
    title: "Health Supplements",
  },
  {
    icon: <ECommerce />,
    title: "E-Commerce Apparel",
  },
  {
    icon: <HomeServices />,
    title: "Home Goods",
  },
  // {
  //   icon: <Contests />,
  //   title: "Contests",
  // },
  // {
  //   icon: <Retail />,
  //   title: "Retail",
  // },
  // {
  //   icon: <RealEstate />,
  //   title: "Real Estate",
  // },
  // {
  //   icon: <Nightlife />,
  //   title: "Nightlife",
  // },
  // {
  //   icon: <Restaurants />,
  //   title: "Restaurants",
  // },
  {
    icon: <Misc />,
    title: "Misc",
  },
];
export const promotionsCards = [
  {
    icon: <PromoInvite />,
    title: "Promo Invites",
    description: "Incentive helps companies offer deep discounts to customers.",
  },
  {
    icon: <GroupChat />,
    title: "Group Chat to Connect",
    description:
      "Communication builds community and excitement between like-minded people. The chat and communication closes after the promo event. So conversation remains focused around the new promo.",
  },
  {
    icon: <ViaCode />,
    title: "Invite via Code",
    description:
      "Use social media or any other platform via an Invite Link or Code people can enter to sign up and automatically get invited to take advantage of the discount. Meaning you can scale promotions from flyers, conferences, or impromptu interactions to dive deeper with customers.",
  },
  {
    icon: <RealFeedback />,
    title: "Get Real Feedback",
    description:
      "Leave private (non-public) reviews for business hosts to ensure trust and safety. We summarize your reviews to highlight the best traits on their business page. A trust score for all businesses ensures integrity of the promotion. Businesses can get full reviews to help them iterate or improve their products.",
  },
  {
    icon: <SocialInteractions />,
    title: "Social Interactions",
    description:
      "Promotions ensure more reliable sales and getting feedback for brick and mortar as well as online stores. Do away with finnicky online ads or promoting with click based platforms.",
  },
];
// Footer
export const footerSocialIcons = [
  {
    icon: <Facebook />,
    url: "https://www.facebook.com/",
  },
  {
    icon: <LinkedIn />,
    url: "https://in.linkedin.com/?src=go-pa&trk=sem-ga_campid.14650114788_asid.151761418307_crid.657403558721_kw.linkedin%20login_d.c_tid.kwd-12704335873_n.g_mt.e_geo.9303870&mcid=6844056167778418689&cid=&gad_source=1&gclid=CjwKCAiAivGuBhBEEiwAWiFmYdfFRVfE2przO6S8TTtmbBpmHt4S7Ai-tKKFjXKLTOtNJQSXi6sY3xoCzFkQAvD_BwE&gclsrc=aw.ds",
  },
  {
    icon: <Instagram />,
    url: "https://www.instagram.com/",
  },
  {
    icon: <Youtube />,
    url: "https://www.youtube.com/",
  },
];
export const standardSpecification = [
  "Unlimited Email Accounts",
  "Unlimited Email Warmup",
  "1000 Active Leads",
  "5000 Emails Monthly",
  "Live Chat Support",
];
export const enterpriseSpecification = [
  "Unlimited Email Accounts",
  "Unlimited Email Warmup",
  "1000 Active Leads",
  "5000 Emails Monthly",
  "Live Chat Support",
];
