import { InlineWidget } from "react-calendly";
const ScheduleTime = () => {
  return (
    <div className="py-5 max_w_1920 mx-auto px-xl-0 px-3">
              <div className=" position-relative">
            {" "}
            <h2 className="text-center fw-bolder mb-0 position-relative z-2">
              Schedule A Free Call: Get More Affiliate Sales or Sign Up For Free 
            </h2>
          </div>{" "}
      <div className="py-sm-3 py-md-4 py-lg-5 position-relative d-inline-block w-100 ">
        <div className="layer__orange position-absolute "> </div>
        <div className="layer__blue position-absolute d-none d-sm-block"> </div>
        <InlineWidget
          url="https://calendly.com/incentive-meet/30min"
          styles={{
            height: `${window.innerWidth > 576 ? "712px" : "500px"}`,
            position: "relative",
            zIndex: "1",
          }}
        />
      </div>
    </div>
  );
};

export default ScheduleTime;