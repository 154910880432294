import { HeadingVector } from "./Icons";
import {ImageBgOrange} from "./Icons"; 
import quotationMarks from "../../components/images/svg/quotation-marks.svg"; 
import neuroImg from "../../components/images/webp/front-neuro.webp"; 

const AuthorBlog = () => {
    return (
      <>
        <div id="author-blog" className="position-relative z-1 mb-lg-5">
          <div className="left_shadow_orange position-absolute start-0 top-50 translate-middle-y rounded-pill z-n1"></div>
          <div className="container container_modified">
            <div className="row flex-column-reverse flex-lg-row align-items-center">
              <div className="col-lg-6 pe-0 mt-lg-0 mt-5 pt-4 pt-lg-0">
                <img src={quotationMarks} alt="quotation marks" />
                <p className="mb-0 fw-normal text-black position-relative z-1 mt-3">
                  <span className="author_blog_heading position-absolute z-n1">
                    <HeadingVector />
                  </span>
                  “Western Medicine is simply catching up to the tremendous and therapeutic benefits of mushrooms in all facets of our lives. 
                  From the ability to calm nerves, to enhance foucs, as well as digestive rebalancing and muscle repair. If there was a naturally 
                  derived miracle cure..Neuro Mushroom Pro has it in one supplement.”
                </p>
                <h6 className="font-md fw-light text-black mt-md-3 mt-2 pt-1 mb-0">
                  by Dr. Bob Love
                </h6>
              </div>
              <div className="col-lg-6 text-end position-relative">
                <div className="position-relative girl_image mx-auto">
                  <img
                    src={neuroImg}
                    alt="girl"
                    className="position-relative z-1 w-100"
                  />
                  <div className="linear_clr"></div>
                  <span className="position-absolute start-50 orange_image">
                    <ImageBgOrange />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  
  export default AuthorBlog;