import { DropdownButton, Dropdown } from "react-bootstrap";

const PrimaryDropdown = ({ title, icon, itemList, customStyles }) => {
    return (
      <DropdownButton
        className={customStyles}
        id="dropdown-basic-button"
        title={
          <>
            {icon ? (
              <div className=" d-flex align-items-center gap-1 gap-sm-2">
                <span
                  style={{ marginTop: "-2px" }}
                  className=" d-inline-block scale-8-mobile">
                  {icon}
                </span>
                {title}
              </div>
            ) : (
              title
            )}
          </>
        }>
        {itemList.map((obj, i) => (
          <Dropdown.Item key={i} href="#/action-1">
            {obj}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    );
  };
  
  export default PrimaryDropdown;