import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Paidmodal from "../../components/Paidmodal";


export const Upgrade = () => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); 

  const onSubscribe = async () => {
    // ... your existing subscribe logic
  };

  const newUser = "Jenna"; 
  const checkoutUrl = '/checkout'; 
  const handleNavigate = () => {
    navigate('/checkout'); 
  }
  return (
    <>
      <Button onClick={() => setShowModal(true)}>Open Modal</Button>
      
      <Paidmodal
        show={showModal}
        handleClose={() => setShowModal(false)}
        title="Upgrade to Premium for More Invites"
        footerText={`${newUser} would love to join you at your event!`}
        imgSrc="https://d1873pk1gxetu0.cloudfront.net/events/It's+time+to+break-10.svg"
        onNavigate={handleNavigate}
        children="Or come back next month for more invites. "
      >
      </Paidmodal>
    </>
  );
};

export default Upgrade;
