import React, {useContext, useEffect, useState, useRef} from 'react';
import { Container, Row, Col, Image, Form, Button, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate} from "react-router-dom";
import { AppContext } from '../../AppContext';
import './chatmessage.scss';
import {format} from 'timeago.js';
import { incrementUnreadCount } from '../../redux/messagesSlice';
import axios from 'axios';

const ChatMessage = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { profiles, eventTitle, eventDate } = location.state;  
  const socket = useContext(AppContext); 
  const [message, setMessage] = useState(''); 
  const [messages, setMessages] = useState([]); 
  const {currentUser} = useSelector(state => state.user); 
  const formRef = useRef(null); 
  const isMounted = useRef(true);
  const messageEndRef = useRef(null);
  const dispatch = useDispatch(); 

 


  useEffect(() => {

    console.log('Use EFEFECT RUNS'); 
    if (socket) {
      socket.on('disconnect', () => {
        console.log('Socket disconnected');
        // Redirect to home page or login page
        //navigate('/login');
      });


      socket.on('prev-messages', (messages)=>{
        setMessages(messages);
      });

      socket.on("room-messages", (roomMessage) => {
        setMessages(prevMessages => [...prevMessages, roomMessage]);
      });

      // emit should be after the 'prev' message listener is setup check if resolves issue
      socket.emit("join-room", profiles._id);
      console.log('JOIN ROOM EFEFECT RUNS'); 

      // Move increment logic to navbar so it's centralized
      // socket.on("increment-message", async (room)=>{
      //   console.log(typeof(room));
      //   console.log(typeof(profiles._id));
      //   console.log(room)
      //   console.log(profiles._id);
      //   console.log(profiles._id !== room);
      //   if(profiles._id !== room) {
      //     dispatch(incrementUnreadCount(room));

      //     try{

      //       const response = await axios.post('/api/messages/incrementUnreadCount',{roomId: profiles._id});

      //     }catch(e){
      //       console.log('Failed to set unread message ', e);
      //     }
      //   }  
      // });



      socket.on('error', (error) => {
        console.error('Socket encountered an error:', error);
        // Redirect to home page or login page
        navigate('/login');
      });
    }

    // Cleanup listeners when component unmounts
    return () => {
      if (socket) {
        // takes care of having to leave rooms
        socket.emit('leaveRoom', profiles._id);
        // possibly add dispatch event to set messageStore's current room to '' to only notify when not in the current room
        socket.off('error');
        socket.off('room-messages'); 
        socket.off('prev-messages');
        //socket.off('increment-message');
      }
    };
  }, [socket]);

  useEffect (()=> {
    console.log(messages);
    //console.log(profiles._id); 
    // socket.off("room-messages").on("room-messages", (roomMessage) => {
    //   setMessages(prevMessages => [...prevMessages, roomMessage]);
    // });
    scrollToBottom();
  },[messages]); 


  useEffect(() => {
    const handleGlobalKeyDown = (e) => {
        if (e.key === 'Enter' && isMounted.current) {
            handleSubmit();
        }
    };
    document.addEventListener('keydown', handleGlobalKeyDown);
    // Cleanup the event listener when the component is unmounted
    return () => {
        document.removeEventListener('keydown', handleGlobalKeyDown);
    };
  }, []);


  const handleSubmit = (e) =>{
    if (formRef.current && isMounted.current) {
      const today = new Date();
      const minutes = today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
      const time = today.getHours() + ":" + minutes;
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const formattedDate = `${monthNames[today.getMonth()]} ${today.getDate()}, ${today.getFullYear().toString().substring(0,4)}`;
      const formData = new FormData(formRef.current);
      const messageValue = formData.get('message');  

      // ** TO DO ** Only Have sender._id, sender.profilePic and sender.userName remove all other fields as rest look for all currentUser and modify to only necessary info
      const {_id, userName, profilePic } = currentUser; 
      const sendUser = {_id, userName, profilePic};
      if (!messageValue) return;
      //old: socket.emit("message-room", profiles._id, messageValue, currentUser, time, formattedDate, today);
      socket.emit("message-room", profiles._id, messageValue, sendUser, time, formattedDate, today);
      setMessage('');
    }
  }

  const handleFormSubmit = (e) =>{
    e.preventDefault(); 
  }
  

  const handleImageClick = (id) =>{
    navigate(`/profile/${id}`); 
  }
  // ensuring Mounting otherwise prevent memory leaks 
  // this is a cleanup
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const scrollToBottom = () => 
  {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }




  return (   <Container fluid className="message-container py-4">
  <Row className="mb-3 d-flex flex-sm-row">
    <Col xs={1}>
      <Button variant="light" onClick={() => navigate("/chat")}>
        Back
      </Button>
    </Col>
    <Col xs={9}>
      <h2 className="event-title text-center">{eventTitle}</h2>
      <h6 className="event-date text-center">{eventDate?.split('T')[0]}</h6>
    </Col>
    <Col xs={1}> </Col>
  </Row>

  <Row className="profile-row mb-3">
    {profiles.guests.slice(0, 5).map((profile, index) => (
      <Col xs={2} sm={1} key={index} className="profile-col px-1">
        <div className="profile-wrap" onClick={() => handleImageClick(profile.user._id)}>
        <Image src={profile.user.profilePic} alt="Profile" className="profile-image w-100" />
        <p className='profile-name'>{profile.user.userName}</p>
        </div>
      </Col>
    ))}
    {profiles.guests.length > 5 && (
      <Col xs={2} sm={1} className="profile-col px-1 d-flex justify-content-center align-items-center overflow-profiles">
        <Badge pill variant="danger">+{profiles.guests.length - 5}</Badge>
      </Col>
    )}
  </Row>

<div className='chat-container'>
  <div className="chat-box mb-4">
  {messages?.map(({ sender, content, date, sortDate }, msgIdx) => (
                                <div className={sender?._id == currentUser._id ? "message-bubble sender" : "message-bubble receiver"} key={msgIdx}>
                                    <div className="message-inner">
                                        <div className="d-flex align-items-left mb-3">
                                            <img src={sender.profilePic} style={{ width: 35, height: 35, objectFit: "cover", borderRadius: "50%", marginRight: 10 }} />
                                            <p className="message-sender" style={{ fontSize: '8', margin: 0, marginRight: 10  }}>{sender._id == currentUser?._id ? "You" : sender.userName}</p>
                                            <p  className="ml-2" style={{ fontSize: '8'}}>{date}</p>
                                        </div>
                                        <p className="message-content">{content}</p>
                                        <p className="time-ago">{format(sortDate)}</p>
                                    </div>
  </div>))}
        <div ref={messageEndRef} />
   </div>

  <Row className="d-flex flex-column flex-sm-row justify-content-between align-items-stretch mb-3">
        <Col xs={12} sm={9} className="pe-2">
          <Form ref={formRef} onSubmit={handleFormSubmit}>
          <Form.Group className="mb-2 mb-sm-0 flex-grow-1">
            <Form.Control name="message" type="text" placeholder="Type a message" value={message} onChange={(e) => setMessage(e.target.value)} />
          </Form.Group>
          </Form>
        </Col>
        <Col xs={12} sm={3} className="ps-2 mt-2 mt-sm-0">
          <Button variant="primary" type="button" onClick={handleSubmit} className="w-100">Send</Button>
        </Col>
        <Col xs={12} md={4} className='mt-3'>
        
        <Button className="w-100" variant="danger" onClick={() => alert("Contact Us via the support icon in the bottom right. We will reach out within 48 hours!")}>
                  Business/Customer Non-Responsive? 
        </Button>
        </Col>
  </Row>
  </div>
</Container>
);
}

export default ChatMessage