import React from 'react';
import { Card } from 'react-bootstrap';
import './privacy.scss'; 

const PrivacyPolicy = () => {
  return (
    <Card>
      <Card.Header className="card-header">Privacy Policy</Card.Header>
      <Card.Body>
        <Card.Text className="card-text">
          <p> We are committed to protecting the privacy of our users. This Privacy Policy describes how we collect, use, and share personal information.</p>
          <h3>GDPR Compliance</h3>
          <p>
            We are committed to ensuring the security and protection of the personal information that we process, and to provide a compliant and consistent approach to data protection.
            EU Residents have the right to access their personal information and to request that their personal information be deleted. 
            Users can exercise these rights by contacting us at incentivemeet-account@rshieldtech.com
          </p>
          <p>
            We have a robust and effective data protection program in place which complies with existing law and abides by the data protection principles.
          </p>

          <h3>California Privacy Act</h3>
          <p>
            We also adhere to the California Privacy Act and ensure that the personal data of California residents is processed in line with the rights and obligations under this legislation.
          </p>

          <h3>Data Collection</h3>
          <p>
          We are committed to protecting the privacy of our users. This Privacy Policy describes how we collect, use, and share personal information.
          We collect personal information from our users when they create an account, sign up via invite codes. The personal information we collect may include:
         </p>
            <ul>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Photo(s)</li>
            </ul>
          <p>
           We may also collect non-personal information from our users, such as their IP address, browser type, and referring/exit pages. We also aggregate data inputs on the site for recomendations and other features.
          </p>

          <h3>Google Analytics</h3>
          <p>
            We may use Google Analytics to understand how our users interact with our website. 
            This tool helps us measure our website's performance, track user behavior, and gather demographic information about our user base as a whole. 
            The information collected may include IP addresses, browser type, internet service provider (ISP), referring/exit pages, platform type, date/time stamp, and number of clicks. This data is not linked to any information that is personally identifiable.
          </p>

          <h3>Third-Party Pixels</h3>
          <p>
          We may share personal information with third parties for the purposes described above. 
          We may also share personal information with third parties if we are required to do so by law or if we believe that sharing the information is necessary to protect our rights or the rights of others.
          </p>

          <h3>Data Security</h3>
          <p>
          We take measures to protect the security of the personal information we collect. These measures include:
          <ul>
            <li>Storing the data in a secure location</li>
            <li> Using encryption to protect the data</li>
            <li>Limiting access to the data to authorized personnel</li>
            </ul>
            We store IP addresses for compliance with regulations such as GDPR. IP addresses are stored securely and are not shared with unauthorized third parties.
          </p>
          <h3>Data Use</h3>
          <p>
          We use the personal information we collect for the following purposes:
          <ul>
            <li>To provide our services</li>
            <li>To understand user interactions with our ads</li>
            <li>To comply with regulations</li>
            </ul>
          </p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default PrivacyPolicy;
