import React from 'react';
import {  Col, Card } from 'react-bootstrap';
import './flatstyledcard.scss';

const FlatStyledCard = ({membershipPrice = 20, discount = 5, total = 15}) => {
  return (

          <Card className="flat-card">
            <Card.Body>
              <div className="membership-section">
                <h5>Premium Membership (per Month)</h5>
                <p>${membershipPrice.toFixed(2)}</p>
              </div>
              <div className="discount-section">
                <h5>Discount</h5>
                <p>-${discount.toFixed(2)}</p>
              </div>
              <div className="total-section">
                <h5>Total (Monthly until Canceled)</h5>
                <p>${total.toFixed(2)}</p>
              </div>
            </Card.Body>
          </Card>



  );
};

export default FlatStyledCard;