import { HeadingVector, IconButtonArrow } from "./Icons";
import PrimaryButton from "../../components/PrimaryButton";

const MediaWorked = () => {
    const scrollToSection = (event) => {
        event.preventDefault(); 
        const element = document.getElementById("product-pricing");
        element.scrollIntoView({ behavior: 'smooth' });
      };
    return (
      <>
        <div id="media-worked">
          <div className="container container_modified">
            <h6 className="font-xxl text-center fw-bolder mb-lg-5 mb-md-4 pb-lg-3 pb-1 position-relative z-1">
              Certified And Tested
              <span className="media_worked_heading position-absolute start-50 z-n1 translate-middle-x">
                <HeadingVector />
              </span>
            </h6>
            <div className="row align-items-center text-center pt-xl-1">
              <div className="col-lg-2 col-4 mt-4 mt-lg-0">
                <img src="https://d1873pk1gxetu0.cloudfront.net/landing/fda.webp" alt="red-bull" className="red_bull w-100" />
              </div>
              <div className="col-lg-2 col-4 mt-4 mt-lg-0">
                <img src="https://d1873pk1gxetu0.cloudfront.net/landing/gmp.jpeg" alt="boodle" className="boodle w-100" />
              </div>
              <div className="col-lg-2 col-4 mt-4 mt-lg-0">
                <img
                  src="https://d1873pk1gxetu0.cloudfront.net/landing/thirdparty.jpeg"
                  alt="bridgement"
                  className="w-100 bridgement"
                />
              </div>
              <div className="col-lg-2 col-4 mt-4 mt-lg-0">
                <img
                  src="https://d1873pk1gxetu0.cloudfront.net/landing/organic.png"
                  alt="wayMarketing"
                  className="w-100 way_marketing"
                />
              </div>
              <div className="col-lg-2 col-4 mt-4 mt-lg-0">
                <img src="https://d1873pk1gxetu0.cloudfront.net/landing/usamade.jpeg" alt="cliqtech" className="w-100 cliqtech" />
              </div>
              <div className="col-lg-2 col-4 mt-4 mt-lg-0">
                <img src="https://d1873pk1gxetu0.cloudfront.net/landing/Screenshot+2024-07-22+at+4.05.10%E2%80%AFPM.png" alt="bizzamm" className="w-100 bizzamm" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 d-flex justify-content-center">
                  <PrimaryButton
                    text="Proceed to Purchase"
                    btnStyle="justify-content-center mt-4"
                    onclick={scrollToSection}
                    icon={
                      <span className="ps-2 d-inline-block">
                        <IconButtonArrow />
                      </span>
                    }
                  />
                  </div>
                </div>

        </div>
      </>
    );
  };
  
  export default MediaWorked;