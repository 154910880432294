import React from "react";

const Field = ({
  placeholder,
  id,
  type,
  customStyles,
  labelStyle,
  maxWidth,
  margin,
  icon,
}) => {
  return (
    <label
      id="input-label"
      style={{ maxWidth: maxWidth || "221px", margin: margin || "" }}
      htmlFor={id || "input"}
      className={`d-flex align-items-center gap-3 ${labelStyle}`}
    >
      {icon && (
        <span className=" cursor_pointer d-flex justify-content-center align-items-center">
          {icon}
        </span>
      )}

      <input
        id={id || "input"}
        className={`w-100 border-0 focus-disabled outline-disabled ${
          customStyles && customStyles
        }`}
        type={type || "text"}
        placeholder={placeholder || "Type..."}
      />
    </label>
  );
};

export default Field;
