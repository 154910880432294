import { 
  GiGolfFlag, 
  GiHiking, 
  GiCaveEntrance, 
  GiForestCamp, 
  GiIsland,
  GiPartyPopper,
  GiLovers,
  GiFilmProjector
} from 'react-icons/gi';
import { MdSportsBar, MdFastfood, MdPaid} from 'react-icons/md';
import {FaBiking, FaSkiing, FaBoxOpen} from 'react-icons/fa'; 
import { SiYourtraveldottv } from "react-icons/si";
import { RiComputerFill } from "react-icons/ri";
import { RiMentalHealthLine } from "react-icons/ri";
import { IoRestaurant } from "react-icons/io5";
import { FaCarSide } from "react-icons/fa";
import { MdOutlineConstruction } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { FaStore } from "react-icons/fa";
import { FaShopify } from "react-icons/fa";



// Note: This file is depecreated . Also change in Backend AI Ask *** if you update here  the file is the Helper.jsx in event folder to update th*** 
export const categories = [
    {
     label: 'Automotive',
     icon: FaCarSide,
     description: 'Auto or auto repairs'
    },
    {
      label: 'Travel Tours',
      icon: SiYourtraveldottv,
      description: 'Tours or city activities',
    },
    {
      label: 'Tech Repairs',
      icon: RiComputerFill,
      description: 'Technoloyg or phone repairs',
    },
    {
      label: 'Health and Supplements',
      icon: RiMentalHealthLine,
      description: 'Health and wellness',
    },
    {
      label: 'E-Commerce',
      icon: FaShopify,
      description: 'E-commerce or online stores',
    },
    {
      label: 'Home Services',
      icon: MdOutlineConstruction,
      description: 'Home improvement services',
    },
    {
      label: 'Contests',
      icon: MdPaid,
      description: 'Paid contests'
    },
    {
      label: 'Retail',
      icon: FaStore,
      description: 'Retail, gifts and craft'
    },
    {
      label: 'Real Estate',
      icon: FaHome,
      description: 'Real estate, apartment listings etc'
    },
    {
      label: 'Nightlife',
      icon: MdSportsBar,
      description: 'Events centered around nightlife or meeting up at bars'
    },
    {
      label: 'Restaurants',
      icon: MdFastfood,
      description: 'Meeting up at restaurants'
    },
    {
      label: 'Social',
      icon: GiLovers,
      description: 'Meeting up for casual or social'
    },
    {
      label: 'Misc',
      icon: FaBoxOpen,
      description: 'Other categories'
    }
  ]