import React from 'react'
import {Accordion, Container, Row, Col} from 'react-bootstrap';
import './faq.scss';

const Faq = () => {
    return (
        <Container className="pt-5">
            <Row>
                <Col md={7} className="faq__bg order-2 order-md-1"></Col>
                <Col md={5} className="align-items-center justify-content-center">
                    <h2 className="form-title text-center mb-4">Frequently Asked Questions/ How it Works</h2>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Can I invite people directly on the site? </Accordion.Header>
                            <Accordion.Body>
                            Yes just search for them by city in the search page and invite them. You must
                            create a promotion first from the "My Offers" page. Customers can choose to accept or decline joining a promotional offer. 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>How can I use promotional offers? </Accordion.Header>
                            <Accordion.Body>
                            We recommend in store businesses offer deals to attract new customers. Online and other businesses should offer 
                            deep discounts and build excitment and engage their communities to get valuable feedback on their existing or new products. 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>What if I want to be invited by a business for promotional offers?  </Accordion.Header>
                            <Accordion.Body>
                            Redeem promos on the main page and we will recommend similar promos to you. Businesses can also re-invite engaged customers who 
                            redeemed a previous discount offer or rated them positively for other promos. 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>What if I don't want to redeem an offer after I request to redeem it? </Accordion.Header>
                            <Accordion.Body>
                            There is no obligation to redeem a discount offer if you change your mind. Assuming you haven't made a purchase. 
                            You will not be able to count that offer in your redeemed qualified offers count to qualify for contests. 
                            You must confirm redemption with a redemption code the business give you after purchase to count the offer as redeemed. 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>How do I create an offer on this platform?</Accordion.Header>
                            <Accordion.Body>
                            You must sign up as a businesses to create an offer by clicking "Create Offer" on the "My Offers" page. 
                            Fill in all the necessary details like date, time, venue, and description of the event, and then click on "Submit" to create your offer.
                            For online stores just enter the city you are based or targeting for the promotion such as Los Angeles, CA or Seattle WA etc. 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>How do reviews work? </Accordion.Header>
                            <Accordion.Body>
                            After promo completion you can write a review about the business that impacts
                            their trust score via a propietary AI algorithm. We only reveal reviews to the businesses themselves
                            so they can get feedback about their promotions. But full reviews are not revealed on our platform publicly 
                            unless the business wants. 
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
            </Col>
            </Row>
        </Container>
        
        
      );
}

export default Faq