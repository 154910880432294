import React, {useState, useEffect, useContext} from "react";
import promtionImg from "../../components/images/dashboard/png/promotions_bg_img.png";
//import jamie from "../../../assets/images/dashboard/webp/img-user.webp";
//import FilterDropdown from "./FilterDropdown";
//import SortDropdown from "./SortDropdown";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector} from 'react-redux';
import axios from '../../backendClient'
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from "./Sidebar";
import { AppContext } from '../../AppContext';



const Promotions = ({ handleClose, category, sharedVariable}) => {

     
    const navigate = useNavigate(); 
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);

    const {currentUser} = useSelector(state => state.user);
    const [selectedCat, setSelectedCat] = useState(category || ''); 



    const fetchMoreData = async () => {
      if(loading || !hasMore){
        return; 
      }

      setLoading(true); 

      try{
        let response; 
        if(!currentUser){
            response = await axios.get(`/api/events/getPublicFacingEvents?page=${page}&&category=${selectedCat}`);
        }
        else{
            response = await axios.get(`/api/events/getPublicEvents?page=${page}&&category=${selectedCat}&&query=${sharedVariable}`);
        }
       
        const newData = response.data; 
        setData(prevData => [...prevData, ...newData]);
        setPage(prevPage => prevPage + 1);
        setHasMore(newData.length > 0);
      }
      catch(e){
        //setError(e.response.data.message);
        setHasMore(false); 
      } finally{
        setLoading(false); 
      }
    };

 //Trigger fetchMoreData on component mount and when page changes.
    useEffect(() => {
      if(sharedVariable){
        setSelectedCat(''); 
      }
      fetchMoreData();
    }, [page, sharedVariable]);

      // Update selected category when the prop changes
  useEffect(() => {
    if(sharedVariable){
      setSelectedCat(''); 
    }else{
    setSelectedCat(category || ''); } 
    setPage(1); // Reset to the first page
    setData([]); // Clear existing data for the new category
    setHasMore(true); // Assume more data is available
  }, [category, sharedVariable]);


    
    const handleShow = () => {
      navigate('/event'); 
    }

    //tracking clicks for dashboard with a visible card or not on Offers and Listings 
    const [visibleCard, setVisibleCard] = useState(null);

    //pass in an id for the Offer to set it's details as visible in EventCard component 
    const toggleCardVisibility = (id) => {
      if(visibleCard === id){
        setVisibleCard(null);
      }else{
      setVisibleCard(id);}
    };

    const [showSidebar, setShowSidebar] = useState(false);

    const [selectedCardData, setSelectedCardData] = useState(null); // State to hold the selected card's data

    const handleImageClick = (id) =>{
        navigate(`/profile/${id}`); 
      }

    const handleCardClick = (cardData) => {
      setSelectedCardData(cardData); // Set the selected card's data
      setShowSidebar(true); // Show the sidebar
    };

    const handleOpenSidebar = () => {
      setShowSidebar(true);
    };
  
    const handleCloseSidebar = () => {
      setShowSidebar(false);
    };
   
    
  
  return (
    <div
      id="promotions"
      className="pb-3 pt-3 mt-1 py-sm-4 mt-sm-2 mb-sm-4"
      style={{
        maxWidth: "1360px",
        margin: "auto",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      {/* Filter and Sort dropdowns */}
      {/* <div className="d-flex flex-column flex-sm-row justify-content-end gap-sm-3 gap-2">
        <FilterDropdown />
        <SortDropdown />
      </div> */}
      {/* Promotions heading */}
      <div className="position-relative">
        <img
          src={promtionImg}
          alt="img"
          width={149}
          height={61}
          className=" position-absolute start-50 translate-middle-x promtion_img"
        />
        <h3 className="text-center font-xxl fw-bolder text-black mb-2 pb-sm-1 position-relative z-1 mt-sm-4 mt-1 pt-3">
          Promotions in Your City
        </h3>
      </div>
      {/* Promotions cards */}
      <InfiniteScroll
            dataLength={data.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={ data.length > 0 && 
            <p style={{textAlign: 'center'}}>
                <b>Yay! You have seen it all</b>
            </p>
            }
        > 
      <div className="row justify-content-center">

        {data.map((obj, index) => {
          return (
            <div className="col-lg-4 col-md-6 col-sm-8 col-12 mt-3" key={index}>
              <div className="bg-white promotions_card">
                {/* Promotion image */}
                <div className="overflow-hidden br_10">
                  <img
                    src={obj.imgEvent}
                    alt="promotions"
                    className="w-100 promotion_img"
                  />
                </div>

                <div className="sub_card">
                  <div className="d-flex align-items-center justify-content-between">
                    {/* Promotion title and description */}
                    <h6 className="mb-0 fw-bolder" style={{ cursor: 'pointer' }}  onClick={() => navigate(`/single/${obj.shortTitle}`)}>{obj.eventTitle}</h6>
                    <p className="mb-0 work fw-bolder">{obj.tags[0]}</p>
                  </div>
                  {/* Promoter information */}
                  <div className="d-flex align-items-center gap-2 mt-3 profile" onClick={() => handleImageClick(obj.hostUser._id)}>
                    <img src={obj.hostUser.profilePic} alt="#prof_img" width={30}  style={{ borderRadius: '50%', height: '100%', objectFit: 'cover' }}/>
                    <p className="mb-0">{obj.hostUser.userName}</p>
                  </div>
                  {/* Button to show interest */}
                  <button
                    className="mt-sm-4 mt-3 fw-medium text-white w-100 common_btn"
                    onClick={()=>handleCardClick(obj)}
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      </InfiniteScroll>
      {selectedCardData && <Sidebar showSidebar={showSidebar} data={selectedCardData} currentUser={currentUser} handleClose={()=>setShowSidebar(false)} /> }
    </div>
  );
};

export default Promotions;
