import React, { useEffect } from 'react';
import Emptystate from '../../components/Emptystate';
import { useLocation, useNavigate } from 'react-router-dom';


const SuccessPage = () => {

  const location = useLocation(); 
  const navigate = useNavigate(); 
  
  useEffect(() => {
    setTimeout(()=>{

    if(location.state?.from === "/trygpt"){
      navigate('/trygpt'); 
    }}, 2000)
  }, []);
  

  return (
    <Emptystate title="You have successfully subscribed to Premium" subtitle="Manage your plan in Settings including cancellations"/>
  )
}

export default SuccessPage; 