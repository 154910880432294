import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert, Card, Spinner } from 'react-bootstrap';
import axios from '../../backendClient'; 
import { loginSuccess, logout } from '../../redux/userSlice';
import Customautocomplete from '../event/Customautocomplete';

//import Warning from './Warning'; // Assuming you have this component somewhere
import './settings.scss'; 

const Settings = () => {

    // Example state values
    const [username, setUsername] = useState("");
    const [phone, setPhone] = useState(""); 
    const [email, setEmail] = useState("");
    const [city, setCity] = useState(""); 
    const [hiddenMode, setHiddenMode] = useState(false);
   
    const [image, setImage] = useState(null); 
    const [isLoading, setIsLoading] = useState(false); 

    const [confirm, setConfirm] = useState(false); 
    const {currentUser} = useSelector(state => state.user);

    const [notificationFrequency, setNotificationFrequency] = useState(currentUser.notificationFrequency);
    const [preview, setPreview] = useState(null);
    const [code, setCode] = useState(''); 
    const fileInputRef = useRef();
    const IMG_SIZE = 2 * 2048 * 2048; 
    const dispatch = useDispatch(); 

    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/') && file.size < IMG_SIZE) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
                setImage(file); 
            };
            reader.readAsDataURL(file);
        } else {
            alert('Img cannot be uploaded'); 
            setPreview(null);
        }
    };

    // Handle form submission
    const handleSubmit = async (event) => {
      event.preventDefault();
      // Implementation for form submission
      const formData = new FormData();
      if (preview) {
          formData.append('profilePic', image);
          setIsLoading(true); 
          try {
             await axios.post('/api/users/updateProfilePic', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            
            // Handle the response...
        } catch (error) {
            alert(error);
            console.log(error); 
        }
      }


        try {
            setIsLoading(true); 
            const res = await axios.post('/api/users/updateProfile', {'userName': username, 'email': email, 'phone': phone, 'city': city, 'isHidden': hiddenMode, 'notificationFrequency': notificationFrequency});
    
            setIsLoading(false); 
            dispatch(loginSuccess(res.data)); 
         
        } catch (error) {
            alert(error); 
      }

  
    };



    const deleteAccount = async() => {
        setConfirm(true); 
        //send deleteCode via SMS 
        await axios('/api/users/sendDeleteCode'); 
    }

    const handleCodeSubmit = async(event) => {
        event.preventDefault(); 
       // alert('Code Confirmed');
        //call deleteUser endpoint and cancel subscriptions **TO DO ** CALL DELETE on Stripe endpoint**
        try{
            const res = await axios.post('/api/users/verifyDeleteCode', {'code': code});
            if(res.data){
              alert('Account deleted.'); 
              dispatch(logout());
              //logout user and set isDeleted to true
            }
            
        }
        catch(e){
            alert(e.response.data.message); 
        }
        //do logout 
        
    }
  
    return (
      <Container className="my-4">
        <Row>
          <Col md={6} className="mx-auto">
          <Card>
                <Card.Body>
                    <Card.Title>Account Settings</Card.Title>
                    <Card.Text>Update your account settings or change your location if you move. Contact support to change your phone number. </Card.Text>
                    <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Profile Picture</Form.Label>
                <div className="d-flex align-items-center">
                  <img
                    src={preview || currentUser.profilePic} // Replace with your image URL
                    alt="Profile"
                    className="rounded-circle me-2"
                    style={{objectFit:'cover', borderRadius:'50%', width: 80, height: 80}} // Adjust size as needed
                  />
                   <input type="file" id="image-upload" ref={fileInputRef}  hidden accept="image/*" onChange={handleFileChange} />
                  <Button variant="outline-primary" onClick={handleFileInputClick}>Change</Button>
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Update username" defaultValue={currentUser.userName} onChange={(e)=>setUsername(e.target.value)}/>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="text" placeholder="Update phone" defaultValue={currentUser.phone} onChange={(e)=>setPhone(e.target.value)}/>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter email" defaultValue={currentUser.email} onChange={(e)=>setEmail(e.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <Customautocomplete onSelect={setCity} searchType="city"></Customautocomplete>
                {/* <Form.Control type="city" placeholder="Enter Location" defaultValue={currentUser.city} onChange={(e)=>setCity(e.target.value)} /> */}
              </Form.Group>
              <Form.Group controlId="hiddenModeSwitch" className="mb-3">
              <Form.Check 
                type="switch"
                label="Browse in Hidden Mode"
                checked={hiddenMode}
                onChange={(e) => setHiddenMode(e.target.checked)}
              />
                <Form.Text className="text-muted">
    Toggling this switch will enable hidden mode, which allows you to browse the site without your profile being visible in search.
  </Form.Text>
            </Form.Group>
            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as="legend" column sm={2}>
                  SMS Notifications
                </Form.Label>
                <Col sm={10}>
                  <Form.Check 
                    type="radio"
                    label="Infrequently based on event"
                    name="notificationFrequency"
                    value="infrequent"
                    checked={notificationFrequency === 'infrequent'}
                    onChange={() => setNotificationFrequency('infrequent')}
                    id="frequencyEventBased"
                  />
                  <Form.Check 
                    type="radio"
                    label="Once per day (summary of any new notifications)"
                    name="notificationFrequency"
                    value="bulk"
                    checked={notificationFrequency === 'bulk'}
                    onChange={() => setNotificationFrequency('bulk')}
                    id="frequencyDaily"
                  />
                  <Form.Check 
                    type="radio"
                    label="Upon new notification"
                    name="notificationFrequency"
                    value="frequent"
                    checked={notificationFrequency === 'frequent'}
                    onChange={() => setNotificationFrequency('frequent')}
                    id="frequencyImmediate"
                  />
                  <Form.Check 
                    type="radio"
                    label="Never"
                    name="notificationFrequency"
                    value="never"
                    checked={notificationFrequency === 'never'}
                    onChange={() => setNotificationFrequency('never')}
                    id="frequencyNever"
                  />
                </Col>
              </Form.Group>
            </fieldset>
              <Button className="mt-3 w-100" variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? (
        <>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
            <span className="ms-2">Updating...</span>
        </>
    ) : (
        'Update Profile'
    )}
              </Button>
            </Form>
                </Card.Body>
            </Card>
   
          </Col>
        </Row>
        <Row>
        <Col md={6} className="mx-auto my-4">
            <Card>
                <Card.Body>
                    <Card.Title>Billing and Plan</Card.Title>
                    <Card.Text>Upgrade or manage your membership for more flexibility. </Card.Text>
                    <Link to="/billing">
                        <Button variant="primary" className="w-100">
                            Manage Plan / Upgrade Membership
                        </Button>
                    </Link> 
                </Card.Body>
            </Card>
        </Col>
        </Row>
        <Row>
            <Col md={6} className="mx-auto mt-3">
                    <Card>
                        <Card.Body>
                            <Card.Title>Delete Account </Card.Title>
                            <Alert variant="warning">
                                Deleting account cannot be undone {currentUser.userName}! You should confirm the SMS code sent to your phone to delete your account.
                                This will cancel your subscriptions if any and close all events you are hosting. 
                            </Alert>
                                {!confirm ? <Button variant="danger" onClick={deleteAccount} className="mb-3 w-100">
                                                Delete Account
                                            </Button> :    
                                            <Form onSubmit={handleCodeSubmit}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="form-label">SMS Code</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={code}
                                                            onChange={e => setCode(e.target.value)}
                                                            placeholder="Enter your code"
                                                        />
                                                        <Form.Text className="text-muted">
                                                            Enter your SMS Code to Confirm Account Deletion 
                                                        </Form.Text>
                                                    </Form.Group>
                                                    <Button variant="danger" type="submit" className="w-100">
                                                        Verify Deleting Account
                                                    </Button>
                                            </Form>}
                        </Card.Body>
                    </Card>

            </Col>
        </Row>
      </Container>
    );
  }
  

export default Settings;