import React, {useState, useEffect, useContext} from 'react';
import { Col, Container, Row, Card, Button, Modal, Dropdown, Image, Alert, Badge, Form} from 'react-bootstrap';
import './search.scss';
import { people } from './searchdata';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector} from 'react-redux';
//import axios from 'axios';
import axios from '../../backendClient'
import { useNavigate, useLocation } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import Paidmodal from '../../components/Paidmodal';
import LazyLoad from 'react-lazyload';


const StatusModal = ({ show, handleClose, events, selectedPersonId, host, selectedPerson }) => {
  const [selectedEvent, setSelectedEvent] = useState(events[0]);
  const [invitedEvent, setInvitedEvent] = useState('');
  const [inviteButton, setInviteButton] = useState(false); 
  const [showModal, setShowModal] = useState(false);
  const socket = useContext(AppContext); 
  const navigate = useNavigate(); 

  const handleNavigate = () => {
    navigate('/checkout'); 
  }

  const inviteGuest = async(selectedPersonId, eventId, currentUser) =>{
    try{
    const response = await axios.put(`/api/events/changeGuestStatus`,{guestId: selectedPersonId, eventId: eventId, inviteStatus: 'invited'})

    socket.emit("sendNotification",{
      sender: currentUser,
      receiver: selectedPersonId, 
      type: 3
     });
    }
     catch(e){
      //alert(e.response.data.status); 
      if(e.response.data.status === 404){
        handleClose(); 
        setShowModal(true); 
      }

      if(e.response.data.status === 429){
        handleClose(); 
        alert(e.response.data.message); 
      }
      
     } 
     try{

      await axios.post('/api/messages/addOfflineNotification',{sender: currentUser, offlineUserId: selectedPersonId,  notificationType: 3, notificationMessage: null });

    }catch(e){
      console.log('Failed to set unread notification ', e);
    }
  }
  
  const handleSelect = (eventSelected) => {
    setSelectedEvent(events.find(event => event.eventTitle === eventSelected));
    setInviteButton(false); 
  };

  const handleInvite = (event) => {
    if(event){
      setInvitedEvent(event);
      //use effect cahnge status of event to Invited with guestId
      //if error in try catch set modal for premium to show
      inviteGuest(selectedPersonId, event._id, host);
      
      setInviteButton(true); 
    }

  }

  const closeAndClear = () =>{
    handleClose(); 
    setInvitedEvent('');
    setSelectedEvent('');
    setInviteButton(false); 
  }
  return (
    <>
    <Paidmodal
    show={showModal}
    handleClose={() => setShowModal(false)}
    title="Upgrade to Premium for More Invites"
    footerText={`${selectedPerson.userName} would love to join you at your event! Subscribe to make it happen.`}
    imgSrc={`${host.profilePic}`}
    imgSrc2={`${selectedPerson.profilePic}`}
    onNavigate={handleNavigate}
    children="Or come back next month for more invites. "
    />
    <Modal.Dialog scrollable = {true}>
    <Modal show={show} onHide={closeAndClear} dialogClassName="modal-gradient" centered>
      <Modal.Header closeButton>
        <Modal.Title>{invitedEvent ? 'Sent Invite for ' +invitedEvent.eventTitle: 'No Invites Sent'}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-end">
        <Dropdown onSelect={handleSelect} style={{width: '100%'}}>
          <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{width: '100%'}}>
           {<>{selectedEvent?<Image src={selectedEvent.imgEvent} style={{width: 30, height:30, marginRight:10, objectFit:'cover', borderRadius:'50%'}}/>: ''} {selectedEvent?.eventTitle} </> || 'No Events Yet'}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-overflow" style={{width: '100%'}}>
          {events.map((event, index) => (
            <Dropdown.Item key={index} eventKey={event.eventTitle} style={{marginBottom:'10px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <span>
                  <Image src={event.imgEvent} style={{width: 30, height:30, marginRight:10, objectFit:'cover', borderRadius:'50%'}}/>
                    {event.eventTitle}
                  </span>
                  <span>{event.guests.filter(guest => guest.user._id === selectedPersonId).status}</span>
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={()=>{handleClose(); setSelectedEvent('');setInvitedEvent(''); setInviteButton(false);}}>
          Close
        </Button>
        <Button variant="success" disabled={inviteButton} onClick={()=>{handleInvite(selectedEvent);}} style={{marginLeft: 'auto'}}>
          Invite
        </Button>
      </Modal.Footer>
    </Modal>
    </Modal.Dialog>
    </>
  );
};

const Search = () => {


   
    const navigate = useNavigate(); 
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
     // This will hold whether there's more data to load
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(true);
    const [loading, setLoading] = useState(false); 

    // set the guest 
    const [selectedPersonId, setSelectedPersonId] = useState(null);
    const [selectedPerson, setSelectedPerson] = useState({userName: 'Unknown', profilePic: "https://d1873pk1gxetu0.cloudfront.net/events/It's+time+to+break-2.svg"}); 

    const [events, setEvents] = useState([]);

    const {currentUser} = useSelector(state => state.user);

    const [modalShow, setModalShow] = useState(false);

    const [pageFlip, setPageFlip] = useState(true); 
    const socket = useContext(AppContext); 
    const location = useLocation(); 
    const query = new URLSearchParams(location.search); 
    const city = query.get('city'); 
    const [networkingMode, setNetworkingMode] = useState(false); 

    const { triggerEffect } = location.state || {};
    
   
    // filter out events the guestId by invited, attending, declined and just include Img Event in the use effect below and have events, setEvents 
  
    const getInviteEvents = async (guestId) => {
      const response = await axios.get(`/api/events/getInviteEvents?guestId=${guestId}`);
      setEvents(response.data); 
    
    }

    useEffect(() => {
    
      const handleLoad = () => {
        const savedScrollPosition = sessionStorage.getItem('scrollPosition');
        if (savedScrollPosition) {
          setTimeout(() => {
            window.scrollTo(0, parseInt(savedScrollPosition, 10));
            sessionStorage.removeItem('scrollPosition');
          }, 700)
        }

      };

      handleLoad(); 
    
      window.addEventListener('load', handleLoad);
      

      return () => window.removeEventListener('load', handleLoad);
    }, [triggerEffect]);



      // This function will be called when the user scrolls down
    const fetchMoreData = async () => {
        //compare fetchedData(people) to data
        //continue to request more and setFetchedData
        //otherwise do the check below and the db will have been 
        //exhausted
      if(loading){
        return; 
      }

      setLoading(true); 

      const endpoint = networkingMode ? `/api/users/getAllMatches?page=${page}&&city=${city}` : `/api/users/getMatches?page=${page}&&city=${city}`; 
      try{
        const response = await axios.get(endpoint);
        const newData = response.data; 
        if (newData.length === 0) {
            setHasMore(false);
            return;
        }else{
          setData(prevData => [...prevData, ...newData ]);
          setPage(prevPage => prevPage +1);
        }
      }
      catch(e){
        setError(e.response.data.message);
      } finally{
        setLoading(false); 
      }
    };

    useEffect(() => {

      setPage(1);
      setData([]);

    }, []);

    useEffect(() => {

      setPage(1);
      setData([]);
      setPageFlip(!pageFlip); 

    }, [city]);

    // Initial data load
    useEffect(() => { 
        fetchMoreData(); 
      
        const checkActiveInvite = async () => {
          const response = await axios.get(`/api/events/hasActiveInvites`,{
            params: {
              phone: currentUser.phone,
              id: currentUser._id}
            });
          if(response.data.length !== 0){
            socket.emit("sendNotification",{
              sender: currentUser,
              receiver: currentUser._id, 
              type: 6
             }); 
           }
        }

      checkActiveInvite(); 

              
    }, [pageFlip, networkingMode]);

 
    const handleCardClick = (id) => {
      sessionStorage.setItem('scrollPosition', window.scrollY);
      navigate(`/profile/${id}`);
    }

    useEffect(() => {
      if (selectedPersonId !== null) {
        getInviteEvents(selectedPersonId);
      }
    }, [selectedPersonId]);
    

    //We pass in the id of the current person use currentUser._id for host id 
    const handleButtonClick = (event, id, currentUser, selectedPerson) => {
      event.stopPropagation(); // This prevents the card's click handler from being called
      // Handle the button click here
      setSelectedPersonId(id);
      //getInviteEvents(selectedPersonId); 
      //we will set the events to pass in with a useEffect populate Events but filter out by invited, attending, and declined status
      setSelectedPerson(selectedPerson); 
      setModalShow(true);
    }
    
   
  return (
    <Container className='maincontainer py-5'>
    <img src='//servedby.studads.com/ads/tracker.php?t=Mjc3OzE5NzIz'/>
    <Row>
      {error && showError && <Alert variant="danger" onClose={() => {setShowError(false);}} dismissible>{error}</Alert>}
    </Row>
    <Row>
        <Col xs={12}>
            <Form className='d-flex justify-content-start'>
                <Form.Check 
                    className='m-1'
                    type="switch"
                    id="custom-switch"
                    label={currentUser.userType==='Business'? 'Include Businesses in Results' : 'Include People in Results'}
                    checked={networkingMode}
                    onChange={() => {setNetworkingMode(!networkingMode); setPage(1); setData([]); setHasMore(true);}}
                    />
            </Form>
        </Col>
    </Row>
        <h1 className='text-center'>Search Results {city? `for ${city} `:``}</h1>
        <InfiniteScroll
        dataLength={data.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={ data.length > 0 && 
          <p style={{textAlign: 'center'}}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      > 

        <Row className='row-cols-1 row-cols-md-3 g-4 py-5'>


        {data.length === 0 && !hasMore ? (
            <Col className='d-flex justify-content-center align-items-center' style={{minHeight: '50vh'}}>
                <h4>Looks like no one has joined in your area!</h4>
            </Col>):
         (data.map((person, i) => (
             <Col xs={12} sm={6} md={4} key={i}>
                <Card onClick = {()=> handleCardClick(person._id)}>
                  <LazyLoad height={375} offset={100} once>
                    <Card.Img className="img-top" src={person.profilePic} />
                  </LazyLoad>
                    <Card.Body className="cardBody">
                    <Card.Title>{person.userName}</Card.Title>
                    <Card.Text className='px-0'>{person.city}</Card.Text>
                    </Card.Body>
                    <div className="d-flex justify-content-between mb-5 p-4">
                    <h6>Trust Score: {person.trustScore}%</h6>
                    <Button variant="primary" onClick ={(event)=> handleButtonClick(event, person._id, currentUser, person) } >Invite</Button>
                    </div>
                    <div className="d-flex justify-content-between mb-5 p-4">
                    {person.membershipType === "premium" ? <Badge bg="warning" text="dark">Premium</Badge> : <Badge bg="danger" text="light">Basic</Badge>}
                    </div>
                </Card> 
            </Col>
        ))
        )}
      
    </Row>
    </InfiniteScroll> 
    <StatusModal
        show={modalShow}
        events={events}
        selectedPersonId = {selectedPersonId}
        selectedPerson = {selectedPerson}
        host = {currentUser}
        handleClose={() => setModalShow(false)}
      />
  </Container>
  )
}

export default Search
