import React from "react";
import { Link } from "react-router-dom";

const SecondaryButton = ({ ctaText, link, icon }) => {
  return (
    <Link
      className="back_btn d-flex align-items-center text-decoration-none text-blue fw-medium br_10 mb-3 mb-md-4 font-sm"
      to={link}>
      <span>{icon}</span>
      {ctaText}
    </Link>
  );
};

export default SecondaryButton;