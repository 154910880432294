import React from "react";
import { Col,Row, Container } from "react-bootstrap";


const User = () => {
    const userImg = "https://incentiveweb.s3.us-east-1.amazonaws.com/profile/1690268955549-1688628400991-1688628400991-istockphoto-1174712683-612x612.jpg"
    const userTableData = [
        {
          offer: "Your Offer Will Be Displayed Here",
          name: "Your Customer",
          image: userImg,
          review:
            "Your reviews will be visible here.",
          impactValue: "+/- 0",
          status: "Pending",
        },
        // {
        //   offer: "Sandwich",
        //   name: "Jamie",
        //   image: userImg,
        //   review:
        //     "Odio volutpat massa justo tincidunt. Commodo sed pulvinar dolor nulla. Cursus neque diam pulvinar massa sollicitudin. Laoreet leo.",
        //   impactValue: "+/-5",
        //   status: "Done",
        // },
        // {
        //   offer: "Sandwich",
        //   name: "Jamie",
        //   image: userImg,
        //   review:
        //     "Odio volutpat massa justo tincidunt. Commodo sed pulvinar dolor nulla. Cursus neque diam pulvinar massa sollicitudin. Laoreet leo.",
        //   impactValue: "+/-5",
        //   status: "Done",
        // },
        // {
        //   offer: "Sandwich",
        //   name: "Jamie",
        //   image: userImg,
        //   review:
        //     "Odio volutpat massa justo tincidunt. Commodo sed pulvinar dolor nulla. Cursus neque diam pulvinar massa sollicitudin. Laoreet leo.",
        //   impactValue: "+/-5",
        //   status: "Done",
        // },
        // {
        //   offer: "Sandwich",
        //   name: "Jamie",
        //   image: userImg,
        //   review:
        //     "Odio volutpat massa justo tincidunt. Commodo sed pulvinar dolor nulla. Cursus neque diam pulvinar massa sollicitudin. Laoreet leo.",
        //   impactValue: "+/-5",
        //   status: "Done",
        // },
        // {
        //   offer: "Sandwich",
        //   name: "Jamie",
        //   image: userImg,
        //   review:
        //     "Odio volutpat massa justo tincidunt. Commodo sed pulvinar dolor nulla. Cursus neque diam pulvinar massa sollicitudin. Laoreet leo.",
        //   impactValue: "+/-5",
        //   status: "Done",
        // },
        // {
        //   offer: "Sandwich",
        //   name: "Jamie",
        //   image: userImg,
        //   review:
        //     "Odio volutpat massa justo tincidunt. Commodo sed pulvinar dolor nulla. Cursus neque diam pulvinar massa sollicitudin. Laoreet leo.",
        //   impactValue: "+/-5",
        //   status: "Done",
        // },
        // {
        //   offer: "Sandwich",
        //   name: "Jamie",
        //   image: userImg,
        //   review:
        //     "Odio volutpat massa justo tincidunt. Commodo sed pulvinar dolor nulla. Cursus neque diam pulvinar massa sollicitudin. Laoreet leo.",
        //   impactValue: "+/-5",
        //   status: "Done",
        // },
      ];

    return (
        <>
        <Container>
          <Row className="mb-2"><Col className="px-3">Reviews</Col></Row>
        </Container>   
      <div
        className="mx-auto user_table"
        style={{ maxWidth: "1360px", paddingLeft: "20px", paddingRight: "20px" }}>
        <div className="bg-white p-3 p-lg-4 br_10">
          <div className="d-none d-md-flex gap-3 gap-xl-5 mb-3 px-3 px-xl-4">
            <div className="w_11">
              <p className="font-md text-gray mb-0">Offer</p>
            </div>
            <div className="w-25 ps-lg-2 ps-xl-4">
              <p className="font-md text-gray mb-0 text-nowrap">Customer Name</p>
            </div>
            <div className="w-75 ps-xl-3">
              <p className="font-md text-gray mb-0">Review Text </p>
            </div>
            <div className="w_11">
              <p className="font-md text-gray mb-0">Impact</p>
            </div>
            <div className="w_20">
              <p className="font-md text-gray mb-0">Status</p>
            </div>
          </div>
          <div className="user_table_parent d-none d-md-block">
            {userTableData.map((obj, index) => {
              return (
                <div
                  key={index}
                  className={`d-flex align-items-center gap-3 gap-xl-5 mb-1 px-3 px-xl-4 py-1 br_10 ${
                    index % 2 === 0 ? "bg-darkgray" : "bg-lightgray"
                  }`}>
                  <div className="w_11 ">
                    <p className="font-sm text-black fw-bolder mb-0">
                      {obj.offer}
                    </p>
                  </div>
                  <div className="w-25 ps-lg-2 ps-xl-4 d-flex gap-1 gap-lg-3 align-items-center pe-xl-3">
                    <img
                      className="user_img"
                      src={obj.image}
                      alt="customer-img"
                    />
                    <p className="font-sm text-black fw-medium mb-0">
                      {obj.name}
                    </p>
                  </div>
                  <div className="w-75 ps-xl-3">
                    <p
                      className="mb-0 font-sm text-gray fw-normal"
                      style={{ lineHeight: "150%" }}>
                      {obj.review}
                    </p>
                  </div>
                  <div className="w_11">
                    <p className="mb-0 font-sm text-black fw-bolder">
                      {obj.impactValue}
                    </p>
                  </div>
                  <div className="w_20">
                    <p className="mb-0 font-sm text-green fw-semibold">
                      {obj.status}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row d-md-none">
            {userTableData.map((obj, index) => {
              return (
                <div key={index} className="col-sm-6">
                  <div
                    className={`p-3 br_10 mb-1 ${
                      index % 2 === 0 ? "bg-darkgray" : "bg-lightgray"
                    }`}>
                    <div className="d-flex justify-content-between pb-2 user_mobile_border">
                      <p className="font-sm text-gray mb-0">Offer</p>
                      <p className="mb-0 font-sm fw-bolder text-black">
                        {obj.offer}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between pb-2 user_mobile_border pt-2">
                      <p className="font-sm text-gray mb-0">Customer Name</p>
                      <div className="d-flex gap-2">
                        <img className="user_img" src={userImg} alt="userImg" />
                        <p className="mb-0 font-sm fw-bolder text-black">
                          {obj.name}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between pb-2 user_mobile_border pt-2 gap-sm-2">
                      <p className="font-sm text-gray w-75 mb-0 text-nowrap">
                        Review Text
                      </p>
                      <p className="w_sm_50 mb-0 font-sm text-gray text-end">
                        {obj.review}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between py-2 user_mobile_border">
                      <p className="font-sm text-gray mb-0">Impact</p>
                      <p className="mb-0 font-sm fw-bolder text-black">
                        {obj.impactValue}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between pt-2">
                      <p className="font-sm text-gray mb-0">Status</p>
                      <p className="mb-0 font-sm fw-bolder text-green">
                        {obj.status}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      </>
    );
  };
  
  export default User;
  