import React, { useEffect, useState, useRef} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'; 
import Spinner from 'react-bootstrap/Spinner';
import FormControl  from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './newevent.scss';
import Customautocomplete from './Customautocomplete';
import Sharemodal from './Sharemodal';
//import axios from 'axios';
import axios from '../../backendClient';
import Container  from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import nodata from '../../assets/nodata.svg';
import LazyLoad from 'react-lazyload';
import Barcode from 'react-barcode'; 
import { useNavigate } from 'react-router-dom';



const images = [
 "https://d1873pk1gxetu0.cloudfront.net/events/It's+time+to+break-2.svg",
 "https://d1873pk1gxetu0.cloudfront.net/events/It's+time+to+break.svg",
 "https://d1873pk1gxetu0.cloudfront.net/events/It's+time+to+break-3.svg",
 "https://d1873pk1gxetu0.cloudfront.net/events/It's+time+to+break-4.svg",
 "https://d1873pk1gxetu0.cloudfront.net/events/It's+time+to+break-5.svg",
 "https://d1873pk1gxetu0.cloudfront.net/events/It's+time+to+break-6.svg",
//  "https://d1873pk1gxetu0.cloudfront.net/events/It's+time+to+break-7.svg",
 "https://d1873pk1gxetu0.cloudfront.net/events/It's+time+to+break-8.svg",
 "https://d1873pk1gxetu0.cloudfront.net/events/It's+time+to+break-9.svg",]
//  "https://d1873pk1gxetu0.cloudfront.net/events/It's+time+to+break-10.svg"]

 const ImageCheckbox = ({ index, src}) => {
  return (
    <div className="image-checkbox">
      <img src={src} alt={`carousel ${index}`}/>
      {/* <div className="image-overlay">
        <input
          type="checkbox"
          checked={selected}
        />
      </div> */}
    </div>
  );
};


const Newevent = () => {

    const [show, setShow] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [showBarcode, setShowBarcode] = useState(false); 
    const [image,setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [noImg, setNoImg] = useState('https://d1873pk1gxetu0.cloudfront.net/events/party.svg');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [showingModalCode, setShowingModalCode] = useState(null);
    const {currentUser, error} = useSelector(state => state.user);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowBarcode = () => {
      setShowBarcode(true); 
    }

    const [isSubmitting, setIsSubmitting] = useState(false); 

    const [activeTab, setActiveTab] = useState('active'); 


    const [events, setEvents] = useState([]);
    const [reviews, setReviews] = useState([]); 
    //const [reviewValue, setReviewValue] = useState(''); 
    const [reviewValue, setReviewValue] = useState({}); 
    const [previewLoading, setPreviewLoading] = useState(false); 

    const [minDate, setMinDate] = useState(''); 
    const [maxDate, setMaxDate] = useState(''); 
    const navigate = useNavigate(); 

    useEffect(()=> {
        const today = new Date(); 
        const todayFormatted = today.toISOString().split('T')[0]; 
        //Note: Specify the next month but with (year, month, 0) get the previous day before the 1st of the next month 
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() +1, 0).toISOString().split('T')[0]; 

        setMinDate(todayFormatted); 
        setMaxDate(lastDayOfMonth); 
    },[])


    const [visibleCard, setVisibleCard] = useState(null); 

    const toggleCardVisible = (id) =>{
      setVisibleCard(visibleCard === id ? null: id); 
    }

    const handleShareClose = () => {
      //setShowShare(false);
       setShowingModalCode(null);
    }
    
    
    const handleShowShare = (inviteCode) => {
      //setShowShare(true);
      setShowingModalCode(inviteCode);
    }

    const handleReviewButtonClick = async (eventId, guest, value) => {
        
       await axios.post(`/api/events/reviewGuest`, {eventId: eventId, reviewedUser: guest, reviewedText: value}); 
       setReviewValue(''); 

        setReviews(reviews.map(event => {
          if (event.eventId._id.toString() === eventId) {
              return {
                  ...event,
                  reviews: event.reviews.filter((_, index) => index !== 0)
              };
          }
          return event;
      }));

    }





    const [myevent, setMyevent] = useState({
      eventTitle: '',
      eventDescription: '',
      location:'',
      place: '', 
      eventStartDate: new Date().toISOString().split('T')[0], 
      eventDate: new Date().toISOString().split('T')[0],
      eventTime: new Date().toTimeString().split(' ')[0].substring(0,5),
      isShareable: false,
      isPaid: false,
      isRetailValue: false, 
      isOnlineCode: false, 
      eventCost: '',
      offerValue: '',
      onlineCode: '',
      attendees: 'Max',
      isLocationHidden: false, 
      isHidden: false,
      imgCover: 'https://d1873pk1gxetu0.cloudfront.net/events/party.svg',
      hostUser: currentUser._id,
      couponCode: '',
      forwardUrl: ''
    });


    const formRef = useRef(); 
    const IMG_SIZE = 2 * 1024 * 1024; 

    const isUrl = (string) =>{
      try{
        new URL(string);
        return true; 
      }catch(e){
        console.error(e);
        return false;
      }
    }

    //useEffect to check wha thee image is logged as 
    useEffect( ()=>{
      const fetchEvents = async() => {
          const res = await axios('/api/events/getMyEvents');
          setEvents(res.data); 
      }
      fetchEvents();
      
    },[formSubmitted]);


    //setEvents or Review 
    useEffect(async () => {
      const endpoint = activeTab === 'active' ? '/api/events/getMyEvents' : '/api/events/getUnreviewed';
      const fetchData = async() => {
        try{
        const res = await axios.get(endpoint); 
        if(activeTab==='active'){
          setEvents(res.data); 
        }else{
          setReviews(res.data); 
        }
        
        }
        catch(e){
          console.log('Error fetching ', e); 
        }
      }

      fetchData(); 
    }, [activeTab]);
 
  
    useEffect( async() => {
      const storeImage = () =>{
        setMyevent({...myevent, imgCover: image}); 
      }

      storeImage(); 

    }, [image])


    const handleInputChange = (e) => {
      setMyevent({
          ...myevent,
          [e.target.name]: e.target.value
      });
    };


    // const storeImage = () =>{
    //   setMyevent({...myevent, imgCover: image}); 
    // }

    const handleImageSelect = async (filePath) => {
      if(isUrl(filePath)){
        setImage(filePath);
        setImagePreview(filePath);
        //setMyevent({...myevent, imgCover:filePath});
      }else{
        setNoImg('https://d1873pk1gxetu0.cloudfront.net/events/party.svg');
      }
    
    };

    const generateAI = async(e) => {
      
      if(!myevent.eventTitle || !myevent.eventDescription){
        return alert('You must enter an offer Title and Description'); 
      }
      setPreviewLoading(true); 
      setIsSubmitting(true); 
      const res = await axios.post('/api/events/createOpenImage', {title: myevent.eventTitle, description: myevent.eventDescription }, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
      setIsSubmitting(false); 
      setPreviewLoading(false); 
      //handleImageSelect(noImg); 
     await handleImageSelect(res.data.data[0].url); 

    return; 


    }

    //We use dynamic setting in javascript to create eventId key for setReviewValue and then post that 
    const handleReviewChange = (e, eventId) => {
      setReviewValue((prevValue) => ({...prevValue, [eventId]: e.target.value})); 
    }

  
    const handleFileChange = async (e) => {
      // Implement your file handling logic here
      const file = e.target.files[0];

      if(file.size > IMG_SIZE){
        return alert('Max file size is 2 MB');
      }else{
        setImage(file); 
        setImagePreview(URL.createObjectURL(file));  
        setMyevent({...myevent,imgCover:file});
      }
      //console.log(e.target.files[0]);
    };

    const handleEventChat = () => {
      navigate('/chat');
    }
    
    const goEvent = (id) => {
      navigate(`/single/${id}`) 
    }



    const handleSubmit = async (e) => {
      e.preventDefault(); 
      
      //do checks on missing fields and show alerts until resolved return alert 
      //if(!myevent.location) return alert('Promotion Address/City must be selected from dropdown!'); 

      if(isSubmitting) return; 
      setIsSubmitting(true); 
      // Simply post object if the imageCover is a url else do formdata 
      if(isUrl(myevent.imgCover)){
        try {
          // Make a POST request to the server-side route that handles event create
          // if it's a URL on the img side just save the event object direclty without S3 upload of img
          // The use effect should update the array on the front end
          // Have a view guest list button from the card and a cancel event button on the card
          const res = await axios.post('/api/events/createEvent', myevent, {
              headers: {
                  'Content-Type': 'application/json'
              }
          });
  
          if(res.data){
            // We can update the eventsArray once we save it to the DB and this should update the useEffect list on the page
            //Useeffect should update immediatley without any call 
             //console.log(events);
             //setEvents(events);
          }
        } catch(err) {
          console.error(err);
          if(err.response.data.status === 403){
            alert(err.response.data.message); 
            navigate('/checkout');
            
             
          }
        }finally{
          setIsSubmitting(false); 
        }
      }else{
      
        // if the myevent is a custom Uploaded img by user then use the form data and pass it in 
        const formData = new FormData();
        formData.append('eventTitle', myevent.eventTitle);
        formData.append('eventDescription', myevent.eventDescription);
        formData.append('location', myevent.location);
        formData.append('eventStartDate', myevent.eventStartDate); 
        formData.append('eventDate', myevent.eventDate);
        formData.append('eventTime', myevent.eventTime);
        formData.append('isShareable', myevent.isShareable);
        formData.append('isPaid', myevent.isPaid);
        formData.append('isRetailValue', myevent.isRetailValue); 
        formData.append('attendees', myevent.attendees);
        formData.append('isLocationHidden',myevent.isLocationHidden); 
        formData.append('isHidden', myevent.isHidden);
        formData.append('hostUser', myevent.hostUser);
        formData.append('place', myevent.place);
        formData.append('couponCode', myevent.couponCode);
        formData.append('isOnlineCode', myevent.isOnlineCode);
        formData.append('forwardUrl', myevent.forwardUrl);   
        formData.append('forwardCTA', myevent.forwardCTA);   
        
        
        if (myevent.isRetailValue === 'on' ) {
          formData.append('offerValue', myevent.offerValue);
        }

           
        if (myevent.isOnlineCode === 'on') {
          formData.append('onlineCode', myevent.onlineCode);
        }
      
        if (myevent.imgCover) {
          formData.append('imageEvent', myevent.imgCover);
        }
         try{
          const res = await axios.post('/api/events/createEvent', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
          }catch(err){
            console.log(err); 
            if(err.response.data.status === 403){
              alert(err.response.data.message); 
              navigate('/checkout')
            }
          }finally{
            setIsSubmitting(false); 
          }
        }
      setFormSubmitted(!formSubmitted);
      handleClose(); 
    }

  
  return (
    <>
    <Container>
      <Row className="justify-content-end">
        <Col xs='auto'>
        <Button onClick={handleShow} className="px-2 py-1 btn btn-outline-purple rounded-full m-3">
          + Create Offer
        </Button>
        </Col>
      </Row>
     
      <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
      <Tab eventKey="active" title="Upcoming Offers">
      <Row className="cards-row mt-3">
        { events && events.length > 0 ? events.map((event,idx)=> 
                <Col xs={12} sm={6} md={4} key={idx} className='mb-3'>
                <Card>
                <LazyLoad height={375} offset={100} once>
                    <Card.Img className="img-top" src={event.imgEvent} /></LazyLoad>
                    <Card.Body className="cardBody">
                    <Button variant='link' onClick={() => toggleCardVisible(idx)}><Card.Title>{event.eventTitle}</Card.Title></Button>
                    {visibleCard === idx && (
                    <> 
                    <Card.Text> # Coupons (Left): {event.attendees === 'Max' ? 'Max' : event.attendees}</Card.Text>
                    <Card.Text>{event.eventDescription}</Card.Text>
                    <Card.Text>Promotion Start: {new Date(event.eventStartDate).toUTCString().split(" 00:00:00")[0]} </Card.Text>
                    <Card.Text>Promotion End: {new Date(event.eventDate).toUTCString().split(" 00:00:00")[0]}</Card.Text>
                    <Card.Text>{event.location}</Card.Text>
                    <Card.Text>Online Code: {event.onlineCode}</Card.Text>
                    {event.eventCost && <Card.Text>{event.eventCost?'Cost '+ event.eventCost: ''}</Card.Text>}

                    {event.couponCode? <div className={`d-flex ${!showBarcode? 'justify-content-start': 'justify-content-center'} ms-auto w-100`}>
                    {!showBarcode && <Button onClick={handleShowBarcode} variant="success">Redeem Offer</Button>}

                    {showBarcode && (
                      <div>
                        <Barcode value={event.couponCode}/>
                        </div>
                    )}
                    </div> : 'No Coupon Code Contact Business'} </> )}

                    {
                      event.hostUser._id !== currentUser._id ?<Button className={`common_btn hero_btn text-decoration-none text-center mt-2`} onClick={()=> handleEventChat()} variant="info">Join Chat</Button> : ''
                    } 
                    
                    </Card.Body>
                    <div onClick={()=> {handleEventChat()}} className="d-flex mb-5 p-2"> 
                   { event.hostUser._id === currentUser._id && event.guests.length > 0 && 
                    <> Customers({event.guests.length}):
                    <div className="d-flex mx-1"></div>
                     {event.guests.map((user,idx)=>(
                           <div key={user._id}>
                            <Image src={user.user.profilePic} style={{width: 30, height:30, marginLeft:-5, objectFit:'cover', borderRadius:'50%'}}/>
                           </div>
                             ))}

                   </>
                    }
                   
                    </div>
                    <div className="d-flex justify-content-between mb-5 p-4">
                    <div> 
                    
                    { event.hostUser && typeof event.hostUser==='object' ?
                      <>
                      <Image src={event.hostUser.profilePic} style={{width: 30, height:30, marginRight:10, objectFit:'cover', borderRadius:'50%'}}/>
                      {event.hostUser.userName}
                      </> : ''
                    }
                    </div>
                    <Button onClick={()=> handleShowShare(event.inviteCode)} variant="primary">Share Code</Button>
                    <Sharemodal show={showingModalCode === event.inviteCode} handleClose={handleShareClose} code={event.inviteCode || 'Not Available'} link={`www.incentivemeet.com/invitecode/${event.inviteCode}` || 'No Link'} publicUrl={`www.incentivemeet.com/single/${event.shortTitle}`}/>
                    </div>
                    <div className="d-flex mb-5 p-4">
                      <Button className={`common_btn hero_btn text-decoration-none text-center mt-2 w-100`} onClick={()=> goEvent(event.shortTitle)} variant="info">Go to Event Page</Button>
                    </div>
                </Card> 
            </Col>

        ): <Col className="d-flex align-items-center justify-content-center flex-direction-column" >
          <Image src={nodata} style={{height:250, objectFit:'cover', marginBottom: 15}}/>
          <h4>No offers yet...create an offer.</h4>
          </Col>}
          </Row>
      </Tab>
    

      <Tab eventKey="closed" title="Review Your Purchases">
        <Row className='cards-row mt-3'>
      { reviews && reviews.length > 0 ? reviews.map((event,idx)=> 
                <Col xs={12} sm={6} md={4} key={idx} className='mb-3'>
                <Card>
                    <Card.Img className="img-top" src={event?.eventId?.imgEvent} />
                    <Card.Body className="cardBody">
                    <Card.Title className='mb-3'>{event?.eventId?.eventTitle}</Card.Title>

                    
                    {
    event.reviews && event.reviews.length > 0 ? (
        <>
            <Form>
                <Form.Group  key={event._id}>
                    <Form.Control as="textarea" maxLength="250" placeholder="Enter your review in about 30-50 words max" rows={5} value={reviewValue[event.eventId._id]} onChange={(e) => handleReviewChange(e,event.eventId._id)} />
                    <div className="d-flex justify-content-between mb-5 p-4">
                        <Row>
                            <div>
                                <Image src={event.reviews[0].user?.profilePic} style={{ width: 30, height: 30, marginRight: 10, objectFit: 'cover', borderRadius: '50%' }} />
                                {event.reviews[0].user?.userName}
                            </div>

                            <div className='mt-3'>
                                <Button onClick={() => handleReviewButtonClick(event.eventId._id, event.reviews[0].user, reviewValue[event.eventId._id])} variant="primary">Review {event.reviews[0].user?.userName}</Button>          
                            </div> 
                        </Row>
                    </div>
                </Form.Group>
            </Form>
        </> 
    ) : (
        'Event Reviewed/No Attendees'
    )
}
      
                    </Card.Body>
         
                </Card> 
            </Col>

        ): <Col className="d-flex align-items-center justify-content-center flex-direction-column" >
          <Image src={nodata} style={{height:250, objectFit:'cover', marginBottom: 15}}/>
          <h4>No events yet...create an event.</h4>
          </Col>}
          </Row>
      </Tab>
    </Tabs>
    </Container>
    <Modal show={show} onHide={handleClose} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Add Promotional Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{ maxWidth: "500px" }} className="mx-auto">
          <Form ref={formRef} onSubmit={handleSubmit}>
            {/* Add actual form submit function above to POST event, Other form fields here */}
            <div className="mb-6">
            <label htmlFor="eventTitle" className="block text-gray-500 font-bold mb-1 pr-4">
                Offer Title
            </label>
            <br />
            <Form.Text className="text-muted">
                    Ex. Buy 1 Pizza Get 1 50% Off At Joe's Pizzeria
            </Form.Text>
            <input
                required
                className="form-control form-control-purple"
                name="eventTitle"
                type="text"
                value={myevent.eventTitle}
                onChange={handleInputChange}
            />
          </div>
          <div className='mb-6'>
          <label htmlFor="eventDescription" className="block text-gray-500 font-bold mb-1 pr-4 mt-3">
                  Offer Details
            </label>
          <Form.Group className="mb-3" controlId="desc">
                <Form.Control as="textarea"
                              required
                              type="text"
                              placeholder=""
                              name="eventDescription"
                              defaultValue={myevent.eventDescription}
                              onChange={handleInputChange}
                />
            </Form.Group>
          </div>
          <label htmlFor="address" className="block text-gray-500 font-bold mb-1 pr-4">Promotion Address/City</label>
          <Form.Group className="mb-3" controlId="address">
          <Customautocomplete className="custom-autocomplete-style" onSelect={(value, place)=>setMyevent({...myevent,location:value, place: place })} searchType="address"></Customautocomplete>
          </Form.Group>
          <div className='mb-3'>
            Location is: {myevent.location ? myevent.location: 'Enter Your Address or Location or Leave Blank for Online or Nationwide'}
          </div>
          <div className='create-event-pic_container mt-5'>
          {previewLoading && <div className='spinner'><Spinner animation="border" role="status">
              </Spinner></div>}
              <img src={imagePreview ? imagePreview:noImg} className='event-cover-pic' alt="" />
          </div>

          <Form.Group className='mt-3'>
              <Form.Label>Upload Event Cover Image Or Select From Below to Generate</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
              
          </Form.Group>

          <label className="mt-3 mb-1">Offer Image </label>
         
           

          <Button className='btn btn-purple mx-2 w-100 mb-3' onClick = {()=> {generateAI();}}> Generate Image from AI</Button>
          


            <Form.Group className='mb-3'>
              {/* <Form.Control hidden type="date" value={myevent.eventStartDate} onChange={(e)=> setMyevent({...myevent,eventStartDate:e.target.value,})} /> */}
              <Form.Label>Promo End Date (Note: Your Promo Start Will Be Today)</Form.Label>
              <br/>
              <Form.Text className="text-muted">
                Promotions can only be run for the current Calendar Month
              </Form.Text>
              <Form.Control required type="date" name="eventDate" value={myevent.eventDate} min={minDate} max={maxDate} onChange={handleInputChange} />
              {/* <Form.Control required type="date" value={myevent.eventDate} min={minDate} max={maxDate} onChange={(e)=> setMyevent({...myevent,eventDate:e.target.value})} /> */}
            </Form.Group>
            <Form.Group controlId="eventTime" className='mb-3'>
                  <Form.Label>End Time (Optional) </Form.Label>
                  <Form.Control required type="time" value={myevent.eventTime} onChange={(e) => setMyevent({...myevent,eventTime:e.target.value})}>
                  </Form.Control>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Check className='mb-2' type="switch" id="custom-switch" label="Online Code" checked={myevent.isOnlineCode} onChange={(e) => setMyevent({...myevent,isOnlineCode:e.target.checked})} />
              { myevent.isOnlineCode &&
                  <Form.Group controlId="eventCost" className='mt-2 mb-3'>
                              <label htmlFor="eventTitle" className="block text-gray-500 font-bold mb-1 pr-4">
                Online Only Code: 
            </label>
                      <Form.Control
                              type="text"
                              placeholder="Enter Any Online Only Code"
                              name="onlineCode"
                              value={myevent.onlineCode || ''}
                              onChange={handleInputChange}
                      />
                     
            </Form.Group> 
}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Check 
                type="switch" 
                id="custom-switch" 
                label="Price to Display on Landing Page" 
                checked={myevent.isRetailValue} 
                onChange={(e) => setMyevent({...myevent, isRetailValue: e.target.checked})} />
            </Form.Group>
              { myevent.isRetailValue &&
                  <Form.Group controlId="eventCost" className='mb-3'>
                      <Form.Label>Price</Form.Label>
                    
                    <InputGroup>
                      $
                      <Form.Control
                              type="text"
                              placeholder="Enter Price of Offer"
                              name="offerValue"
                              value={myevent.offerValue || ''}
                              onChange={handleInputChange}
                              />
                      </InputGroup>
            </Form.Group>
}

<div className="mb-6">
            <label htmlFor="eventTitle" className="block text-gray-500 font-bold mb-1 pr-4">
                Coupon Barcode:
            </label>
            <input
                required
                className="form-control form-control-purple mb-2"
                name="couponCode"
                type="text"
                value={myevent.couponCode}
                onChange={handleInputChange}
            />
          </div>

            <Form.Group className='mb-3'>
            <Form.Text className="text-muted">
            Note: Customers cannot redeem offer beyond # redemptions. Choose Max for max redemptions. 
            </Form.Text>
            <br />
              <Form.Label className='mt-2'># Coupon Redemptions: </Form.Label>
              <Form.Control as="select" value={myevent.attendees} onChange={(e) => 
                { 
                  const value = e.target.value === "Max" ? "Max" : parseInt(e.target.value);
                  setMyevent({...myevent,attendees:value});
                }}>
                {[...Array(20).keys()].map((number) =>
                  <option key={number + 1} value={number + 1}>{number + 1}</option>
                )}
                <option value="Max">Max</option>
              </Form.Control>
            </Form.Group>
            {/* <Form.Group className='mb-3'>
              <Form.Check type="switch" id="custom-switch-rsvp" label="Only Show Location Once Guests RSVP Attending?" checked={myevent.isLocationHidden} onChange={(e) => setMyevent({...myevent, isLocationHidden:e.target.checked})} />
            </Form.Group> */}
            <Form.Group className='mb-3'>
              <Form.Check type="switch" id="custom-switch-private" label="Hide Promo from Main Page (Offer is Invite Only)" checked={myevent.isHidden} onChange={(e) => setMyevent({...myevent, isHidden:e.target.checked})} />
            </Form.Group>
          </Form>
          <Form.Text className="text-muted mb-3">
            Enter the URL for interested customers off the platform e.g. a checkout or another landing page for example.  
            </Form.Text>
            <br />
          <label htmlFor="eventTitle" className="block text-gray-500 font-bold mb-1 pr-4">
                Forward URL:
            </label>
            <InputGroup>
              <span className="text-gray-500 font-bold mr-3">https://</span>
              <FormControl
                  required
                  className="form-control form-control-purple mx-1 mb-2"
                  name="forwardUrl"
                  type="text"
                  value={myevent.forwardUrl}
                  onChange={handleInputChange}
                  />
            </InputGroup>
            <Form.Text className="text-muted mb-3">
            Enter the CTA of the Forward URL Default is "Continue to Purchase." (Optional) <br/>
            </Form.Text>
            <label htmlFor="eventCTA" className="mb-3 block text-gray-500 font-bold mb-1 pr-4">
                Forward URL CTA Button: 
            </label>
            <InputGroup>
            <FormControl
                  required
                  className="form-control form-control-purple mx-1 mb-2"
                  name="forwardCTA"
                  type="text"
                  placeholder="Enter your button CTA..."
                  value={myevent.forwardCTA}
                  onChange={handleInputChange}
                  />
            </InputGroup>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button className="btn btn-slate" variant="secondary" onClick={handleClose}>Close</Button>
          <Button className="btn btn-purple" variant="primary" type="submit" form="editmodal" onClick={handleSubmit} disabled={isSubmitting}>Create</Button>
        </Modal.Footer>
      </Modal>
      </>
  )
}

export default Newevent




