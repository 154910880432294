import React from "react";
import { postOffersList } from "./Helper";
import { PostOfferIcon, SliderArrow } from "./Icons";
import { useNavigate, useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
//import required modules
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";



const PostOffersCards = ({onCategorySelect}) => {
    const navigate = useNavigate(); 
    const handleShow = () => {
        navigate('/event'); 
      }
  return (
    <div id="post-offers">
      <div
        className="padding_xl_right position-relative"
        style={{
          maxWidth: "1258px",
          margin: "auto",
          paddingLeft: "20px",
        }}
      >
        <span className="overflow-hidden position-absolute swiper-prev bg-white left_btn slider_btn_parent d-none d-lg-flex justify-content-center align-items-center cursor_pointer transition_03s">
          <SliderArrow />
        </span>
        <span className="overflow-hidden position-absolute swiper-next bg-white right_btn slider_btn_parent d-none d-lg-flex justify-content-center align-items-center cursor_pointer transition_03s">
          <SliderArrow />
        </span>
        <Swiper
          slidesPerView={3.3}
          spaceBetween={10}
          loop={true}
          speed={1000}
          autoplay={{
            delay: 1500,
          }}
          breakpoints={{
            1300: {
              slidesPerView: 11,
              spaceBetween: 8,
            },
            1200: {
              slidesPerView: 10,
            },
            991: {
              slidesPerView: 9,
            },
            768: {
              slidesPerView: 7,
            },
            576: {
              slidesPerView: 5,
            },
            500: {
              slidesPerView: 4.6,
            },
            460: {
              slidesPerView: 4,
            },
            400: {
              slidesPerView: 3.8,
            },
          }}
          navigation={{ nextEl: ".swiper-next", prevEl: ".swiper-prev" }}
          modules={[Navigation, Autoplay]}
          className="mySwiper"
        > 
        {/* Horizontal scrolling container */}
        {/* <div className="d-flex gap-2 overflow-x-auto"> */}
          {/* Map through post offers list */}
          {postOffersList.map((obj, index) => {
            return (
              <SwiperSlide key={index}>
              <div onClick={()=> onCategorySelect(obj.title)} className="text-center post_card" key={index}>
                {/* Render post offer icon */}
                <span className="cards_circle position-relative">
                  {obj.icon}
                </span>
                {/* Render post offer title */}
                <h6 className="mb-0">{obj.title}</h6>
              </div>
              </SwiperSlide>
            );
          })}
        {/* </div> */}
        <div className="swiper_dots gap-2 d-flex justify-content-center bottom-0 position-absolute start-50 translate-middle-x"></div>
        {/* Post offer button */}
        </Swiper>
        <div className="me-3 pe-1 pe-sm-0 me-sm-0">
          <button onClick={handleShow} className="d-flex mx-sm-auto text-white fw-medium">
            {/* Post offer icon */}
            <PostOfferIcon /> Post Offer
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostOffersCards;