
import React, {useState} from 'react';
import {  Button, Form, Row, Col, Container, Card, Alert } from 'react-bootstrap';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import FlatStyledCard from './FlatStyledCard';
import { useNavigate } from 'react-router-dom';
//import axios from 'axios';
import axios from '../../backendClient'; 
import './checkoutform.scss'
import {useSelector} from 'react-redux'; 

const CheckoutForm = ({locationState}) => {
   const stripe = useStripe();
   const elements = useElements(); 
    const [errorMessage, setErrorMessage] = useState("");  
   const [loading, setLoading] = useState(false); 
   const [promoCode, setPromoCode] = useState('');
   const navigate = useNavigate(); 
   const {currentUser} = useSelector(state => state.user);

   // we will display the error as an alert on the page 
   const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
   }

   const handleServerResponse = async (response) => {
    if (response.error) {
      // Show error from server on payment form via alert error
      setErrorMessage(response.error.message); 
    } else if (response.status === "requires_action") {
      // Use Stripe.js to handle the required next action
      const {
        error,
        paymentIntent
      } = await stripe.handleNextAction({
        clientSecret: response.clientSecret
      });
  
  
      if (error) {
        // Show error from Stripe.js in payment form
        //display error
        alert('Payment error');
      } else {
        // Actions handled, show success message
        //navigate to confirmation page and allow user to use the service or can navigate to message or chats with alert etc
        alert('success'); 
      }
    } else {
      // No actions needed, show success message

      // VERIFY DOMAIN https://stripe.com/docs/payments/payment-methods/pmd-registration  for Apple/Google Pay 
      // Navigate to a success page to a link to some visited page 
      // ** TO DO SEND EMAIL CONFIRMATION ABOUT PAYMENT TERMS And Navigate to Search Page with Alert saying You Are Now A Premium Member and Premium Badge in Nav Bar/Profile
      alert('success'); 
      if(locationState === '/trygpt'){
        navigate('/success', {state: {from: '/trygpt'}}); }
      else{
        navigate('/success'); 
      }
      
    }
    //possibly save stuff like status of the subscription 
  }

   const handleSubmit = async(event) =>{
    console.log('submitted'); 
    event.preventDefault(); 

    if(!stripe){
        // can'submit this function until form loads
        return; 
    }

    setLoading(true); 

     // Trigger form validation and wallet collection
     const {error: submitError} = await elements.submit();
     if (submitError) {
       handleError(submitError);
       return;
     }

       // Create the PaymentMethod using the details collected by the Payment Element
    const {error, paymentMethod} = await stripe.createPaymentMethod({elements});

    //error Handling on Submit 
    if (error) {
        // This point is only reached if there's an immediate error when
        // creating the PaymentMethod. Show the error to your customer
        handleError(error);
        return;
      }

      //Create Subscription from the backend from the PaymentMethod Id that is returned above
      try{
      const res = await axios.post("/api/payments/subscription", {paymentMethodId: paymentMethod.id});

      // axios already provides data in json() format ** 
      const data = res.data;
      //Handle any additional actions like Bank signin or verification from stripe's side 
      console.log(data); 
      handleServerResponse(data); 
      }
      catch(e){
        handleError(e); 
      }
      //possibly save all the informaiton like paymentMethod Id, customer Id, subscription ID (return it from res or save on back end)
   }


   const showUpdatedPrice = async () => {
    const amount = await axios.post("/api/payments/discountApplied",{promoCode}); 
    console.log(amount); 
   }


  return (
    <Container>
        <Row>
            <Col md={12} className="d-flex flex-direction-row justify-content-center align-items-center m-3">
            <h3>Premium Membership Checkout</h3>
            </Col>
        </Row>
        <Row>
        {errorMessage && <Alert variant="danger" dismissible>{errorMessage}</Alert>}
        </Row>
        <Row className='d-flex flex-direction-row justify-content-center'>
            <Col md={6} xs={12} className="m-3 d-flex flex-direction-column justify-content-center order-2 order-md-1" >
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Col md={12}>
                            <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label className="stripe-like-label">Promo Code (Optional)</Form.Label>
                            <Form.Control
                                className="stripe-like-input"
                                type="text"
                                placeholder="FREE2023"
                                value={promoCode}
                                onChange={(e) => setPromoCode(e.target.value)}
                            />
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row className="mb-3"> {/* Add a margin-bottom for spacing */}
                        <Col md={12}>
                            <PaymentElement/>
                        </Col>
                        <Col>
                            <Row className="mt-2">
                                <Button variant="primary" type="submit" disabled={!stripe || loading}>
                                    Upgrade to Premium with 1 Month Trial
                                </Button>
                            </Row>
                        </Col>

                    </Row>
                </Form> 
            </Col>
            <Col md={4} xs={12} className="order-1">
                <Row>
                    {currentUser.userType === 'Individual' ? <FlatStyledCard membershipPrice={15.00} discount={7.00} total={8.00}/> : <FlatStyledCard/>}
                </Row>
                <Row>
                    <Card className="flat-card">
                        <Card.Body>
                            <div className="membership-section">
                                <ul>
                                    <li> 100 Customer Invites per month</li>
                                    <li> Post 3 Active Offers per Month in Any Category during the Calendar Month</li>
                                    <li> Test out and see if customers respond to different combinations of prices/offers</li>
                                    <li> Host Group chats and get immediate feedback/sentiment on various offerings</li>
                                    <li> Written Reviews impacts trust score(s) of others.</li>
                                    <li> Priority Support</li>
                                </ul>
                            </div>
                        </Card.Body>
                    </Card>
                </Row>
                <Row>
                    <Card className="flat-card">
                        <Card.Body>
                            <div className="membership-section">
                                One-click cancel via settings page (pro-rated by the month). 
                            </div>
                        </Card.Body>
                    </Card>
                </Row>
            </Col>
        </Row>
    </Container>

    )
}

export default CheckoutForm