import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   event: null,
   loading: false, 
   error: false
};

// name will be stat.user but value is currentUser
export const eventSlice = createSlice({
   name: 'event',
   initialState,
   reducers:{
       loginStart: (state)=> {
           state.loading = true; 
       },
       loginSuccess: (state, action)=> {
           state.loading = false;
           state.currentUser = action.payload;
       },
       loginFailure: (state)=> {
           state.loading = false;
           state.error = true;  
       },
       registerStart: (state) => {
           state.loading = true;
       },
       // this means they just registered didn't confirm
       registerSuccess: (state) => {
           state.loading = false;
           state.registrationComplete = false;
       },
       // Note: Same as successful login 
       registerConfirm: (state, action) => {
           state.registrationComplete = true;
           state.currentUser = action.payload; 
       },
       registerFailure: (state,action) => {
           state.loading = false;
           state.error = action.payload;
       },
       logout:() =>{
           return initialState; 
       }
   }
});

export const {loginStart,loginSuccess, loginFailure, logout, 
registerStart, registerSuccess, registerFailure, registerConfirm} = eventSlice.actions;


export default eventSlice.reducer; 