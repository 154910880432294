import { loadStripe } from '@stripe/stripe-js';
import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { Elements, useStripe, PaymentElement } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import {useLocation} from 'react-router-dom';



const Checkout = () => {

  const stripePromise = loadStripe('pk_live_51NtjiEID4DrgC8ZC2oAC6Jt3mAvjhzeQXr8rw19wLUW33J7eoVd9V7kvUBfqUeGoea7eJ0DkqNLsfAgQv6zDOUnb00AXQbueN4');

  const appearance = {   theme: 'flat'};
  const location = useLocation(); 
  const [toLocation, setToLocation] = useState(null); 
  const options = {
    business: 'Incentive Meet',
    mode: 'subscription',
    amount: 0,
    currency: 'usd', 
    paymentMethodCreation: 'manual',
    appearance: appearance
  };

  useEffect(() => {
    setToLocation(location.state?.from); 
  }, []);


  return (
<Container>
  <Row>
    <Col md={12} className="d-flex flex-direction-column">
        <Elements stripe={stripePromise} options={options}>
               <CheckoutForm locationState={toLocation}/>
        </Elements>
    </Col> 
  </Row>
</Container>
  )
}

export default Checkout