import { Swiper, SwiperSlide } from "swiper/react";
import { HeadingVector, IconButtonArrow, SliderArrow, Comments, Like, OptionIcon, Share, ShavedVideo } from "./Icons";
import PrimaryButton from "../../components/PrimaryButton";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import leftImage from "../../components/images/png/left-orange-img.png";
import jonasSmith from "../../components/images/png/jonas-smit.png"; 


const OurCustomersSay = () => {

    const scrollToSection = (event) => {
        event.preventDefault(); 
        const element = document.getElementById("product-pricing");
        element.scrollIntoView({ behavior: 'smooth' });
      };

    const CustomerSayList = [
        {
          name: "RosaLA",
          profile: "https://d1873pk1gxetu0.cloudfront.net/landing/RosaProfile.png",
          image: "https://d1873pk1gxetu0.cloudfront.net/landing/Rosa1Img.png",
          text: "Felt so much better after years of body pains and not knowing what to do about it. All my pains were gone after just 2 weeks.",
          likes: "2,043", 
          comments: "130"

        },
        {
          name: "RyanHealth", 
          profile: "https://d1873pk1gxetu0.cloudfront.net/landing/ryanIgprofile.png",
          image: "https://d1873pk1gxetu0.cloudfront.net/landing/ryanIg3.png",
          text: "If you guys don't know that mushrooms are the only food proven to regenerate brain cells that you can consume everyday. Neuro Mushroom Pro provides it in one bottle.",
          likes: "1,042", 
          comments: "355"
        },
        {
          name: "fitMoms",
          profile: "https://d1873pk1gxetu0.cloudfront.net/landing/MarissProfile.png",
          image: "https://d1873pk1gxetu0.cloudfront.net/landing/marissaIg2.png",
          text: "Helpful for my weightloss and mental health journey. I saw immediate benefits from Neuro Mushroom Pro when nothing else was working.",
          likes: "2,029", 
          comments: "445"
        },
        {
          name: "kaitKreat30",
          profile: "https://d1873pk1gxetu0.cloudfront.net/landing/KatieProf1.png",
          image: "https://d1873pk1gxetu0.cloudfront.net/landing/KatieIgprofile4.png",
          text: "As a holistic health practitioner I take these every day. It would be inconvenient to take all these mushrooms separately so it's great to see one product have all of them.",
          likes: "229", 
          comments: "45"
        },
      ];

    return (
      <>
        <div id="our-customer-say" className="position-relative">
          <img
            src={leftImage}
            alt="circle"
            className="customer_left_img position-absolute start-0"
          />
          <div className="right_shadow position-absolute bottom-0 end-0 rounded-pill"></div>
          <div className="container container_modified">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="font-xxl fw-bolder position-relative z-1 mb-0">
                What our customers say
                <span className="our_customer_heading position-absolute mb-0 z-n1 translate-middle-x">
                  <HeadingVector />
                </span>
              </h4>
              <div className="parent_slider_buttons d-flex gap-3">
                <span className="overflow-hidden swiper-prev bg-white left_btn slider_btn_parent d-none d-lg-flex justify-content-center align-items-center cursor_pointer transition_03s">
                  <SliderArrow />
                </span>
                <span className="overflow-hidden swiper-next bg-white right_btn slider_btn_parent d-none d-lg-flex justify-content-center align-items-center cursor_pointer transition_03s">
                  <SliderArrow />
                </span>
              </div>
            </div>
            <Swiper
              slidesPerView={1.13}
              spaceBetween={10}
              loop={true}
              speed={1000}
              pagination={{
                clickable: true,
                el: ".swiper_dots",
              }}
              autoplay={{
                delay: 1500,
              }}
              breakpoints={{
                991: {
                  slidesPerView: 3,
                  spaceBetween: 24,
                },
                576: {
                  slidesPerView: 2,
                },
              }}
              navigation={{ nextEl: ".swiper-next", prevEl: ".swiper-prev" }}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper py-lg-5 py-4"
            >
              {CustomerSayList.map((obj, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="customer_card bg-white">
                      <div className="d-flex justify-content-between align-items-center profile">
                        <div className="d-flex gap-3 align-items-center">
                          <img
                            src={obj.profile}
                            alt="Jonas Smith"
                            width={38}
                            height={38}
                            className="rounded-pill"
                          />
                          <p className="font-sm fw-bold mb-0">{obj.name}</p>
                        </div>
                        <span className="cursor_pointer">
                          <OptionIcon />
                        </span>
                      </div>
                      <div className="overflow-hidden card_img">
                        <img
                          src={obj.image}
                          alt="photo shut"
                          className="w-100 transition_03s object-fit-cover"
                        />
                      </div>
                      <div className="card_bottom_data">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex gap-3">
                            <span className="cursor_pointer">
                              <Like />
                            </span>
                            <span className="cursor_pointer">
                              <Comments />
                            </span>
                            <span className="cursor_pointer">
                              <Share />
                            </span>
                          </div>
  
                          <span className="cursor_pointer">
                            <ShavedVideo />
                          </span>
                        </div>
                        <p className="font-sm fw-bold text-black mb-1 mt-2 pt-1">
                          {obj.likes} likes
                        </p>
                        <p className="font-xs fw-normal text-black mb-1">
                          <span className="fw-bold">{obj.name}</span> {obj.text}
                        </p>
                        <p className="fw-normal font-xs view_all mb-0">
                          View all {obj.comments} comments
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
              <div className="swiper_dots gap-2 d-flex justify-content-center bottom-0 position-absolute start-50 translate-middle-x"></div>
            </Swiper>
            <div className="pe-3 pe-sm-0 me-1 me-sm-0">
              <PrimaryButton
                text="Buy Now"
                btnStyle="justify-content-center btn_customers_say mx-auto mt-lg-5 mt-4"
                onclick={scrollToSection}
                icon={
                  <span className="ps-3 d-inline-block">
                    <IconButtonArrow />
                  </span>
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default OurCustomersSay;
  