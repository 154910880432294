import React from "react";
import { SecondryHeadingVector } from "./Icons";
import {
    GroupChat,
    PromoInvite,
    RealFeedback,
    SocialInteractions,
    ViaCode
  } from "./Icons";

const IncentivePromotions = () => {
    const promotionsCards = [
        {
          icon: <PromoInvite />,
          title: "Promo Invites For Creators Looking To Partner or Invite A Brand And Track Affiliate Sales on Shopify",
          description: "Directly Invite Creators on The Platform Without Endless Emails..",
        },
        {
          icon: <GroupChat />,
          title: "Access to ProductGPT Authentic Script Generator",
          description:
            "Real Authentic Sounding Scripts",
        },
        {
          icon: <ViaCode />,
          title: "Invite via Code",
          description:
            "Scale any offer and Track Affiliate Links with Invite Code If Links Are Blocked or Banned",
        },
        {
          icon: <RealFeedback />,
          title: "Join Affiliate Offers without Follower Count Limits",
          description:
            "Review And Discuss Terms and Track Payouts with Secure Tracking For Sales and Commission Payouts",
        },
        {
          icon: <SocialInteractions />,
          title: "Negotiate or Post Your Own Affiliate Offers with A Few Clicks",
          description:
            "Have Direct Access To Brands or Post Your Own Offer, Event or Affiliate Offer to Multiply Sales For Your Own Products or Brand Products",
        },
      ];
  return (
    <div className="container container_modified">
      <h2 className="text-black fw-bolder font-xxl text-center mt-4 mb-md-1 py-1 position-relative z-1">
        <span className=" d-inline-block position-absolute center__absolute_content ">
          <SecondryHeadingVector />
        </span>
        Why Incentive and ProductGPT
      </h2>
      <div className="row justify-content-center">
        {promotionsCards.map((obj, index) => (
          <div
            className={`mb-3 mb-md-4 ${
              index === 3 || index === 4 ? "col-sm-6" : "col-sm-6 col-lg-4"
            }`}
            key={index}>
            <div className="tools_card transition_03s bg-white d-flex flex-column align-items-center text-center h-100">
              <span className="cards_circle position-relative">{obj.icon}</span>
              <h3 className="font-lg fw-bold mt-4 mt-md-3 pt-0 pt-md-1 ">
                {obj.title}
              </h3>
              <p className="text-gray font-sm mb-0">{obj.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IncentivePromotions;
