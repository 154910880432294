import Hero from "./Hero";
// import Header from "./Header";
import ProjectSales from "./ProjectSales";
import BusinessStep from "./BusinessStep";
import BusinessForm from "./BusinessForm";
import Testimonials from "./Testimonials";
import OurProcess from "./OurProcess";
import SheduleTime from "./ScheduleTime";
import Pricing from "../home/Pricing";
// import Footer from "../landing/Footer";
// import Testimonials from "./sections/Testimonials";
import './businesslanding.scss'; 

const BusinessLanding = () => {
  return (
    <div id="business-landing" className="bg-white">
      {/* <Header isHeaderVisible={isHeaderVisible} /> */}
      <Hero />
      <ProjectSales />
      <BusinessStep />
      <BusinessForm />
      <Testimonials />
      <OurProcess />
      <SheduleTime />
      <Pricing />
      {/* 
      <Footer /> */}
    </div>
  );
};

export default BusinessLanding;
