import React from "react";
import relatedCardImage from "../../components/images/dashboard/webp/info_business.webp"


const RelatedOffers = ({relatedEvents}) => {

  const handleNavigate = (shortTitle) => { window.location.href = `/single/${shortTitle}`; };
 
    let RelatedCardsList = [
        {
          img: relatedCardImage,
          title: "Offer title",
          description:
            "Volutpat leo placerat donec diam sodales diam. Amet augue ullamcorper dui eu sem nec molestie. ",
        },
        {
          img: relatedCardImage,
          title: "Offer title",
          description:
            "Volutpat leo placerat donec diam sodales diam. Amet augue ullamcorper dui eu sem nec molestie. ",
        },
        {
          img: relatedCardImage,
          title: "Offer title",
          description:
            "Volutpat leo placerat donec diam sodales diam. Amet augue ullamcorper dui eu sem nec molestie. ",
        },
        {
          img: relatedCardImage,
          title: "Offer title",
          description:
            "Volutpat leo placerat donec diam sodales diam. Amet augue ullamcorper dui eu sem nec molestie. ",
        },
        {
          img: relatedCardImage,
          title: "Offer title",
          description:
            "Volutpat leo placerat donec diam sodales diam. Amet augue ullamcorper dui eu sem nec molestie. ",
        },
        {
          img: relatedCardImage,
          title: "Offer title",
          description:
            "Volutpat leo placerat donec diam sodales diam. Amet augue ullamcorper dui eu sem nec molestie. ",
        },
        {
          img: relatedCardImage,
          title: "Offer title",
          description:
            "Volutpat leo placerat donec diam sodales diam. Amet augue ullamcorper dui eu sem nec molestie. ",
        },
      ];

      // RelatedCardsList = [    {
      //   img: relatedCardImage,
      //   title: "Offer title",
      //   description:
      //     "Volutpat leo placerat donec diam sodales diam. Amet augue ullamcorper dui eu sem nec molestie. ",
      // }]; 

      if (!relatedEvents || relatedEvents.length === 0) {
        return   <div
        className="related-cards d-flex align-items-sm-center gap-3 border-0 pt-0"  style={{ width: '100px', height: '100px', overflow: 'visible' }}>
        <img src={relatedCardImage}style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="card" />

        <div className="ps-1">
          {" "}
          <h3 className="mb-2">No Related Offers</h3>
          {/* <p className="mb-0"></p> */}
        </div>
      </div>
      }

  return (
    <div id="related-Offers">
      {relatedEvents.map((obj, i) => (
        <div
          key={i}
          className={`related-cards d-flex align-items-sm-center gap-3 ${
            i === relatedEvents.length - 1 ? "border-0" : ""
          } ${i <= 0 && "pt-0"}`}
          onClick={() => handleNavigate(obj.shortTitle)}>
          <img src={obj.imgEvent} alt="card" />

          <div className="ps-1">
            {" "}
            <h3 className="mb-2">{obj.eventTitle}</h3>
            <p className="mb-0">{obj.eventDescription}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RelatedOffers;