import { useNavigate, useLocation } from "react-router-dom";
import './categorybox.scss'; 

const CategoryBox = ({ icon: Icon, label, selected, onCategoryChange }) => {
    
    const location = useLocation();
  
    const handleClick = () => {
        const searchParams = new URLSearchParams(location.search);
        
        let newCategory;
        if (searchParams.get('category') === label) {
          newCategory = ''; // Clear category
        } else {
          newCategory = label; // Set new category
        }
    
        onCategoryChange(newCategory);
        
      };
  
    return (
      <div onClick={handleClick} className={`d-flex flex-column align-items-center justify-content-center p-1 hover-highlight ${selected ? 'border-bottom border-dark text-dark' : 'border-transparent text-muted'}`}>
        <Icon size={26} />
        <div className="font-medium text-sm">
          {label}
        </div>
      </div>
    );
  };

export default CategoryBox; 