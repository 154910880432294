import React, { useState, useEffect,useCallback } from 'react'
import Form from "react-bootstrap/Form";
import "./customautocomplete.scss";
//import axios from 'axios';
import axios from '../../backendClient';

const Customautocomplete = ({onSelect, searchType}) => {

    const [query, setQuery] = useState("");
    const [inputFocused, setInputFocused] = useState(false);
    const [data, setData] = useState([]);

    const searchCity = useCallback(async () => {
        // Determine which API to call based on searchType
        const apiURL = searchType === "address" ?
                `/api/events/address?addressName=${query}` :
                `/api/events/cities?cityName=${query}`;
        const response = await axios(apiURL); //{ withCredentials: true }
        //Debug below: 
        //console.log(response);
        

        if (response) {
            setData(response.data.predictions);
        }
    },[searchType, query]);

    useEffect(() => {
        const timerId = setTimeout(() => {
            if (query && query.length > 3) {
                searchCity();
            }
        }, 300); // delay of 500ms

        return () => {
            clearTimeout(timerId); // Clear the timer if the user types before the delay ends
        };
    }, [query, searchCity, searchType]);




    const handleSelect = (name, place=null) => {
        setQuery(name); 
        setInputFocused(false);
        onSelect(name, place);// Clears the input and hides the dropdown, also for overrides will ignore 2nd argument for useState (setting city etc)
    };


    return (      
    <>
        <Form.Control 
            type="text"
            placeholder={searchType === 'address'?'Search by address' : 'Search by city'}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setTimeout(() => setInputFocused(false), 200)}
        />
                    
                   
        <ul className='list'>
                        {/* {  inputFocused  &&
                            data.filter((item)=>item.description.toLowerCase().includes(query.toLowerCase())).map((item) => (
                                <li key={item.place_id} className='listItem' onClick={(e) => handleSelect(item.description)}>{item.description}</li>
                                ))
                            } */}

            {inputFocused &&
                data
                    .filter(item => query.toLowerCase().split(/[ ,]+/)
                    .map(part => part.trim())
                    .some(q => item.description.toLowerCase().includes(q)))
                    .map((item, index) => (
                            <li key={index} className='listItem' onClick={(e) => {handleSelect(item.description, item.place_id);}}>
                                {item.description}
                            </li>))}

        </ul>
    </>
                    
    )
}

export default Customautocomplete