

import PrimaryButton from "../../components/PrimaryButton";
import { IconButtonArrow, HeadingVector } from "./Icons";
import heroImage from "../../components/images/webp/hero-image-girl.webp"; 
import bgImageHero from "../../components/images/png/hero-bg-image.png"; 
import neuroImage from "../../components/images/webp/front-neuro.webp"; 


const Hero = ({title}) => {
    const scrollToSection = (event) => {
        event.preventDefault(); 
        const element = document.getElementById("wanna-advertise");
        element.scrollIntoView({ behavior: 'smooth' });
      };
    return (
      <>
        <div id="hero-social" className="position-relative z-1 mb-lg-5 mt-5 pt-4">
          <div className="bg_clr_blue_hero position-absolute end-0 z-n1"></div>
          <img
            src={bgImageHero}
            alt="bg-item-full"
            className="bg_image_hero position-absolute top-50 end-0 translate-middle-y z-n1"
          />
          <div className="container container_modified pt-5">
            <div className="row flex-column-reverse flex-lg-row align-items-center">
              <div className="col-lg-6 col-md-8 mt-4 mt-lg-0 pe-xl-3">
                <h2 className="text-black position-relative mb-2 mb-md-3 z-1">
                  <span className="position-absolute z-n1 hero_heading">
                    <HeadingVector />
                  </span>
                 Never miss meetings, forget tasks...
                  <span className="heading_clr"> {title}</span> <br/>
                </h2> 
                <p className="pb-lg-2 mb-3 text-gray font-md">
                  Neuro Mushroom Pro is a premium dosage over 10 Mushroom extracts...the most powerful extracts combined together. Get multiple 
                  benefits in one bottle. Empirical Research supports many of the findings in large studies from the benefits of these powerful substances.
                  GMP, FDA Approved, and US Manufactured Neuro Mushroom Pro is finally here. Get Bonus Offers Below. 
                  Ganoderma mushrooms, commonly known as reishi mushrooms, are a type of medicinal mushroom that has been traditionally used in Eastern medicine for their potential health benefits. In recent years, Ganoderma mushrooms have gained attention for their potential effects on prostate health, including prostate cancer and benign prostatic hyperplasia (BPH).
                </p>
                <PrimaryButton
                  text="See Benefits"
                  btnStyle="hero_button"
                  onclick={scrollToSection}
                  icon={
                    <span className="ps-1">
                      <IconButtonArrow />
                    </span>
                  }
                />
              </div>
              <div className="col-lg-6 col-md-8 text-lg-end text-center">
                <img src={neuroImage} alt="hero-main" className="hero_img" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default Hero;