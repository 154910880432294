// import Hero from "./Hero";
// import About from "./About";
import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CrossIcon } from "./Icons";
import { BtnArrow } from "./Icons";
import PrimaryButton from "./PrimaryButton";
import axios from '../../backendClient'
import { useNavigate, useParams } from 'react-router-dom';
import { clearError, userRouteError, updateUser } from '../../redux/userSlice';
import { AppContext } from '../../AppContext';
import './nprofile.scss'; 

const Nprofile = () => {

    //fill in all logic for getting profile Id and handling images and deleting images from the list 

    const {id: urlId} = useParams(); 
    const socket = useContext(AppContext); 
    const {currentUser, error} = useSelector(state => state.user);
    const [cardText, setCardText] = useState("");
    const [profileUser, setProfileUser] = useState("");
    const [uploadDone, setUploadDone] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [uploadImages, setUploadImages] = useState(currentUser.moreImages || []);
    
    const id = urlId || currentUser._id;

    useEffect(() => {
        const fetchProfile = async () => {
            //emit the socket 'view' event here which is type 1 
            if (id !== currentUser._id.toString()) {
                try {
                    const result = await axios(`/api/users/profile/${id}`);
                    if (result.data){
                    setProfileUser(result.data);
                    setCardText(result.data.aboutMe);
                    } 
                   socket.emit("sendNotification",{
                    sender: currentUser,
                    receiver: id, 
                    type: 1
                   }); 
                   try{
    
                    const response = await axios.post('/api/messages/addOfflineNotification',{sender: currentUser, offlineUserId: id,  notificationType: 1, notificationMessage: null });
          
                  }catch(e){
                    console.log('Failed to set unread notification ', e);
                  }
                } catch (error) {
                    console.error(error);
                    dispatch(userRouteError(error.response.data.message));
                    navigate("/login");
        
                }
            } else {
                setProfileUser(currentUser);
                setCardText(currentUser.aboutMe);
               // setOwnPage(true); // and here too
            
            }
        }
        fetchProfile(); 
      }, [id, currentUser._id, uploadDone]);
  
    const removeValueHandler = (defaultImg) => {
      setUploadImages((prevImages) => {
        return prevImages.map((item) => {
          if (item.defaultImg === defaultImg) {
            return { ...item, value: !item.value };
          }
          return item;
        });
      });
    };
  
    const imageHandler = (value, defaultImg) => {
      const file = value;
  
      // Check if a file is selected
      if (file) {
        // Check if the selected file is an image
        if (file.type.startsWith("image/")) {
          const image = URL.createObjectURL(file);
  
          setUploadImages((prevImages) => {
            return prevImages.map((item) => {
              if (item.defaultImg === defaultImg) {
                return { ...item, img: image, value: false };
              }
              return item;
            });
          });
        } else {
          // Display an error or handle non-image file selection
          console.error("Please select an image file");
        }
      }
    };
    return (
      <div style={{ background: "#FAFAFA" }}>
        <div
          style={{
            marginTop: "71px",
            maxWidth: "1360px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
          className="pt-3 pt-sm-4 mx-auto px_12"
        >
          <PrimaryButton ctaText="Back" icon={<BtnArrow />} link="/dashboard" />
          <div className="row flex-column flex-lg-row">
            <div className="hero_images_parent" style={{ width: "62%" }}>
            <div className="br_10 bg-white p-3 mb-3 pb-4 pt-md-4 p_12">
        <div className="row mx-0">
          <div className="col-sm-7 px-0 px-sm-2">
            <img
              src={currentUser.profilePic}
              alt="profileMainImg"
              className="w-100 br_10 object-fit-cover h-100"
            />
          </div>
          <div className="col-sm-5 ps-0 ps-sm-1 pe-0 d-none d-sm-block">
            <div className="row mx-0">
              {uploadImages.map((obj, index) => (
                <div
                  className={`col-6 px-2 position-relative user_img_parent ${
                    index === 4 || index === 5 ? "" : "mb-3"
                  }`}
                  key={index}
                >
                  <span
                    onClick={() => removeValueHandler(obj.defaultImg)}
                    className={`position-absolute img_cross_icon cursor_pointer transition_03s ${
                      obj.value ? "opacity-0 invisible" : "img_cross_icon_hover"
                    }`}
                  >
                    <CrossIcon />
                  </span>
                  {obj.value ? (
                    <>
                      <label
                        className="d-flex align-items-center justify-content-center h-100 cursor_pointer user_img_height upload_image_label"
                        htmlFor="my-file"
                      >
                        {" "}
                        Upload File
                      </label>
                      <input
                        onChange={(e) =>
                          imageHandler(e.target.files[0], obj.defaultImg)
                        }
                        id="my-file"
                        type="file"
                        hidden
                      />
                    </>
                  ) : (
                    <img
                      src={obj.img ? obj.img : obj.defaultImg}
                      alt={obj.img ? obj.img : obj.defaultImg}
                      className="w-100 br_10 h-100 object-fit-cover user_img_height"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <h4
          style={{ fontSize: "22px" }}
          className="text-black fw-bolder mb-2 mt-3 pt-md-1 ps-1 ps-sm-2"
        >
         {profileUser.userName}
        </h4>
        <p className="text-gray font-sm mb-1 mb-md-2 ps-1 ps-sm-2">
          {profileUser.city}
        </p>
        <p className="text-black font-sm fw-medium mb-2 mb-sm-3 pb-1 ps-1 ps-sm-2">
          Trust Score: <span className="text-green fw-bolder">{profileUser.trustScore}%</span>
        </p>
        <p className="bg-yellow br_10 text-lightblack text-decoration-none px-3 py-2 fw-bolder ms-1 ms-sm-2 d-inline-block">
          {profileUser.membershipType}
        </p>
        <div className="row d-sm-none pt-1 px-2">
          {uploadImages.map((obj, index) => (
            <div
              className={`col-4 px-2 mt-3 position-relative user_img_parent`}
              key={index}
            >
              {obj.value ? (
                <>
                  <label
                    className="d-flex align-items-center justify-content-center h-100 cursor_pointer user_img_height upload_image_label"
                    htmlFor="my-file"
                  >
                    {" "}
                    Upload File
                  </label>
                  <input
                    onChange={(e) =>
                      imageHandler(e.target.files[0], obj.defaultImg)
                    }
                    id="my-file"
                    type="file"
                    hidden
                  />
                </>
              ) : (
                <img
                  src={obj.img ? obj.img : obj.defaultImg}
                  alt={obj.img ? obj.img : obj.defaultImg}
                  className="w-100 br_10 h-100 object-fit-cover user_img_height"
                />
              )}
              <span
                onClick={() => removeValueHandler(obj.defaultImg)}
                className={`position-absolute img_cross_icon cursor_pointer transition_03s ${
                  obj.value ? "opacity-0 invisible" : "img_cross_icon_hover"
                }`}
              >
                <CrossIcon />
              </span>
            </div>
          ))}
        </div>
      </div>
              <div className="br_10 bg-white p-3 p-md-4">
            <h4 style={{ fontSize: "22px" }} className="px-1 sm-px-0 text-black fw-bolder mb-2 pt-1 pt-sm-0">
        About Me
      </h4>
      <p className="px-1 sm-px-0 text-gray font-sm mb-1 mb-sm-0">
                {cardText}
      </p>
    </div>
              {/* <About /> */}
            </div>
            <div className="offer_cards_parent" style={{ width: "38%" }}>
              {/* <Offers /> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Nprofile;