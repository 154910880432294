import React, {useEffect, useState} from 'react'
import './chat.scss';
import { Container, Image, Badge, Row, Col} from "react-bootstrap";
import {useNavigate} from 'react-router-dom';
//import axios from 'axios';
import axios from '../../backendClient';
import { useDispatch, useSelector } from 'react-redux';
import { clearUnreadCountForRoom } from '../../redux/messagesSlice';
import Emptystate from '../../components/Emptystate';



function Chat() {

    const navigate = useNavigate();
    let eventTitle = ''; 
    let eventDate = '';

    const [users, setUsers] = useState([]);
    const [firstMessage, setFirstMessage] = useState([]); 
    const messagesPerRoom = useSelector(state=> state.messages.byRoom);
    const dispatch = useDispatch(); 

  
   
    const getChatUsers = async () => 
    {
        const response = await axios.get(`/api/events/getChatUsers`);
        setUsers(response.data); 
    }

    
    const getFirstMessage = async(events) => {

      const response = await axios.post('/api/messages/getFirstMessage',{events: events}); 
      setFirstMessage(response.data); 
      
    }


    useEffect(() => {
      //once users are gotten go through 
      const events = users.map(event => {return event._id}); 
      //call getFirstMessage and set firstMessage array
      getFirstMessage(events); 
    }, [users]);

    useEffect(()=>{
      console.log(firstMessage);
    }, [firstMessage]); 

    useEffect(() => {getChatUsers();}, []);

    const handleImageClick = (id) => {
     // alert('clicked!'); 
     // navigate(`/profile/${id}`); 
    }
    

    const handleChatClick = async (profiles, eventTitle, eventDate) => {
      console.log('runs');
      console.log(profiles);
      dispatch(clearUnreadCountForRoom(profiles._id));
      try{

        await axios.put('/api/messages/clearUnreadRoomCount',{roomId: profiles._id});

      }catch(e){
        console.log('Failed to remove unread messages from room', e);
      }

      navigate('/message', { state: { profiles, eventTitle, eventDate } });
    };

  return (
    <Container className="chat-container">
    <h2 className="event-title">Promotions/Offer Chats</h2>
    <Row>
            <Col md={12} className="d-flex flex-direction-row align-items-left m-1">
            <h6>Chat communication may close up to 24 hours after offer ends. You will be able to privately review in past offers on the "My Offers" page.</h6>
            </Col>
    </Row>
    {users && users.length > 0 ? users.map((profiles, i) => (
      <div key={i} className="event-row" onClick={() => handleChatClick(profiles, eventTitle=profiles.eventTitle, eventDate=profiles.eventDate)}>  
      <button key={i} className="event-row shadow-sm">
        <Row className="inner-row">
          <div className="profile-row">
            {profiles.guests.slice(0, 4).map((profile, index) => (
              <div key={index} className={`profile-col profile-col-${index + 1}`}>
                        <div className="profile-container">
                          <Image src={profile.user.profilePic} alt="Profile" className="profile-image" roundedCircle />
                        </div>
                    
              </div>
            ))}
            {profiles.guests.length > 4 && (
              <div className="overflow-profiles">
                <Badge pill variant="danger">+{profiles.guests.length - 4}</Badge>
              </div>
            )}
          </div>
          <div className='event-details'>
            <div className='event-name-date'>
              <h5 className="event-name"> {profiles.eventTitle}</h5>
              <h6 className="event-date">Promotion End Date: {profiles.eventDate?.split('T')[0]}</h6>
            </div>
            <div className="message-unread">
              <p className="placeholder-message">{firstMessage[i]}</p>
              <Badge pill variant="danger" className="unread-badge">{messagesPerRoom[profiles._id]?.unreadCount > 0 && messagesPerRoom[profiles._id]?.unreadCount}</Badge>
            </div>
          </div>
        </Row>
      </button>
      </div>
    )) : <Emptystate title="No offers" subtitle='Request to Redeem Offers or Create Offers to chat with customers here and provide information and build excitement.' />}
    
  </Container>
  );
}

export default Chat