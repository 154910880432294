import { useRef, useState, useEffect } from "react";
import { HeadingVector, VideosBtnArrow, Star } from "./Icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import PrimaryButton from "../../components/PrimaryButton";
import leftImage from "../../components/images/png/left-blue-img.png";
import playVideoBtn from "../../components/images/png/play-btn.png"; 
import jonas from "../../components/images/png/jonas-smit.png"; 

const PopularVideos = () => {

  const scrollToSection = (event) => {
    event.preventDefault(); 
    const element = document.getElementById("product-pricing");
    element.scrollIntoView({ behavior: 'smooth' });
  };

    const PopularVideosList = [
      {"videoId": "WqxBtTnznsk", "image": "https://d1873pk1gxetu0.cloudfront.net/landing/christianStem.png", "rating": 5, "description": "Cordyceps has benefits for energy and strength. Take it today in this formulation."},
      {"videoId": "-2P6Lxl6NqE", "image": "https://d1873pk1gxetu0.cloudfront.net/landing/rlove.png", "rating": 5, "description": "Brain promoting mushrooms ar inside Neuro Mushroom pro in the dosage I recommend with all its benefits. "},
      {"videoId": "d3ggYiS596c", "image": "https://d1873pk1gxetu0.cloudfront.net/landing/reishiProf.png", "rating": 5, "description": "Learn what you need to do take of your brain health with the one natural substance that can do so. Reishi can be safely taken everyday and Neuro Mushroom Pro helps you do it conveniently."},
      {"videoId": "JXmiM6xkD7I", "image": "https://d1873pk1gxetu0.cloudfront.net/landing/jktraining.png", "rating": 5, "description": "Taking a formualtion of natural mushrooms that provide all the necessary vitamins the brain needs is absolutely essential. Pair it with a DHA Fatty Acid and you are all set. -Robert "},
  ]


    const videoRefs = useRef([]);
    const playerRefs = useRef([]);
    const [playingVideoIndex, setPlayingVideoIndex] = useState(-1);
  
    useEffect(() => {
      // Load the YouTube Iframe API
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  
      // Initialize YouTube players once the API is ready
      window.onYouTubeIframeAPIReady = () => {
        videoRefs.current.forEach((video, index) => {
          if (video) {
            playerRefs.current[index] = new window.YT.Player(video, {
              events: {
                onStateChange: (event) => onPlayerStateChange(event, index),
              },
              playerVars: {
                'enablejsapi': 1,
              },
            });
          }
        });
      };
    }, []);

    const onPlayerStateChange = (event, index) => {
        if (event.data === window.YT.PlayerState.PLAYING) {
          setPlayingVideoIndex(index);
        } else if (event.data === window.YT.PlayerState.PAUSED) {
          setPlayingVideoIndex(-1);
        }
      };
    
      const toggleVideoPlay = (index) => {
        const player = playerRefs.current[index];
        if (player) {
          if (playingVideoIndex === index) {
            player.pauseVideo();
          } else {
            player.playVideo();
          }
        }
      };
//     return (
//       <>
//         <div id="popular-videos" className="position-relative py-5">
//           <img
//             src={leftImage}
//             alt="circle"
//             className="popular_videos_img position-absolute start-0"
//           />
//           <div className="container container_modified pt-md-2">
//             <h6 className="font-xxl text-center fw-bolder position-relative z-1 mb-0 text-black">
//               Popular Videos
//               <span className="popular_videos_heading position-absolute start-50 z-n1 translate-middle-x">
//                 <HeadingVector />
//               </span>
//             </h6>
//             <Swiper
//               slidesPerView={2.2}
//               spaceBetween={10}
//               loop={true}
//               speed={1000}
//               pagination={{
//                 clickable: true,
//                 el: ".swiper_dots",
//               }}
//               autoplay={{
//                 delay: 1500,
//               }}
//               breakpoints={{
//                 991: {
//                   slidesPerView: 4,
//                   spaceBetween: 20,
//                 },
//                 576: {
//                   slidesPerView: 3,
//                 },
//               }}
//               navigation={{ nextEl: ".swiper-next", prevEl: ".swiper-prev" }}
//               modules={[Pagination, Navigation, Autoplay]}
//               className="mySwiper py-lg-5 py-4"
//             >
//               {PopularVideosList.map((obj, index) => {
//                 return (
//                   <SwiperSlide key={index}>
//                     <div className="position-relative pb-2 ">
//                       <img
//                         src={obj.image}
//                         alt="photo shut"
//                         className="w-100 transition_03s object-fit-cover card_image"
//                       />
//                       <div className="position-absolute play_btn_position cursor_pointer">
//                         <img
//                           src={playVideoBtn}
//                           alt="play-btn"
//                           className="w-100"
//                         />
//                       </div>
//                     </div>
//                   </SwiperSlide>
//                 );
//               })}
//               <div className="swiper_dots gap-sm-2 d-flex justify-content-center bottom-0 position-absolute start-50 translate-middle-x d-lg-none"></div>
//             </Swiper>
//             <div className="pt-4 pt-lg-0 me-3 pe-1 pe-sm-0 me-sm-0">
//               <PrimaryButton
//                 text="Watch All Videos"
//                 btnStyle="justify-content-center btn_customers_say  mx-auto bg-transparent videos_btn"
//                 icon={
//                   <span className="ps-2 ps-sm-3 d-inline-block">
//                     <VideosBtnArrow />
//                   </span>
//                 }
//               />
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   };
return (
    <div id="popular-videos" className="position-relative py-5">
      <img
        src={leftImage}
        alt="circle"
        className="popular_videos_img position-absolute start-0"
      />
      <div className="container container_modified pt-md-2">
        <h6 className="font-xxl text-center fw-bolder position-relative z-1 mb-0 text-black">
          Popular Videos
          <span className="popular_videos_heading position-absolute start-50 z-n1 translate-middle-x">
            <HeadingVector />
          </span>
        </h6>
        <Swiper
          slidesPerView={2.2}
          spaceBetween={200}
          loop={true}
          speed={60000}
          pagination={{
            clickable: true,
            el: ".swiper_dots",
          }}
          autoplay={{
            delay: 1500,
          }}
          breakpoints={{
            991: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            576: {
              slidesPerView: 2,
            },
          }}
          navigation={{ nextEl: ".swiper-next", prevEl: ".swiper-prev" }}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper py-lg-5 py-4"
        >
          {PopularVideosList.map((obj, index) => (
            <SwiperSlide key={index}>
               <div className="position-relative pb-2"> 
                 <div className="video_wrapper position-relative overflow-hidden"> 
                  <iframe
                    id={`player-${index}`}
                    title={obj.videoId}
                    className="w-100 transition_03s object-fit-cover card_image"
                    src={`https://www.youtube.com/embed/${obj.videoId}?enablejsapi=1`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    ref={(el) => (videoRefs.current[index] = el)}
                  ></iframe>
                 </div> 
                <div className="comment-card d-flex flex-grow-1 justify-content-top flex-column">
                  <div className=" d-flex align-items-center gap-2">
                    <img
                      className=" img_user me-1"
                      src={obj.image}
                      alt="user-profile-content"
                    />
                    <div>
                      <div>
                        <h3 className="text-start mb-0">{obj.userName}</h3>
                        <div className=" d-flex gap-2 align-items-center">
                          {" "}
                          {[...Array(obj.rating)].map((_) => (
                            <Star />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="text-start mb-0 mt-2 pt-1">
                    “{obj.description}”{" "}
                  </p>
                </div> 
              </div> 
            </SwiperSlide>
          ))}
          <div className="swiper_dots gap-sm-2 d-flex justify-content-center bottom-0 position-absolute start-50 translate-middle-x d-lg-none"></div>
        </Swiper>
        <div className="pt-4 pt-lg-0 me-3 pe-1 pe-sm-0 me-sm-0">
          <PrimaryButton
            text="Continue to Purchase"
            btnStyle="justify-content-center btn_customers_say  mx-auto bg-transparent videos_btn"
            onclick={scrollToSection}
            icon={
              <span className="ps-2 ps-sm-3 d-inline-block">
                <VideosBtnArrow />
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
};
  
  export default PopularVideos;