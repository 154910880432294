import React, {useState, useEffect, useContext} from 'react';
import {Container, Row, Col, Card, Button, Image, FormControl, Alert, Spinner, Badge} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import './profile.scss';
//import axios from 'axios';
import axios from '../../backendClient'
import { useNavigate, useParams } from 'react-router-dom';
import photoPic from '../../assets/photo.svg';
import { clearError, userRouteError, updateUser } from '../../redux/userSlice';
import { addNotifications } from '../../redux/messagesSlice';
import { AppContext } from '../../AppContext';



const Profile = () => {

const [isEditing, setIsEditing] = useState(false); 
const [hostedEvents, setHostedEvents] = useState([]); 
const [profileEvents, setProfileEvents] = useState([]);
const [profileUser, setProfileUser] = useState("");
const [cardText, setCardText] = useState("");
const [ownPage, setOwnPage] = useState(false); 
const [show, setShow] = useState(true);
const [uploadDone, setUploadDone] = useState(false);
const [images, setImages] = useState([]);
const [profilePreview, setProfilePreview] = useState("");
const [isSaving, setIsSaving] = useState(false);
const [saveStatus, setSaveStatus] = useState(null);
const [isLoading, setIsLoading] =useState(false); 



const socket = useContext(AppContext); 

const {id: urlId} = useParams(); 

const {currentUser, error} = useSelector(state => state.user);
const dispatch = useDispatch();
const navigate = useNavigate();

const isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;

const isChrome = () => {
    return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  };

//either we use the redux User or the param user (if the param user is not available) take care of case where they are equal **TO DO** 
const id = urlId || currentUser._id;

const isUrl = (string) =>{
    try{
      new URL(string);
      return true; 
    }catch(e){
      console.error(e);
      return false;
    }
  }


useEffect(() => {
    const fetchProfile = async () => {
        //emit the socket 'view' event here which is type 1 
        if (id !== currentUser._id.toString()) {
            try {
                const result = await axios(`/api/users/profile/${id}`);
                if (result.data){
                setProfileUser(result.data);
                setCardText(result.data.aboutMe);
                } 
               socket.emit("sendNotification",{
                sender: currentUser,
                receiver: id, 
                type: 1
               }); 
               try{

                const response = await axios.post('/api/messages/addOfflineNotification',{sender: currentUser, offlineUserId: id,  notificationType: 1, notificationMessage: null });
      
              }catch(e){
                console.log('Failed to set unread notification ', e);
              }
            } catch (error) {
                console.error(error);
                dispatch(userRouteError(error.response.data.message));
                navigate("/login");
    
            }
        } else {
            setProfileUser(currentUser);
            setCardText(currentUser.aboutMe);
            setOwnPage(true); // and here too
        
        }
    }
    fetchProfile(); 
  }, [id, currentUser._id, uploadDone]);







//const placeholderImages = new Array(6).fill('https://images.unsplash.com/photo-1621707854626-ffa306e32745?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1964&q=80'); 

//Use an initial UseEffect to populate profile data 
//and dependency of the object so whenver it changes
// profile.Name, profile.location, profile.about, and profile.images and then set placeholder images with 

useEffect(() => {
    if(profileUser){
    setImages([profileUser.profilePic, ...(profileUser.moreImages || [])]);
    setProfilePreview(profileUser.profilePic);}
  }, [profileUser]);


  useEffect(()=> {
    setProfilePreview(profileUser.profilePic); 
  }, [uploadDone]); 


// Create an array of 6 slots, filled with images or the upload icon
const placeholderImages = Array(6).fill().map((_, index) => {
    const src = images[index];
    return src ? src : photoPic;
  });




// Will save editable changes to the Profile


useEffect(() => {
    if (!isEditing && cardText !== "") {
        const saveChanges = async () => {
            setIsSaving(true);
            try{

                const res = await axios.put('/api/users/saveAboutMe', {'aboutMe': cardText});
                if(res.data){
                    console.log(res.data);
                    dispatch(updateUser({fieldName:'aboutMe',value:res.data}));
                   }

            }catch(error){
                setSaveStatus("error");
            }
            // Save changes here.
            //console.log("Saving changes:");
            setTimeout(()=> {setIsSaving(false)}, 2000);
          };
          saveChanges(); 
    }
   
  }, [isEditing]); // This will be called whenever isEditing changes.

  const handleCardClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    setCardText(event.target.value);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
  };


//useEffect to check what events the profile user is attending logged as 
    useEffect( ()=>{
        const fetchEvents = async() => {
            const res = await axios(`/api/events/getHostEvents?guestId=${profileUser._id}`);
            setHostedEvents(res.data); 
        }
        fetchEvents();

        if(!ownPage && profileUser._id){
            const fetchProfileEvents = async() => {
                const res = await axios(`/api/events/getProfileEvents?guestId=${profileUser._id}`);
                setProfileEvents(res.data); 
            }
            fetchProfileEvents(); 
        }
        
      },[profileUser._id]);


const handleFileUpload = (index) => async (event) => {
        // Now you have access to the specific index this file input corresponds to
        console.log(index);
        const file = event.target.files[0];
        if (!file) return alert('Please Upload Your Profile Photo!');
        // your file upload logic here

        //if its the profile picture and is photo pic then upload it to the upload profile photo endpoint ** MUST IMPLEMENT ** 
        if(index===0){
            const data = new FormData(); 
            data.append('profilePic',file);
            data.append('userId',currentUser._id);
            data.append('idx', index); 
    
            try {
                // Make a POST request to the server-side route that handles file upload
                setIsLoading(true); 
                const res = await axios.post('/api/users/uploadProfilePic', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                
                
                if(res.data){
                 dispatch(updateUser({fieldName:'profilePic',value:res.data}));
                 dispatch(updateUser({fieldName:'placeHolderPic', value: false}));
                 setUploadDone(prev => !prev);
                 setIsLoading(false); 
                 return; 
    
                }
            } catch(err) {
                console.error(err);
                
            }

        }

        //otherwise just upload normal like a non-prof img 
        // upload an img if the handleFileUpload is visible and it's your profile (on the backend) to uploadMorePics endpoint
        // update the set upload done and refresh the useEffect for the user 
        //pass it to formDate as setformdate()
       
        const data = new FormData(); 
        data.append('additionalPic',file);
        data.append('userId',currentUser._id);
        data.append('idx', index); 

        try {
            // Make a POST request to the server-side route that handles file upload
            const res = await axios.post('/api/users/upload', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            if(res.data){
             dispatch(updateUser({fieldName:'moreImages',value:res.data}));
             setUploadDone(prev => !prev);

            }
        } catch(err) {
            console.error(err);
            
        }

    };

const handleImageSelect = async (filePath) => {

        if(isUrl(filePath)){
          setProfilePreview(filePath);
        }
      
};

const handleRemoveImage = async (indexToRemove, imgUrl) => {
    // S3 deletion endpoint 

    try{
        
        //if it's the Profile Pic handle it differently  and let it be uploaded if currentUser.placeHolderPic
        if(indexToRemove===0 && currentUser.placeHolderPic) return alert('Can Not Delete PlaceHolder');

        if(indexToRemove===0 && !currentUser.placeHolderPic){
            //delete the image and then check if currentUser.gender === 'Male' set the img Preview on the back-end
            // do not remove the index as there must always be a profile picture allow the profile to be set with a ProfilePlaceholder flag
            //update the dispatch with filedName: 'profilePic' 
            const res = await axios.post('/api/users/deleteProfilePic',{'fileUrl': imgUrl, 'userId': currentUser._id}, {
                headers: {
                    'Content-Type': 'application/json'
                }   
            });

            if(res.data){
                dispatch(updateUser({fieldName:'profilePic', value: res.data}));
                dispatch(updateUser({fieldName:'placeHolderPic', value: true}));
                let updatedImages = [...images]; 
                setImages(updatedImages);
                setUploadDone(prev => !prev);
            }
        }

        if(indexToRemove !== 0){

            const res = await axios.post('/api/users/deletePic',{'fileUrl': imgUrl, 'userId': currentUser._id}, {
                headers: {
                    'Content-Type': 'application/json'
                }   
            });

            if(res.data[0]){
                // Remove the image from the array and replace it with null
                dispatch(updateUser({fieldName:'moreImages', value: res.data[1]}));
                let updatedImages = [...images]; // Copy the array to prevent directly mutating state
                updatedImages[indexToRemove] = null;
  
                // Sort the array to ensure null values come last
                updatedImages.sort((a, b) => (a === null) - (b === null));
                setImages(updatedImages);
            
            }
        }
    }
    catch(e){
        console.error(e);
    }
  }


const handleButtonStatus = (event) => {
    const guest = event.guests.find(guest => guest.user === profileUser._id);

    const status = guest?.status;

    if (status === "invited") {
         return "primary";
    } else if (status === "attending") {
        return "success";
    }else if(status === "declined"){
        return "danger";
    } else {
    return "info";
    }
}

const handleButtonStatusProfile = (event) => {
    const guest = event.guests.find(guest => guest.user === currentUser._id);

    const status = guest?.status;

    if (status === "invited") {
         return "primary";
    } else if (status === "attending") {
        return "success";
    }else if(status === "declined"){
        return "danger";
    } else {
    return "info";
    }
}


return (
    <Container className='main-profile mt-5'>
        <Row>
        {error && show && <Alert variant="danger" onClose={() => {setShow(false); dispatch(clearError());}} dismissible>{error}</Alert>}
        </Row>
        <Row className='mb-1'>
            <Col xs={1} >
            <Button variant="light" onClick={() => navigate("/search", { state: { triggerEffect: window.scrollY } })}>
                        Back
            </Button>
            </Col>
        </Row>
        <Row>
            
            <Col md={ownPage?7:7} xs={12} className="profile-container mb-3">
                <Card>
                    {isLoading && <Spinner animation="grow" /> }
                    <Row>
                        <Col md={8}>
                            <Card.Img className={ownPage? "img-top-own" : "img-top"} src={profilePreview}/>
                            <Card.Body className="cardBody">
                                <Card.Title>{profileUser.userName}</Card.Title>
                                <Card.Text className='px-0'>{profileUser.city}</Card.Text>
                                <div className="d-flex justify-content-between"> 
                                <div>
                                    <h6>Trust Score: {profileUser.trustScore}%</h6>
                                    {ownPage ?  (profileUser.membershipType === "premium" ? <Badge bg="warning" text="dark">Premium</Badge> : <Badge bg="danger" text="light">Basic</Badge>): ''}
                                </div>
                                </div>
                                
                            </Card.Body>
                        </Col>
                
                        <Col md={4}>
                           
                            {/* {   //we pass in the image urls
                                placeholderImages.map((imgUrl, index) => (
                                <span className='singleImage'>
                                    {index === 0 && <p style={{fontSize:'8px', margin:'0', padding:'0'}}>Profile Picture</p>}
                                    <Image key={index} src={imgUrl} className="img-thumbnail" />
                                    {ownPage && <FontAwesomeIcon className='removePhoto' icon={faCircleXmark} />}
                                </span>))
                            }
                             */}

                                {placeholderImages.map((imgUrl, index) => (
                                            <span className='singleImage' key={index}>
                                            {index === 0 && <p style={{fontSize:'8px', margin:'0', padding:'0'}}>Profile Picture</p>}
                                            { ownPage ?
                                                (
                                                    <>
                                                    <label htmlFor={`file-upload-${index}`} className="custom-file-upload">
                                                    <Image src={imgUrl} className="img-thumbnail" onClick = {()=> handleImageSelect(imgUrl)}/>
                                                    </label>
                                                    { ((imgUrl === photoPic) || (index===0 && currentUser.placeHolderPic)) && <input
                                                        id={`file-upload-${index}`}
                                                        type="file"
                                                        style={{ display: "none" }} // This hides the actual file input element
                                                        onChange={handleFileUpload(index)} // call handleFileUpload with the index to get a function with that index in scope
                                                    /> }
                                                   <FontAwesomeIcon     className={index === 0 ? (isAndroid ? 'removePhotoFirstAndroid' : (isChrome() ? 'removeChrome' : 'removePhoto')): 'removePhoto'}  
                                                   icon={faCircleXmark} onClick = {()=> handleRemoveImage(index,imgUrl)}/>

                                                    </>
                                                ) :
                                                    <Image src={imgUrl} className="img-thumbnail" onClick = {()=> handleImageSelect(imgUrl)}/>
                                            }
                                            </span>
                                )) }

                            {!ownPage && <div className='invite-button text-align-mobile'>
                                    {/* <Button variant="primary">Invite</Button> */}
                            </div> }
                        </Col> 
                    </Row>
                </Card> 
            </Col>
            <Col md={4} xs={12} className="events-container text-center order-md-1 order-2 h-100">
            <div className="100-vh">
                <Row>
                    <h2 className="form-title mb-5">My Offers {!ownPage? `for ${profileUser.userName}` : ''}</h2>
                        {hostedEvents.map((event,idx)=> 
                            <div className='events-box mb-1' key={idx}>
                                <Col md={2}>
                                    <h6>{event.eventTitle}</h6>
                                </Col>
                                <Col md={2}><h6>{new Date(event.eventDate).toLocaleDateString(undefined, {month: "short", day: "numeric"})}</h6></Col>
                                <Col md={2}><h6>{new Date(1970, 0, 1, event.eventTime.split(':')[0], event.eventTime.split(':')[1]).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: true})}</h6></Col>
                                {/* <Col md={5} className="truncate-3-lines"><h6>{event.location}</h6></Col>  */}
                                <Col md={2}><img src={event.imgEvent} style={{width:50, height:50,borderRadius:15}} alt='event-pic'/></Col>
                                    {event.status && 
                                    <Col md={2}><div className='mt-1' style={{justifyContent:'flex-end'}}>
                                        <Button variant={handleButtonStatus(event)} style={{ marginLeft: '2px' }}>
                                            {event.hostUser === profileUser._id ? 'Host' : (event.guests.find(guest => guest.user === profileUser._id)?.status || 'uninvited')}
                                        </Button>
                                    </div></Col>
                                    }
                        </div>)
                        }
            </Row>

              {!ownPage &&
              <>
               <Row>
                <h2 className="form-title mb-5">{profileUser.userName} Offers for {currentUser.userName}</h2>
                {profileEvents.map((event,idx)=> 
                    <div className='events-box mb-2' key={idx}>
                        <Col md={3}><h6>{event.eventTitle}</h6></Col>
                        <Col md={3}><h6>{event.location}</h6></Col>
                        <Col md={3}><img src={event.imgEvent} style={{width:50, height:50,borderRadius:15}} alt='event-pic'/></Col>
                        {event.status && 
                        <Col md={3}><div className='mt-1' style={{justifyContent:'flex-end'}}>
                        <Button variant={handleButtonStatusProfile(event)} style={{ marginLeft: '2px' }}>
                             {event.hostUser === currentUser._id ? 'Host' : (event.guests.find(guest => guest.user === currentUser._id)?.status || 'uninvited')}
                        </Button>
                        </div></Col>}
                    </div>)}
            </Row>
            </>
             }
             </div>
            </Col> 
            <Col xs={12} md={ownPage? 7: 7} className="profile-aboutme order-md-2 order-1 mb-3">
                <Card onClick={handleCardClick} className='h-100'>
                    <Card.Body>
                        <Card.Title>About Me</Card.Title>
                           <Card.Text>
                                {isEditing && ownPage ? (
                            <FormControl 
                                as="textarea" 
                                autoFocus
                                onBlur={handleInputBlur}
                                value={cardText}
                                onChange={handleInputChange}
                            />
                                    ) : (
                                        <div>{cardText}
                                        { isSaving && <Spinner className="spin" animation="border" />}
                                        {saveStatus ==="saved" && <span>Saved!</span>}
                                        </div>
                                    )}
                                </Card.Text>
                                
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
  )
}

export default Profile