export const BtnArrow = () => {
    return (
      <svg
        width="5"
        height="10"
        viewBox="0 0 5 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.00905848 4.99501C0.00833416 4.9295 0.0200486 4.86454 0.0434484 4.80432C0.0668483 4.74411 0.101416 4.68996 0.144928 4.64536L4.22101 0.14985C4.40217 -0.0499501 4.68297 -0.0499501 4.86413 0.14985C5.04529 0.34965 5.04529 0.659341 4.86413 0.859141L1.10507 5.005L4.85507 9.14086C5.03623 9.34066 5.03623 9.65035 4.85507 9.85015C4.67391 10.05 4.39312 10.05 4.21196 9.85015L0.13587 5.35465C0.04529 5.25475 0 5.12488 0 5.005L0.00905848 4.99501Z"
          fill="#5271FF"
        />
      </svg>
    );
  };
  export const CrossIcon = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          cx="10.0002"
          cy="10.0001"
          rx="6.41039"
          ry="6.41039"
          fill="white"
        />
        <path
          d="M17.0746 2.92582C13.1727 -0.975273 6.82708 -0.975273 2.92517 2.92582C-0.975058 6.82691 -0.975058 13.1742 2.92517 17.0752C4.87613 19.0254 7.4382 20 10.0003 20C12.5624 20 15.1236 19.0253 17.0746 17.0752C20.9757 13.1742 20.9757 6.82691 17.0746 2.92582ZM14.1265 12.9482C14.4525 13.2742 14.4525 13.8011 14.1265 14.1271C13.9639 14.2897 13.7505 14.3714 13.537 14.3714C13.3236 14.3714 13.1101 14.2897 12.9475 14.1271L10.0003 11.179L7.05384 14.1263C6.89044 14.2888 6.67697 14.3706 6.46437 14.3706C6.25094 14.3706 6.03748 14.2888 5.8749 14.1263C5.54892 13.8003 5.54892 13.2725 5.8749 12.9474L8.82134 10.0001L5.87408 7.05284C5.5481 6.72686 5.5481 6.1991 5.87408 5.87394C6.19924 5.54796 6.727 5.54796 7.05298 5.87394L10.0002 8.82121L12.9475 5.87394C13.2735 5.54796 13.8004 5.54796 14.1264 5.87394C14.4524 6.1991 14.4524 6.72686 14.1264 7.05284L11.1791 10.0001L14.1265 12.9482Z"
          fill="#7D7D7D"
        />
      </svg>
    );
  };