import React from "react";
import heroImg from "../../components/images/webp/hero_main.webp";
import CustomButton from "./CustomButton"
import {
  HeroBlurCircle,
  HeroHeadingVector,
  HeroImgCircle,
  MessageIcon,
} from "./Icons";

const Hero = () => {
  return (
    <div className="min-vh-70 d-flex align-items-center position-relative overflow-hidden hero_section z-1">
      <span className="position-absolute top-0 end-0 z-n1">
        <HeroBlurCircle />
      </span>
      <div className="container container_modified pb-4 pb-md-5 mt-3 mb-1 my-md-5">
        <span className="position-absolute end-0 hero_circle">
          <HeroImgCircle />
        </span>
        <div className="row align-items-center">
          <div className="col-md-6 order-2 order-md-1">
            <h1 className="text-black font-xxl mb-3 fw-bolder me-xxl-5 pe-xl-3 position-relative d-none d-md-block hero_heading">
              <span className="hero_heading_vector position-absolute start-0 z-n1">
                <HeroHeadingVector />
              </span>
              <span className="text-black">The Home of Product GPT</span> <br />
              <span className="text-blue">Quickly Create Engaging Posts that Sound Authentic to Get Sales</span> 
             
            </h1>
            <p className="text-gray mb-4 font-md mt-3 mt-md-0 pt-1 pt-md-0">
              <br /> 
              Use IncentiveMeet's ProductGPT to research and discuss and craft engaging Scripts based on Thousands of Real World Ads.   
            </p>
            <div>

            <CustomButton
              text="Find Promotions/Offers"
              btnStyle="hero_btn w-100"
              icon={<MessageIcon />}
              />
            </div>
            <div className="mt-3">

            <CustomButton
              text="Try Product GPT Free"
              btnStyle="hero_btn w-100"
              icon={<MessageIcon />}
              type="link"
              />
            </div>
          </div>
          <div className="col-md-6 order-1 order-md-2">
            <h1 className="text-black font-xxl mb-4 fw-bolder position-relative d-md-none">
              <span className="hero_heading_vector position-absolute start-0 z-n1">
                <HeroHeadingVector />
              </span>
              <span className="text-black">The Home of Product GPT</span><br />
              <span className="text-blue">Quickly Create Engaging Posts that Sound Authentic to Get Sales</span> 
              <br />
              <span className="text-blue">Free to Try and Sign up To Keep Using For Free</span> 
            </h1>
            <img
              className="w-100 hero_img"
              loading="lazy"
              src={heroImg}
              alt="heroImg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
